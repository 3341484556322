import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import swal from "sweetalert";
import AdminPrivateRoute from "./AdminPrivateRoute";
import CartContext from "./Contexts/CartContext";
import Blogs from "./components/frontend/Blogs";
import Box from "./components/frontend/Box";
import Cart from "./components/frontend/Cart";
import Checkout from "./components/frontend/Checkout";
import Home from "./components/frontend/Home";
import NavBar from "./components/frontend/NavBar";
import Product from "./components/frontend/Product";
import ProductWithCat from "./components/frontend/ProductWithCat";
import ProductWithSubCat from "./components/frontend/ProductWithSubCat";
import TrackOrder from "./components/frontend/TrackOrder";
import Verify from "./components/frontend/Verify";
import VerifyPayment from "./components/frontend/VerifyPayment";
import Login from "./components/frontend/auth/Login";
import Register from "./components/frontend/auth/Register";

import TagManager from "react-gtm-module";
import Blog from "./components/frontend/Blog";
import FooterNew from "./components/frontend/FooterNew";
import HomeItems from "./components/frontend/HomeItems";
import MobileNav from "./components/frontend/MobileNav";
import PrivacyPolicy from "./components/frontend/PrivacyPolicy";
import Search from "./components/frontend/Search";
import ShippingReturnPolicy from "./components/frontend/ShippingReturnPolicy";
import TermsConditions from "./components/frontend/TermsConditions";
import Otp from "./components/frontend/auth/Otp";

axios.defaults.baseURL = "https://backend.shokhibox.com/";
//axios.defaults.baseURL = 'http://127.0.0.1:8000';

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  function generateSessionId() {
    const uuid = require("uuid");
    const sessionId = uuid.v4();

    return sessionId;
  }

  //////console.log("Home");
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const history = useHistory();
  const [loggedIn, setloggedIn] = useState(false);

  const [cartLength, setCartLength] = useState(
    localStorage.getItem("cartLength")
  );
  const [isAdmin, setAdmin] = useState(false);
  const [showFloatingCart, setShowFloatingCart] = useState(true);

  const handleLogout = (e) => {
    e.preventDefault();
    const email = localStorage.getItem("auth_email");
    const data = { email: email };
    axios
      .post("/api/logout", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAdmin(false);

          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_email");
          localStorage.removeItem("auth_name");
          localStorage.removeItem("auth");
          localStorage.removeItem("isVerified");

          setloggedIn(false);
          swal("Logged out", res.data.message, "success");
          window.location.reload();
          history.push("/");
        }
      });
  };

  useEffect(() => {
    let sessionId = Cookies.get("sessionId");
    localStorage.setItem("countdownTime", 90);

    if (sessionId === undefined || sessionId === null) {
      sessionId = generateSessionId();
      Cookies.set("sessionId", sessionId);
    }

    let admin = JSON.parse(localStorage.getItem("auth"));
    if (admin) setAdmin(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [renderNav, setRenderNav] = useState(true);

  const [showCart, setShowCart] = useState(false);
  const [IsVerify, setIsVerify] = useState(localStorage.getItem("isVerified"));

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-PBLR9V9P" });
  }, []);

  // usePageTracking.js

  useEffect(() => {
    const handleLocationChange = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: history.location.pathname + history.location.search,
        },
      });
    };

    // Trigger the first pageview manually
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: history.location.pathname + history.location.search,
      },
    });

    const unlisten = history.listen(handleLocationChange);

    return () => {
      unlisten();
    };
  }, [history]);
  console.log(IsVerify);

  return (
    <CartContext>
      <div className="App">
        <div className="innerContainer text-white d-flex flex-column justify-content-between">
          <NavBar
            showCart={showCart}
            setShowCart={setShowCart}
            showFloatingCart={showFloatingCart}
            renderNav={renderNav}
            isAdmin={isAdmin}
            handleLogout={handleLogout}
            loggedIn={loggedIn}
            setloggedIn={setloggedIn}
            setCartLength={setCartLength}
            cartLength={cartLength}
          />
          <MobileNav />
          <div className="contents">
            <Switch>
              <Route exact path="/">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Home setCartLength={setCartLength} />
                )}
              </Route>

              <Route path="/login">
                {IsVerify == "true" ? (
                  <Redirect to="/" />
                ) : (
                  <Login setloggedIn={setloggedIn} setAdmin={setAdmin} />
                )}
              </Route>

              <Route path="/register">
                {IsVerify === "true" ? (
                  <Redirect to="/" />
                ) : (
                  <Register
                    setIsVerify={setIsVerify}
                    setloggedIn={setloggedIn}
                  />
                )}
              </Route>

              <Route path="/otp">
                {IsVerify === "true" ? (
                  <Redirect to="/" />
                ) : (
                  <Otp setIsVerify={setIsVerify} setloggedIn={setloggedIn} />
                )}
              </Route>

              <Route exact path="/products/:category">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <ProductWithCat setCartLength={setCartLength} />
                )}
              </Route>

              <Route exact path="/trending/:home">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <HomeItems setCartLength={setCartLength} />
                )}
              </Route>

              <Route exact path="/products/:category/:subcategory">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <ProductWithSubCat setCartLength={setCartLength} />
                )}
              </Route>

              <Route exact path="/product/:id">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Product setCartLength={setCartLength} />
                )}
              </Route>

              <Route exact path="/blogs">
                {IsVerify == "false" ? <Redirect to="/otp" /> : <Blogs />}
              </Route>
              <Route exact path="/blogs/:id">
                {IsVerify == "false" ? <Redirect to="/otp" /> : <Blog />}
              </Route>

              <Route exact path="/cart">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Cart
                    setShowFloatingCart={setShowFloatingCart}
                    setShowCart={setShowCart}
                    setRenderNav={setRenderNav}
                    setCartLength={setCartLength}
                    cartLength={cartLength}
                  />
                )}
              </Route>

              <Route exact path="/checkout">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Checkout
                    setShowCart={setShowCart}
                    setShowFloatingCart={setShowFloatingCart}
                    setCartLength={setCartLength}
                  />
                )}
              </Route>

              <Route exact path="/verify/:email/:token">
                {IsVerify == "false" ? <Redirect to="/otp" /> : <Verify />}
              </Route>

              <Route path="/shokhibox">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Box setCartLength={setCartLength} />
                )}
              </Route>

              <Route path="/verifyBkash">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <VerifyPayment />
                )}
              </Route>

              <Route path="/TrackOrder">
                {IsVerify == "false" ? <Redirect to="/otp" /> : <TrackOrder />}
              </Route>

              <Route path="/terms">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <TermsConditions />
                )}
              </Route>

              <Route path="/shipping">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <ShippingReturnPolicy />
                )}
              </Route>

              <Route path="/privacy">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <PrivacyPolicy />
                )}
              </Route>

              <Route path="/searchItem">
                {IsVerify == "false" ? (
                  <Redirect to="/otp" />
                ) : (
                  <Search setCartLength={setCartLength} />
                )}
              </Route>

              {/* <Redirect exact from="/" to="/home" /> */}

              <AdminPrivateRoute path="/admin" name="Admin" />
            </Switch>
          </div>
          <FooterNew />
          {/* <Footer /> */}
        </div>
      </div>
    </CartContext>
  );
}

export default App;
