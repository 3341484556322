// gtm.js
import TagManager from "react-gtm-module";
const gtmId = "GTM-PBLR9V9P"; // Replace with your GTM ID

export const initializeTagManager = () => {
  TagManager.initialize({ gtmId });
};

export const trackButtonClick = (buttonName) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "button_click",
      buttonName: buttonName,
    },
  });
};
