import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

export default function TrackOrder() {
  const [PrevOrders, setPrevOrders] = useState([]);
  const [PrevOrdersLoading, setPrevOrdersLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios
      .get("/api/prev-orders", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setPrevOrders(res.data.orders);
        }
        setPrevOrdersLoading(false);
      })
      .catch((error) => {
        //////////console.log(error);
      });
  }, []);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [invoice, setInvoice] = useState("");
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);

  const track = (e) => {
    if (invoice === "") {
      swal("Invoice Required", "Please Enter Your Invoice", "warning");
      return;
    }
    const data = {
      invoice: invoice,
    };

    axios
      .post("/api/trackOrder", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOrder(res.data.order);
        } else {
          swal("Warning", res.data.message, "warning");
        }
      })
      .catch((error) => {
        //////////console.log(error);
      });
  };
  ////console.log(order)

  return (
    <>
      <div className="mx-auto my-5 flex flex-col justify-center items-center space-y-3">
        <h2 className="text-black text-xl font-bold">Track Order</h2>
        <input
          style={{ width: "300px" }}
          className="bg-white border rounded-md outline-0 ring-0 focus:ring-1 focus:ring-blue-400 text-black py-1 px-2"
          onChange={(e) => setInvoice(e.target.value)}
          value={invoice}
          type="text"
          required
          placeholder="Your Invoice example:ShokhiBox1111111"
        />
        <button className="btn btn-primary mt-3" onClick={(e) => track(e)}>
          Track
        </button>
      </div>

      <div
        style={{ width: "300px" }}
        className="Cart-Container  mx-auto  text-black mx-auto"
      >
        {Object.keys(order).length > 0 && (
          <>
            <div className="details my-5">
              <h5 className="text-start mt-1">Name : {order.name}</h5>
              <h5 className="text-start mt-1">Address : {order.address}</h5>
              <h5 className="text-start mt-1">Total : {order.total} Tk</h5>
              <h5 className="text-start mt-1">Status : {order.status}</h5>
            </div>

            <div
              style={{ width: "300px" }}
              className="checkout-cart text-black "
            >
              {order.orderdetails.map((item) => {
                return (
                  <div className="checkout-cart-item d-flex align-items-center justify-content-between text-black">
                    <img
                      className="pic"
                      src={item.product_pic}
                      alt="missing"
                    ></img>
                    <span className="mx-1 item-name">{item.product_name}</span>

                    <div className="price fw-bold mx-1">
                      {item.quantity} x {item.product_price} Tk
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {/* {PrevOrdersLoading === true &&
        {
          <img className="loader" src={loader}></img> 
        }} */}

      {PrevOrders.length > 0 && (
        <>
          <h5 className="mt-4 text-black fw-bold">Your Previous Orders</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Invoice</th>
                <th>Name</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {PrevOrders.map((order, index) => (
                <tr className="" key={index}>
                  <td style={{ fontSize: "13px" }}>{index + 1}</td>
                  <td style={{ fontSize: "13px" }}>{order.invoiceId}</td>
                  <td style={{ fontSize: "13px" }}>{order.name}</td>
                  <td style={{ fontSize: "13px" }}>{order.total} Tk</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
