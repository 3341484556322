import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import sad from "../../images/sad.png";

export default function ProductWithCat(props) {
  const { setCartLength } = useContext(MyCartContext);
  const { category } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(-1);
  const { cart, setCart } = useContext(MyCartContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(20);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const [currentProducts, setCurrentProducts] = useState([]);

  const pageClicked = (items, pageNo) => {
    let pageIndex = pageNo - 1;
    let firstIndex = pageIndex * productsPerPage;
    let lastIndex = firstIndex + productsPerPage - 1;
    if (items.length - 1 < lastIndex) lastIndex = items.length - 1;

    setCurrentProducts(items.slice(firstIndex, lastIndex + 1));
    window.scrollTo(0, 0);
  };
  const [Pages, setPages] = useState([]);

  useEffect(() => {
    const data = {
      cat: category,
    };
    axios
      .post("/api/get-category-products", data, {
        headers: { "Allowed-Source": process.env.REACT_APP_ALLOWED_SOURCE },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setProducts(res.data.products);

          let pages = res.data.products.length / productsPerPage;

          let pageArray = [];
          for (let index = 0; index < pages; index++) {
            pageArray.push(index + 1);
          }
          setPages(pageArray);
          pageClicked(res.data.products, 1);
          setLoading(false);
        } else {
          setErrors(res.data.messages);
          //  history.push('/');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        //history.push('/');
      });
  }, [history, category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sortBy]);

  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  const Search = (e) => {
    setSearch(e.target.value);
  };

  const sortClicked = (e, val) => {
    setSortBy(val);

    let sortedItems = [...currentProducts];

    if (val == 1) {
      sortedItems.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (val == 0) {
      sortedItems.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    } else if (val == 2) {
      sortedItems.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (val == 3) {
      sortedItems.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }
    console.log(sortedItems);
    setCurrentProducts([...sortedItems]);
  };

  const addToCart = async (product, e) => {
    e.preventDefault();
    e.persist();

    let total = 0;
    let found = 0;

    cart.forEach((element) => {
      if (product.id === element.id) {
        total = total + 1;
        if (product.stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      let product_id = product.id;

      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: product.name,
        id: product_id,
        size: product.size.name,
        color: "",
        pic: product.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: 0,
      };

      cart.push(productInput);

      setCartD(cart);
      setCart(cart);
      props.setCartLength(cart.length);

      localStorage.setItem("cartLength", cart.length);
    }
  };

  return (
    <div className="Outer-Container d-flex flex-column">
      <Helmet>
        <title>Products</title>
        <meta name="description" contents="Women hygiene products" />
        <meta
          name="keywords"
          contents="Women hygiene products,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
        />
      </Helmet>
      <input
        type="text"
        placeholder="Search item"
        className="searchBar w-25 mx-auto m-3 border rounded text-black"
        name="search"
        value={search}
        onChange={(e) => Search(e)}
      />
      <div className="d-flex mx-4">
        <label className="text-black p-2 fw-bold">Sort By Price</label>
        <select
          className="p-2 border text-black w-40 cursor-pointer"
          name="cars"
          id="cars"
          value={sortBy}
          onChange={(e) => sortClicked(e, e.target.value)}
        >
          <option className="p-2" value={-1}>
            None
          </option>
          <option className="p-2" value={0}>
            High to Low
          </option>
          <option className="p-2" value={1}>
            Low to High
          </option>
          <option className="p-2" value={2}>
            Alphabetic(A-Z)
          </option>
          <option className="p-2" value={3}>
            Alphabetic(Z-A)
          </option>
        </select>
      </div>
      <div className="Container d-flex flex-wrap justify-content-center align-items-center ">
        {loading ? (
          <div>{/* <img src={loader} alt="Your GIF" /> */}</div>
        ) : currentProducts.length === 0 ? (
          <div className="text-black d-flex flex-column">
            <h4 className="fw-bold mt-5">No Items Available</h4>
            <img src={sad} className="item-pic mx-auto" />
          </div>
        ) : (
          currentProducts.map((product, index) => (
            <div className="tredningProduct1 border m-1" key={index}>
              <Link to={`/product/${product.id}`} className="dec-none">
                <div className="m-2 item-card p-2">
                  {product.discount.discount !== 0 && (
                    <span className="og-price">
                      {product.discount.discount}% OFF
                    </span>
                  )}
                  {product.size.name !== "Not Available" && (
                    <span className="size myborder-pink2 px-2 rounded ">
                      {product.size.name}
                    </span>
                  )}
                  <img
                    loading="lazy"
                    src={product.pic}
                    className="item-pic"
                    alt="..."
                  />
                  <div className="item-body">
                    <h5 className="item-title">{product.name}</h5>
                    <h4></h4>
                    <div className="d-flex justify-content-between">
                      <span>
                        <span className="item-price">
                          ৳{Math.ceil(calculatePrice(product))}
                        </span>
                      </span>
                      {/* {product.size.name !== "Not Available" && (
                        <span className=" border myborder-pink2 px-2 rounded ">
                          {product.size.name}
                        </span>
                      )} */}
                    </div>
                    <button
                      onClick={(e) => {
                        addToCart(product, e);
                      }}
                      className=" myborder-pink bg-white mb-2 text-[15px]"
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
      <ul className="pagination mx-auto">
        {Pages.map((_, index) => (
          <li key={index} className="page-item">
            <button
              onClick={() => pageClicked(products, _)}
              className="page-link"
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
