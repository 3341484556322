import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import bundles from "../../images/Bundles.png";
import waterbag from "../../images/Hot Water Bag.png";
import pads from "../../images/Pads.png";
import Panties from "../../images/Panties.png";
import tampon from "../../images/Tampons.png";
import treats from "../../images/Treats.png";
import bogo from "../../images/bogo.png";
import shop from "../../images/shop.png";

export default function DraggableLinks(props) {
  const tabsbox = useRef(null);
  const [dragging, setDragging] = useState(false);

  const startDragging = () => {
    setDragging(true);
  };

  const stopDragging = () => {
    setDragging(false);
  };

  const drag = (e) => {
    if (1) {
      return;
    }

    ////console.log("Dragging")
    if (dragging) {
      tabsbox.current.scrollLeft -= e.movementX;
    }
  };
  const handleMouseUp = () => {
    stopDragging();
    setDragging(false);
  };

  useEffect(() => {
    ////console.log("useEffect ran")
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const scroll = (direction) => {
    if (direction === 1) {
      if (window.innerWidth > 600) tabsbox.current.scrollLeft -= 380;
      else tabsbox.current.scrollLeft -= 190;
    } else {
      if (window.innerWidth > 600) tabsbox.current.scrollLeft += 380;
      else tabsbox.current.scrollLeft += 190;
    }
  };

  return (
    <div className=" draggableContainer draggableContainer2">
      <div className="flex justify-center items-center">
        <ul
          className="tabs-box"
          ref={tabsbox}
          onMouseMove={drag}
          onMouseDown={startDragging}
        >
          <li>
            <Link
              className="dec-none  -black bg-white   rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Feminine Hygiene/Pads"}
            >
              <div className="dec-none ">
                <img loading="lazy" className="home-icon" src={pads}></img>
                <p>Sanitary Pads</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center "
              to={"products/Bundles"}
            >
              <div className="dec-none  ">
                <img loading="lazy" className="home-icon" src={bundles}></img>
                <p>Bundles</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Feminine Hygiene/Tampons"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={tampon}></img>
                <p>Tampons</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white  rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Masks"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={bogo}></img>
                <p>BOGO!</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Panties"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={Panties}></img>
                <p>Panties</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Treats"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={treats}></img>
                <p>Treats</p>
              </div>
            </Link>
          </li>

          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/Cramp Relief"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={waterbag}></img>
                <p>Cramp Relief</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dec-none  -black bg-white rounded homeLink m-1 d-flex align-items-center justify-content-center"
              to={"products/beautycare"}
            >
              <div className="dec-none">
                <img loading="lazy" className="home-icon" src={shop}></img>
                <p className="w-max">Beauty Care</p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
