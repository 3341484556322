import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { encryptData } from "../../hooks/DataEncDec";
import useGetCart, { setCartD } from "../../hooks/useCart";
import bkash from "../../images/bkash.png";
import cod from "../../images/cod.png";

export default function Checkout(props) {
  const [Cities, setCities] = useState([]);
  const [Thanas, setThanas] = useState([]);
  const [promoValue, setPromoValue] = useState({
    amount: 0,
    type: "",
  });
  const [IsNotPromo, setNotIsPromo] = useState(false);

  let history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [errors, setErrors] = useState([]);
  function totalPrice() {
    let total = 0;
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    //  let percent = (100-promoValue)/100;

    if (promoValue.type === "percent") {
      const value = promoValue.amount / 100;
      console.log(value);
      const temp = total * value;
      console.log(temp);
      total = Math.round(total - temp);
      console.log(total);
    } else if (promoValue.type === "taka") {
      total = total - promoValue.amount;
    }

    area.forEach((the_area) => {
      if (the_area.value === selectedArea) total += the_area.deliveryCharge;
    });

    return total;
  }

  const [totalAmount, setTotalAmount] = useState(0);
  const { boxes, setBoxes } = useContext(MyCartContext);
  const { cart, loading } = useGetCart();

  const paymentMethods = [
    {
      name: "Cash On Delivery",
      value: 0,
      icon: cod,
    },
    {
      name: "Bkash",
      value: 1,
      icon: bkash,
    },
  ];

  const [selectedArea, setArea] = useState(0);
  const area = [
    {
      name: "Inside Dhaka",
      value: 0,
      deliveryCharge: 60,
    },
    {
      name: "Outside Dhaka",
      value: 1,
      deliveryCharge: 100,
    },
    {
      name: "Instant Delivery (Inside Dhaka)",
      value: 2,
      deliveryCharge: 150,
    },
  ];

  const [selectedPayment, setSelectedPayment] = useState(
    paymentMethods[0].name
  );

  const handleSelectPayment = (event) => {
    setSelectedPayment(event.target.value);
  };

  const [payment, setPaymentType] = useState(0);

  const handlePaymentChange = (event) => {
    setPaymentType(Number(event.target.value));
  };

  const handleAreaChange = (event) => {
    setArea(Number(event.target.value));
  };
  const [detailsInput, setDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    city: "",
    postCode: "",
    address: "",
    phn: "",
    total: totalPrice(),

    id: null,
    status: "Pending",
    promoCode: "",
  });

  const handleInput = (e) => {
    e.persist();
    e.preventDefault();
    setDetails({ ...detailsInput, [e.target.name]: e.target.value });
  };

  function setPrice(price, discount) {
    return (price * (100 - discount)) / 100;
  }
  function setQuantity(original, quantity) {
    if (quantity > original) return original;

    if (quantity < 1) return 1;

    return quantity;
  }

  ////console.log(payment,"Pay")

  ////console.log(selectedArea,"arrea")

  const Checkout = (e) => {
    if (cart.length === 0) history.push("/home");

    e.preventDefault();

    const data = {
      cart: cart,
      details: {
        name: detailsInput.fname + " " + detailsInput.lname,
        address:
          detailsInput.city +
          " " +
          detailsInput.address +
          " " +
          detailsInput.postCode,
        phn: detailsInput.phn,
        total: totalPrice(),

        id: detailsInput.id,
        status: detailsInput.status,
        city_id: detailsInput.city_id,
        thana_id: detailsInput.thana_id,
      },
    };

    axios
      .post("/api/order", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.status === 200) {
          let sessionId = Cookies.get("sessionId");

          const encryptedCart = encryptData([]);

          const post = {
            sessionId: sessionId,
            cart: encryptedCart,
          };

          await axios
            .post("/api/set-cart", post, {
              headers: { "Allowed-Source": allowedSource },
            })
            .then((res) => {
              if (res.data.status === 200) {
                localStorage.setItem("cartLength", 0);
                window.location.reload();
                //////console.log("Added")
              }
            });

          props.setCartLength(0);
          setCartD([]);
          setBoxes([]);

          window.location.href = res.data.redirect;
        }
        if (res.data.status === 400) {
          setErrors(res.data.message);
          ////////console.log(res.data.message,"errors")
          swal("Warning", res.data.message, "warning");
        }

        history.push("/home");
      })
      .catch((error) => {
        //////////console.log(error);
      });
  };

  const payWithBkash = () => {
    const data = {
      cart: cart,
      details: {
        name: detailsInput.fname + " " + detailsInput.lname,
        address:
          detailsInput.city +
          " " +
          detailsInput.address +
          " " +
          detailsInput.postCode,
        phn: detailsInput.phn,
        total: totalPrice(),

        id: detailsInput.id,
        status: detailsInput.status,
      },
    };

    axios
      .post("/api/payWithBkash", data, {
        headers: {
          "Allowed-Source": allowedSource,
        },
      })
      .then(async (res) => {
        if (res.data.status === 200) {
          let sessionId = Cookies.get("sessionId");
          //    ////console.log( res.data.redirect);
          const encryptedCart = encryptData([]);

          const post = {
            sessionId: sessionId,
            cart: encryptedCart,
          };

          axios
            .post("/api/set-cart", post, {
              headers: { "Allowed-Source": allowedSource },
            })
            .then((res) => {
              if (res.data.status === 200) {
                localStorage.setItem("cartLength", 0);

                //////console.log("Added")
              }
            });
          window.location.href = res.data.redirect;
        } else {
          swal("Try Again", res.data.message, "warning");
        }
      })
      .catch((error) => {
        ////console.log(error);
      });
  };

  const setPromo = (e) => {
    e.persist();
    e.preventDefault();
    console.log(detailsInput);

    const data = {
      promoCode: detailsInput.promoCode,
    };

    axios
      .post("/api/get-promo", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          //console.log(res.data.value,"Added")
          setPromoValue(res.data.value);
          if (res.data.value === 0) {
            setNotIsPromo(true);
          }
        } else {
          setPromoValue({
            type: "",
            amount: 0,
          });
        }
      })
      .catch((error) => {
        //////////console.log(error);
      });
  };
  useEffect(() => {
    props.setShowCart(false);
    props.setShowFloatingCart(false);
    window.scrollTo(0, 0);

    const getCity = async () => {
      await axios
        .get("/api/get-cities", {
          headers: { "Allowed-Source": allowedSource },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            setCities(res.data.cities);
          }
        });
    };

    getCity();

    return () => {
      props.setShowFloatingCart(true);
    };
  }, []);

  const getThana = (id) => {
    axios
      .get(`/api/get-thanas/${id}`, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          setThanas(res.data.thanas);
        }
      });
  };

  if (!localStorage.getItem("auth_token")) {
    history.push("/login");
    sessionStorage.setItem("redirect_url_exists", true);
  }

  if (loading) {
    return <div>{/* <img src={loader} alt="Your GIF" /> */}</div>;
  } else if (cart.length < 1) {
    return <div className="text-black">Empty Cart</div>;
  }

  return (
    <div className="Checkout-Container d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center text-black ">
      <div className="details my-5  text-start">
        <div className=" mb-3 w-75 m-auto">
          <label for="email" className="form-label">
            <h4 className="text-2xl font-bold">{"Contact (Email)"}</h4>
          </label>
          <input
            type="text"
            className="w-full outline-none border py-2 rounded-md bg-gray-100"
            id="exampleInputName"
            name="email"
            value={detailsInput.email}
            onChange={handleInput}
          />
        </div>

        <h4 className="text-2xl font-bold mb-3 w-75 m-auto">Delivery</h4>

        <div className=" mb-3 w-75 m-auto text-red">
          <h6>{errors.name}</h6>
          <h6>{errors.address}</h6>
          <h6>{errors.phn}</h6>
          <h6>{errors.city_id}</h6>
          <h6>{errors.thana_id}</h6>
        </div>

        <form className="" onSubmit={Checkout}>
          <div className="checkout-name gap-1 d-flex flex-column flex-sm-row w-75 mx-sm-auto mx-3 ">
            <div className=" mb-3 w-75 m-auto">
              <label for="name" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="w-full outline-none border py-2 rounded-md bg-gray-100"
                id="exampleInputName"
                name="fname"
                value={detailsInput.fname}
                onChange={handleInput}
              />
            </div>

            <div className=" mb-3 w-75 m-auto">
              <label for="name" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="w-full outline-none border py-2 rounded-md bg-gray-100"
                id="exampleInputName"
                name="lname"
                value={detailsInput.lname}
                onChange={handleInput}
              />
            </div>
          </div>

          <div className=" mb-3 w-75 m-auto flex flex-col justify-center items-start">
            <label for="name" className="form-label">
              City
            </label>

            <select
              onChange={(e) => {
                handleInput(e);
                getThana(e.target.value);
              }}
              name="city_id"
              className="w-full outline-none border py-2 rounded-md bg-gray-100"
            >
              <option value="">None</option>
              {Cities.map((city, index) => {
                return (
                  <option key={index} value={city.id}>
                    {city.name}
                  </option>
                );
              })}
            </select>
          </div>
          {detailsInput.city_id && Thanas && (
            <div className=" mb-3 w-75 m-auto flex flex-col justify-center items-start">
              <label for="name" className="form-label">
                Thana
              </label>

              <select
                onChange={handleInput}
                name="thana_id"
                className="w-full outline-none border py-2 rounded-md bg-gray-100 "
              >
                <option value="">None</option>
                {Thanas.map((thana, index) => {
                  return (
                    <option key={index} value={thana.id}>
                      {thana.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className=" mb-3 w-75 m-auto">
            <label for="address" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="w-full outline-none border py-2 rounded-md bg-gray-100"
              id="exampleInputName"
              name="address"
              value={detailsInput.address}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3 w-75 m-auto flex flex-col justify-center items-start ">
            <label for="name" className="form-label">
              Postal Code
            </label>
            <input
              type="text"
              className="w-full outline-none border py-2 rounded-md bg-gray-100"
              id="exampleInputName"
              name="postCode"
              value={detailsInput.postCode}
              onChange={handleInput}
            />
          </div>

          <div className=" mb-3 w-75 m-auto">
            <label for="name" className="form-label">
              Phone
            </label>
            <input
              type="text"
              className="w-full outline-none border py-2 rounded-md bg-gray-100"
              id="exampleInputName"
              name="phn"
              value={detailsInput.phn}
              onChange={handleInput}
            />
          </div>

          <div className=" mb-3 w-75 m-auto">
            {promoValue.amount === 0 && (
              <>
                <label for="promoCode" className="form-label">
                  Promo Code
                </label>
                <input
                  type="text"
                  className="w-full outline-none border py-2 rounded-md bg-gray-100"
                  id="promoCode"
                  name="promoCode"
                  value={detailsInput.promoCode}
                  onChange={handleInput}
                />
                {IsNotPromo && (
                  <div className="text-red-500">invalid Promo Code!!</div>
                )}
                <button
                  onClick={(e) => setPromo(e)}
                  className="btn btn-secondary mt-2"
                >
                  Apply
                </button>
              </>
            )}
            {promoValue.amount !== 0 && (
              <>
                <h4>
                  {promoValue.amount}
                  {promoValue.type === "taka" ? "TK" : "%"} Discount Added
                </h4>
              </>
            )}
          </div>

          <div className=" mb-3 w-75 m-auto d-flex flex-column  text-start">
            <label for="name" className="form-label fw-bold">
              Select Area
            </label>

            {area.length > 0 &&
              area.map((area) => {
                return (
                  <label className=" text-start border p-1 radio-bg rounded m-1">
                    <input
                      className="mx-1 radio-item"
                      type="radio"
                      name="area"
                      value={area.value}
                      checked={selectedArea === area.value}
                      onChange={handleAreaChange}
                    />
                    {area.name}
                  </label>
                );
              })}
          </div>

          <div className=" mb-3 w-75 m-auto d-flex flex-column  text-start">
            <label for="name" className="form-label fw-bold">
              Select Payment Method
            </label>

            {paymentMethods.length > 0 &&
              paymentMethods.map((method) => {
                return (
                  <label className=" text-start border p-1 rounded m-1 radio-bg">
                    <input
                      className="mx-1  radio-item"
                      type="radio"
                      name="payment"
                      value={method.value}
                      checked={payment === method.value}
                      onChange={handlePaymentChange}
                    />
                    {method.name}
                    <img className="payment-icon" src={method.icon}></img>
                  </label>
                );
              })}
          </div>
        </form>

        <div className=" mb-3 w-75 m-auto">
          {payment === 0 && (
            <button onClick={Checkout} className="btn btn-warning  ">
              Order
            </button>
          )}
          {payment === 1 && (
            <button onClick={payWithBkash} className="btn btn-warning  ">
              Pay
            </button>
          )}
        </div>
      </div>
      {/* details box */}

      <div className="Cart-Container  ">
        <div className="checkout-cart">
          {cart.length > 0 &&
            cart.map((item) => {
              if (item.box_num === 0)
                return (
                  <div className="checkout-cart-item d-flex align-items-center justify-content-between">
                    <img className="pic " src={item.pic} alt="loading" />

                    <span className="mx-1 item-name">{item.name}</span>

                    <div className="price fw-bold mx-1">
                      {item.quantity} x {item.price} Tk
                    </div>
                  </div>
                );
            })}
        </div>

        <div className="checkout-boxes">
          {boxes.length > 0 &&
            boxes.map((box, index) => {
              return (
                <div className="border border-1 mt-1 rounded">
                  <h6>Shokhi Box {index + 1}</h6>
                  {box.items.map((item) => {
                    return (
                      <div className="checkout-cart-item d-flex align-items-center justify-content-between">
                        <img className="pic " src={item.pic} alt="loading" />

                        <span className="mx-1 item-name">{item.name}</span>

                        <div className="price fw-bold mx-1">
                          {item.price} Tk
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
        <div className="price">
          <div className="product-price d-flex justify-content-between w-75 mx-auto my-2">
            <span className="fw-bold">{"Delivery Charge(Extra)"}</span>
          </div>

          {area.map((the_area) => {
            if (selectedArea === the_area.value) {
              return (
                <div className="product-price d-flex justify-content-between w-75 mx-auto my-2">
                  <span className="fw-bold ts-xs">{the_area.name}</span>
                  <span className="fw-bold ts-xs">
                    {the_area.deliveryCharge} Tk
                  </span>
                </div>
              );
            }
          })}

          <div className="product-price d-flex justify-content-between w-75 mx-auto my-2">
            <span className="fw-bold fs-4">Total</span>
            <span className="fw-bold fs-4">{totalPrice()} Tk</span>
          </div>
        </div>
      </div>
    </div>
  );
}
