import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

const FooterNew = () => {
  return (
    <footer className="text-gray-900 body-font bg-gray-200 dec-none text-left pb-20 lg:pb-0">
      <div className="px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col space-y-8 2xl:space-y-0">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <Link
            className={
              "text-2xl font-bold text-gray-900 dec-none flex justify-center"
            }
            to={"/"}
          >
            <img src={Logo} alt="" />
          </Link>
          <p className="mt-2 text-sm text-gray-900">
            Your Journey, Our Commitment: Redefining Menstrual Care.
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mb-3 md:text-left uppercase">
              Quick Links
            </h2>
            <nav className="list-none mb-10 md:text-left">
              <li>
                <Link
                  to={"/products/Feminine Hygiene"}
                  className="text-gray-600 hover:text-gray-500 dec-none dec-none"
                >
                  Feminine Hygiene
                </Link>
              </li>
              <li>
                <Link
                  to={"/products/Bundles"}
                  className="text-gray-600 hover:text-gray-500 dec-none dec-none"
                >
                  Bundles
                </Link>
              </li>
              <li>
                <Link
                  to={"/privacy"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Privacy policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to={"/shipping"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Shipping & Return Policy
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mb-3 md:text-left uppercase">
              Top Categories
            </h2>
            <nav className="list-none mb-10 md:text-left">
              <li>
                <Link
                  to={"/products/Feminine Hygiene"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Feminine Hygiene
                </Link>
              </li>
              <li>
                <Link
                  to={"/products/Feminine Hygiene/Sanitary Pads"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Sanitary Pads
                </Link>
              </li>
              <li>
                <Link
                  to={"/products/Treats/Chocolates"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Chocolates
                </Link>
              </li>
              <li>
                <Link
                  to={"/products/Masks"}
                  className="text-gray-600 hover:text-gray-500 dec-none"
                >
                  Bogo!
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mb-3 md:text-left uppercase">
              Contact us
            </h2>
            <nav className="list-none mb-10 md:text-left">
              <li>
                <a className="text-gray-600 hover:text-gray-500 dec-none">
                  Corporate address:
                </a>
              </li>
              <li className="w-full">
                <a className="text-gray-600 hover:text-gray-500 dec-none min-w-max">
                  145/1, Shantinagar, Dhaka-1217
                </a>
              </li>
              <li className="w-full">
                <a className="text-gray-600 hover:text-gray-500 dec-none min-w-max">
                  Talk to us: +880-1788477966
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mb-3 md:text-left uppercase">
              Social Links
            </h2>
            <nav className="list-none mb-10 md:text-left space-x-1 flex justify-center md:justify-start items-center">
              <li className=" flex justify-center md:justify-start">
                <a
                  href={"https://www.facebook.com/shokhibox"}
                  className="text-gray-600 hover:text-gray-500 dec-none w-fit"
                  target="_blank"
                >
                  <FaFacebook className="text-xl" />
                </a>
              </li>
              <li className=" flex justify-center md:justify-start">
                <a
                  href="https://www.instagram.com/shokhibox/"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-500 dec-none w-fit"
                >
                  <AiFillInstagram className="text-2xl" />
                </a>
              </li>
              <li className=" flex justify-center md:justify-start">
                <a
                  href="https://www.youtube.com/@shokhibox"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-500 dec-none w-fit"
                >
                  <FaYoutube className="text-2xl" />
                </a>
              </li>
              <li className=" flex justify-center md:justify-start">
                <a
                  href="https://twitter.com/shokhibox/"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-500 dec-none w-fit"
                >
                  <FaTwitter className="text-2xl" />
                </a>
              </li>
              <li className=" flex justify-center md:justify-start">
                <a
                  href="https://www.tiktok.com/@shokhiboxbd/"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-500 dec-none w-fit"
                >
                  <FaTiktok className="text-2xl" />
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-300 mx-auto py-4 px-5 flex flex-wrap flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
        <div className="flex flex-col sm:flex-row justify-center items-center space-x-2">
          <p>&copy; 2023 All rights reserved </p>
          <a className="" target="_blank" href="https://shovelerinfotech.com/">
            Shoveler Infotech{" "}
          </a>{" "}
        </div>
        <div className="flex flex-col sm:flex-row justify-center sm:justify-start items-center">
          <span className="mx-3 dbid">• DBID Number: 246619014</span>
          <span className="mx-3 dbid">• TRAD/DSCC/019534/2023</span>
        </div>
      </div>
    </footer>
  );
};

export default FooterNew;
