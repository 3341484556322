import React, { useContext } from "react";
import { CgProfile } from "react-icons/cg";
import { FaHeart, FaHome, FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MyCartContext } from "../../Contexts/CartContext";
const MobileNav = () => {
  const { cart, setCart, boxes, setBoxes } = useContext(MyCartContext);

  function totalItems() {
    let total = 0;
    cart.forEach((element) => {
      total += element.quantity;
    });
    return total;
  }
  return (
    <div className="fixed bottom-0 right-0 left-0 w-full bg-gray-200 shadow-md text-black py-2 flex lg:hidden z-10">
      <Link
        to={"/"}
        className="w-full px-4 py-2 text-sm text-gray-700"
        style={{ textDecoration: "none" }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="">
            <FaHome className="text-2xl" />
          </div>
          <p className="no-underline">Home</p>
        </div>
      </Link>
      <Link
        to={"/"}
        className="w-full px-4 py-2 text-sm text-gray-700"
        style={{ textDecoration: "none" }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <FaHeart className="text-2xl" />
            <div className="w-5 h-5 rounded-full bg-red-500 flex justify-center items-center absolute -right-3 -top-2 text-[8px] text-white">
              10
            </div>
          </div>
          <p className="no-underline">Wishlist</p>
        </div>
      </Link>
      <Link
        to={"/cart"}
        className="w-full px-4 py-2 text-sm text-gray-700"
        style={{ textDecoration: "none" }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <FaShoppingCart className="text-2xl" />
            <div className="w-5 h-5 rounded-full bg-red-500 flex justify-center items-center absolute -right-3 -top-2 text-[8px] text-white">
              {totalItems()}
            </div>
          </div>
          <p className="no-underline">Cart</p>
        </div>
      </Link>
      <Link
        to={"/login"}
        className="w-full px-4 py-2 text-sm text-gray-700"
        style={{ textDecoration: "none" }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="">
            <CgProfile className="text-2xl" />
          </div>
          <p className="no-underline">Login</p>
        </div>
      </Link>
    </div>
  );
};

export default MobileNav;
