import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useEffect, useState } from "react";
import { decryptData } from "../hooks/DataEncDec";

export const MyCartContext = createContext();

export default function CartContext({ children }) {
  const [cartLength, setCartLength] = useState(
    localStorage.getItem("cartLength")
  );

  const [num_of_box, setNumberOfBox] = useState(0);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [boxes, setBoxes] = useState([]);

  const [localCart, setLocalCart] = useState([]);

  useEffect(() => {
    let sessionId = Cookies.get("sessionId");
    if (sessionId === null || sessionId === undefined) {
      window.location.reload();
    }
    const data = {
      sessionId: sessionId,
    };

    axios
      .post("/api/get-cart", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then(async (res) => {
        if (res.data.status === 200) {
          let encCart = res.data.cart;
          //////console.log('ecrypted Cart:', encCart);
          if (encCart === null) {
            setCart([]);
            localStorage.setItem("cartLength", 0);
          } else {
            let temp_cart = await decryptData(encCart);

            localStorage.setItem("cartLength", temp_cart.length);
            let num = 0;
            let box_serials = [];

            await temp_cart.forEach((item) => {
              num = Math.max(num, item.box_num);

              if (item.box_num !== 0) box_serials.push(item.box_num);
            });

            let temp_serials = [...new Set(box_serials)];
            box_serials = temp_serials;
            //   //console.log(box_serials)

            let temp_boxes = [];

            box_serials.forEach((serial) => {
              let items = [];

              temp_cart.forEach((item) => {
                if (serial === item.box_num) {
                  items.push(item);
                }
              });
              const newBox = {
                box_id: serial,
                items: items,
              };

              temp_boxes.push(newBox);
            });
            // //console.log(temp_boxes)
            setBoxes([...temp_boxes]);

            setCart(temp_cart);
          }
          setLoading(false);
        }
      });
  }, []);

  //console.log(cart)

  return (
    <MyCartContext.Provider
      value={{
        localCart,
        setLocalCart,
        setNumberOfBox,
        num_of_box,
        boxes,
        setBoxes,
        setCart,
        cart,
        boxes,
        cartLength,
        setCartLength,
      }}
    >
      {children}
    </MyCartContext.Provider>
  );
}
