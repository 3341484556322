import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import blogImage from "../../images/demoblog.avif";

const Blog = () => {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  useEffect(() => {
    axios
      .get(`/api/get-blogs/${params.id}`, {
        headers: { "Allowed-Source": allowedSource },
      })

      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          // setBlogs(res.data.blog);
        } else {
        }
        // setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
      });
  }, [history]);

  return (
    <div className="flex justify-center items-center py-4">
      <div className="w-full md:w-1/2 md:shadow-xl rounded-md overflow-hidden">
        <div>
          <img className="w-full" src={blogImage} alt="" />
        </div>
        <div className="text-black my-5 px-3 space-y-5">
          <h5 className="text-xl font-bold tracking-wider">Title</h5>
          <p className="text-left tracking-wide leading-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
            quod rerum exercitationem quasi eum itaque, excepturi eos
            consequatur voluptas nesciunt quae sed blanditiis tempora. Quos,
            quae aut libero cumque natus molestiae quidem aspernatur odit odio
            aperiam repellendus assumenda neque inventore at iure enim eius,
            nisi a nobis? Asperiores omnis libero sapiente fuga corporis at
            possimus laboriosam veniam dolor quam vitae, maxime itaque nihil ex
            cum qui optio quos. Quis ipsa officia ea, cumque provident esse
            voluptates delectus laudantium quaerat, similique autem fugit in,
            expedita magnam. Laudantium mollitia ipsum quisquam non nostrum
            soluta atque eius veritatis earum suscipit aut sunt quas optio nemo,
            magni similique voluptates aperiam? Illo, qui, quasi, culpa
            molestias ea ex temporibus nemo amet inventore enim earum eos non
            suscipit atque laudantium expedita fugit reiciendis est? Accusantium
            alias eveniet voluptatem commodi, quo corporis illo harum nostrum
            dolorem accusamus non doloribus recusandae natus. Soluta
            necessitatibus laboriosam illum praesentium tempore quia dolor quam,
            fuga rerum omnis veniam sapiente. Placeat ullam adipisci incidunt
            dicta, cupiditate ab maiores esse minus fuga temporibus excepturi!
            Incidunt quo veniam eaque quasi doloremque! At porro fugit possimus
            eum deleniti, laboriosam ea voluptates doloribus tempore provident
            ab molestias eveniet? Ratione laborum sequi accusantium impedit nemo
            iste architecto cumque. Ratione nostrum ullam ipsa commodi minima
            fuga, eum quibusdam a quae necessitatibus officiis aperiam, ipsum
            aliquam voluptatem fugit, voluptatibus aliquid nisi velit. Molestiae
            eaque tenetur excepturi necessitatibus. Cumque, est nesciunt
            perferendis laudantium tenetur fugiat, atque magnam unde
            necessitatibus sed beatae! Fugiat odit sit fuga minima veritatis
            porro est, sunt culpa provident expedita soluta? Consequuntur
            quisquam a pariatur ut iste, nulla aliquid obcaecati officia,
            necessitatibus voluptatibus ad fuga nisi? Reprehenderit beatae nobis
            explicabo aliquid libero eum cumque at tempora consequuntur
            accusantium atque aspernatur quibusdam odit asperiores temporibus
            recusandae, facilis, veritatis maxime veniam autem. Provident rerum
            inventore nostrum illum, quasi voluptates?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Blog;
