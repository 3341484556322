import React, { useEffect } from "react";

export default function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-black" style={{ marginTop: "8rem" }}>
      <div class="m-5">
        <h1>Terms & Conditions</h1>
        <div class="page-wrapper">
          <h4>পরিষেবা চুক্তির শর্তাবলী:</h4>
          <p>
            <strong>
              দয়া করে এই ওয়েবসাইটটি ব্যবহার করে বা এই ওয়েবসাইট থেকে পণ্য
              অর্ডার করার মাধ্যমে এই পরিষেবার চুক্তির শর্তাবলী সাবধানে পড়ুন
              আপনি এই চুক্তির সমস্ত শর্তাবলীর সাথে আবদ্ধ হতে সম্মত আছেন।
            </strong>
          </p>
          <p>
            এই পরিষেবার চুক্তির শর্তাবলী ("চুক্তি") এই ওয়েবসাইট,
            www.shokhibox.com ("ওয়েবসাইট"), Shokhi Box ("ব্যবসায়িক নাম") এই
            ওয়েবসাইট থেকে কেনার জন্য পণ্যের অফার, বা এই ওয়েবসাইট থেকে পণ্য
            কেনার জন্য। এই চুক্তির অন্তর্ভুক্ত, এবং এই রেফারেন্স দ্বারা
            অন্তর্ভুক্ত, নীতি এবং নির্দেশিকা নীচে উল্লেখ করা হয়েছে৷
          </p>
          <p>
            Shokhi Box এই ওয়েবসাইটে যেকোনো পরিবর্তন বা একটি সংশোধিত চুক্তি
            পোস্ট করে যেকোনো সময় এই চুক্তির শর্তাবলী পরিবর্তন বা সংশোধন করার
            অধিকার সংরক্ষণ করে। Shokhi Box আপনাকে সতর্ক করবে যে এই চুক্তির উপরে
            এটি সর্বশেষ সংশোধিত হওয়ার তারিখটি উল্লেখ করে পরিবর্তন বা সংশোধন করা
            হয়েছে। পরিবর্তিত বা সংশোধিত চুক্তিটি এই ওয়েবসাইটে পোস্ট করার সাথে
            সাথেই কার্যকর হবে। এই ধরনের কোনো পরিবর্তন বা একটি সংশোধিত চুক্তি
            পোস্ট করার পরে আপনার ওয়েবসাইটটির ব্যবহার এই ধরনের কোনো পরিবর্তন বা
            সংশোধনের আপনার গ্রহণযোগ্যতা স্থাপন করবে। Shokhi Box আপনাকে এই চুক্তি
            পর্যালোচনা করার জন্য উত্সাহিত করে যখনই আপনি ওয়েবসাইট পরিদর্শন করেন
            তা নিশ্চিত করার জন্য যে আপনি ওয়েবসাইটের ব্যবহার নিয়ন্ত্রণকারী
            শর্তাবলী বোঝেন। এই চুক্তিটি অন্যান্য পণ্য বা পরিষেবাগুলির জন্য
            Shokhi Box-এর সাথে আপনার হতে পারে এমন অন্য কোনও লিখিত চুক্তির
            শর্তাদি বা শর্তাবলীর কোনো পরিবর্তন করে না। আপনি যদি এই চুক্তিতে
            সম্মত না হন (কোন উল্লেখিত নীতি বা নির্দেশিকা সহ), অনুগ্রহ করে
            অবিলম্বে ওয়েবসাইটটির ব্যবহার বন্ধ করুন। আপনি যদি এই চুক্তিটি
            প্রিন্ট করতে চান, তাহলে অনুগ্রহ করে আপনার ব্রাউজার টুলবারে প্রিন্ট
            বোতামে ক্লিক করুন।
          </p>
          <h4>১. পণ্য</h4>
          <p>
            অফার শর্তাবলী: এই ওয়েবসাইটটি নির্দিষ্ট কিছু পণ্য ("পণ্য সমূহ")
            বিক্রির প্রস্তাব দেয়। এই ওয়েবসাইটের মাধ্যমে পণ্যের জন্য অর্ডার
            দেওয়ার মাধ্যমে, আপনি এই চুক্তিতে উল্লিখিত শর্তাবলীতে সম্মত হন।
          </p>
          <p>
            <strong>
              <u>গ্রাহক অনুরোধ:</u>
            </strong>
            <br />
            আপনি যদি আমাদের তৃতীয় পক্ষের কল সেন্টার প্রতিনিধিদেরকে অবহিত না
            করেন, যখন তারা আপনাকে কল করছে, কোম্পানির আরও সরাসরি যোগাযোগ এবং
            অনুরোধগুলি থেকে অপ্ট আউট করার ইচ্ছা সম্পর্কে, আপনি আরও ইমেল এবং কল
            সলিসিটেশনগুলি পেতে সম্মত হচ্ছেন Shokhi Box এবং এটি ঘরে বা তৃতীয়
            স্থানে মনোনীত পার্টি কল দল(গুলি)।
          </p>
          <p>
            <strong>
              <u>অপ্ট আউট পদ্ধতি:</u>
            </strong>
            <br />
            আমরা ভবিষ্যত অনুরোধগুলি থেকে অপ্ট আউট করার ২টি সহজ উপায় অনুসরণ করি:
          </p>
          <ul>
            <li>
              ১) আপনি যে কোনো ইমেল অনুরোধে পাওয়া অপ্ট আউট লিঙ্কটি ব্যবহার করতে
              পারেন।
            </li>
            <li>
              ২) আপনি আপনার ইমেল ঠিকানা পাঠানোর মাধ্যমে অপ্ট আউট করতে পারেন:
              support@shikhibox.com
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>
              <u>মালিকানার অধিকার:</u>
            </strong>
            <br />
            পণ্যগুলিতে Shokhi Box-এর মালিকানা অধিকার এবং বাণিজ্য গোপনীয়তা
            রয়েছে৷ আপনি Shokhi Box দ্বারা উত্পাদিত এবং/অথবা বিতরণ করা কোনও পণ্য
            অনুলিপি, পুনরুত্পাদন, পুনরায় বিক্রয় বা পুনরায় বিতরণ করতে পারবেন
            না। কল টু অ্যাকশন, টেক্সট বসানো, ছবি এবং অন্যান্য তথ্য সহ এই
            ওয়েবপৃষ্ঠার সমস্ত ট্রেডমার্ক এবং ট্রেড ড্রেস এবং নির্দিষ্ট লেআউটের
            অধিকারও Shokhi Box-এর রয়েছে।
          </p>
          <p>
            <strong>
              <u>সেলস ট্যাক্স (বিক্রয় কর):</u>
            </strong>
            <br />
            আপনি যদি কোনো পণ্য ক্রয় করেন, তাহলে আপনি যে কোনো প্রযোজ্য সেলস
            ট্যাক্স পরিশোধের জন্য দায়ী থাকবেন।
          </p>
          <h4>২. ওয়েবসাইট</h4>
          <p>
            <strong>
              <u>বিষয়বস্তু, বুদ্ধিবৃত্তিক সম্পত্তি এবং তৃতীয় পক্ষের লিঙ্ক:</u>
            </strong>
            <br />
            পণ্যগুলি উপলব্ধ করার পাশাপাশি, এই ওয়েবসাইটটি তথ্য এবং বিপণন
            সামগ্রীও সরবরাহ করে। এই ওয়েবসাইটটি তৃতীয় পক্ষের ওয়েবসাইটের
            প্রত্যক্ষ এবং পরোক্ষ লিঙ্কের মাধ্যমেও তথ্য সরবরাহ করে। Shokhi Box
            সবসময় এই ওয়েবসাইটে দেওয়া তথ্য তৈরি করে না; পরিবর্তে তথ্য প্রায়ই
            অন্যান্য উত্স থেকে সংগ্রহ করা হয়. Shokhi Box এই ওয়েবসাইটে যে
            পরিমাণ সামগ্রী তৈরি করে, এই ধরনের বিষয়বস্তু ভারত, বাংলাদেশ, বিদেশী
            দেশ এবং আন্তর্জাতিক সংস্থাগুলির মেধা সম্পত্তি আইন দ্বারা সুরক্ষিত।
            উপাদানের অননুমোদিত ব্যবহার কপিরাইট, ট্রেডমার্ক, এবং/অথবা অন্যান্য
            আইন লঙ্ঘন করতে পারে। আপনি স্বীকার করেন যে এই ওয়েবসাইটের বিষয়বস্তুর
            আপনার ব্যবহার ব্যক্তিগত, অবাণিজ্যিক ব্যবহারের জন্য। তৃতীয় পক্ষের
            ওয়েবসাইটের যেকোনো লিঙ্ক শুধুমাত্র আপনার সুবিধার জন্য প্রদান করা
            হয়। Shokhi Box এই ধরনের কোনো তৃতীয় পক্ষের ওয়েবসাইটের বিষয়বস্তু
            অনুমোদন করে না। Shokhi Box এই থার্ড-পার্টি ওয়েবসাইটগুলিতে আপনার
            অ্যাক্সেস বা নির্ভরতার কারণে সামগ্রীর সামগ্রী বা কোনও ক্ষতির জন্য
            দায়ী নয়। আপনি যদি তৃতীয় পক্ষের ওয়েবসাইটগুলিতে লিঙ্ক করেন তবে
            আপনি নিজের ঝুঁকিতে তা করবেন।
          </p>
          <p>
            <strong>
              <u>ওয়েবসাইট ব্যবহার:</u>
            </strong>
            <br />
            Shokhi Box এই ওয়েবসাইটটি ব্যবহার করার ফলে যে কোন ক্ষতির জন্য দায়ী
            নয়। আপনি অবৈধ উদ্দেশ্যে ওয়েবসাইট ব্যবহার করবেন না. আপনি (1)
            ওয়েবসাইটের আপনার ব্যবহারে সমস্ত প্রযোজ্য স্থানীয়, রাষ্ট্রীয়,
            জাতীয় এবং আন্তর্জাতিক আইন ও প্রবিধান মেনে চলবেন (মেধাস্বত্ব
            সংক্রান্ত আইন সহ), (2) ওয়েবসাইটের ব্যবহার এবং উপভোগে হস্তক্ষেপ বা
            ব্যাঘাত ঘটাবেন না। অন্যান্য ব্যবহারকারীরা, (3) ওয়েবসাইটে সামগ্রী
            পুনঃবিক্রয় করবেন না, (4) প্রত্যক্ষ বা পরোক্ষভাবে "স্প্যাম", চেইন
            লেটার, জাঙ্ক মেল বা অন্য কোনো ধরনের অযাচিত যোগাযোগের সংক্রমণে জড়িত
            হবেন না এবং (5) মানহানি করবেন না, ওয়েবসাইটের অন্যান্য
            ব্যবহারকারীদের হয়রানি, অপব্যবহার বা ব্যাহত করবেন না।
          </p>
          <p>
            <strong>
              <u>পোস্টিং:</u>
            </strong>
            <br />
            ওয়েবসাইটে কোনো বিষয়বস্তু পোস্ট, সঞ্চয় বা ট্রান্সমিট করার মাধ্যমে,
            আপনি এতদ্বারা Shokhi Box-কে একটি চিরস্থায়ী, বিশ্বব্যাপী,
            অ-এক্সক্লুসিভ, রয়্যালটি-মুক্ত, বরাদ্দযোগ্য, অধিকার এবং লাইসেন্স
            ব্যবহার, অনুলিপি, প্রদর্শন, সঞ্চালন, এর থেকে ডেরিভেটিভ কাজ তৈরি,
            বিতরণ করার অনুমতি দিচ্ছে। বিশ্বের যেকোন জায়গায়, এখন পরিচিত বা এর
            পরে তৈরি করা সমস্ত মিডিয়াতে যে কোনও আকারে এই জাতীয় সামগ্রী বিতরণ,
            প্রেরণ এবং বরাদ্দ করেছে। Shokhi Box-এর ওয়েবসাইটের মাধ্যমে দেওয়া
            ব্যবহারকারী-উত্পাদিত সামগ্রীর প্রকৃতি নিয়ন্ত্রণ করার ক্ষমতা নেই।
            আপনি ওয়েবসাইটের অন্যান্য ব্যবহারকারীদের সাথে আপনার মিথস্ক্রিয়া এবং
            আপনার পোস্ট করা যেকোনো বিষয়বস্তুর জন্য সম্পূর্ণরূপে দায়ী। Shokhi
            Box ব্যবহারকারীদের মধ্যে কোনো পোস্ট বা ইন্টারঅ্যাকশনের ফলে কোনো
            ক্ষতি বা ক্ষতির জন্য দায়ী নয়। Shokhi Box ওয়েবসাইটের
            ব্যবহারকারীদের মধ্যে এবং তাদের মধ্যে মিথস্ক্রিয়া নিরীক্ষণ করার এবং
            Shokhi Box-এর আপত্তিকর মনে করে এমন কোনও সামগ্রী সরিয়ে ফেলার অধিকার
            সংরক্ষণ করে, কিন্তু কোনও বাধ্যবাধকতা নেই।
          </p>
          <p>
            <strong>
              <u>৩. ওয়্যারেন্টি অস্বীকৃতি</u>
            </strong>
            <br />
            এই ওয়েবসাইট এবং/অথবা পণ্যগুলির আপনার ব্যবহার আপনার একমাত্র ঝুঁকিতে।
            ওয়েবসাইট এবং পণ্যগুলি "যেমন আছে" এবং "যেমন উপলব্ধ" ভিত্তিতে দেওয়া
            হয়৷ Shokhi Box স্পষ্টভাবে যে কোনো ধরনের ওয়্যারেন্টি অস্বীকার করে,
            তা প্রকাশ করা হোক বা উহ্য, সহ, কিন্তু এর মধ্যেই সীমাবদ্ধ নয়, কোনো
            বিশেষ উদ্দেশ্যের জন্য পণ্যের উহ্য ওয়্যারেন্টিগুলিকে অস্বীকার করে
            ওয়েবসাইট বিষয়বস্তু বা পণ্য ("পণ্য" ট্রায়াল পণ্য অন্তর্ভুক্ত করে)।
          </p>
          <h4>
            পূর্বোক্ত বিষয়গুলো সীমাবদ্ধ না করে, Shokhi Box কোন ওয়ারেন্টি দেয়
            না:
          </h4>
          <ul>
            <li>
              এই ওয়েবসাইটে দেওয়া তথ্য সঠিক, নির্ভরযোগ্য, সম্পূর্ণ বা
              সময়োপযোগী।
            </li>
            <li>
              যে তৃতীয় পক্ষের ওয়েবসাইটগুলির লিঙ্কগুলি সঠিক, নির্ভরযোগ্য,
              সম্পূর্ণ বা সময়োপযোগী তথ্যের জন্য।
            </li>
            <li>
              কোন উপদেশ বা তথ্য, মৌখিক বা লিখিত যাই হোক না কেন, এই ওয়েবসাইট
              থেকে আপনি প্রাপ্ত কোন ওয়্যারেন্টি তৈরি করবে না যা এখানে
              স্পষ্টভাবে বলা হয়নি।
            </li>
            <li>
              পণ্যের ব্যবহার থেকে প্রাপ্ত ফলাফল বা পণ্যের ত্রুটিগুলি সংশোধন করা
              হবে৷
            </li>
            <li>ওয়েবসাইটের মাধ্যমে কেনা বা প্রাপ্ত যেকোনো পণ্যের বিষয়ে।</li>
          </ul>
          <p>
            কিছু এখতিয়ার কিছু নির্দিষ্ট ওয়্যারেন্টি বাদ দেওয়ার অনুমতি দেয়
            না, তাই উপরের কিছু বর্জন আপনার জন্য প্রযোজ্য নাও হতে পারে।
          </p>
          <h4>৪. দায়বদ্ধতা সীমাবদ্ধতা</h4>
          <p>
            Shokhi Box ওয়েবসাইটের বিষয়বস্তু এবং পণ্য এবং/অথবা এই চুক্তির কোনো
            লঙ্ঘনের জন্য আইনানুযায়ী, ন্যায্যতা বা অন্যথায় কোনো দায়বদ্ধতা বা
            একচেটিয়া প্রতিকার গ্রহণ করে না।
          </p>
          <p>
            Shokhi Box কোনো প্রত্যক্ষ, পরোক্ষ, আকস্মিক, বিশেষ বা ফলশ্রুতিগত
            ক্ষতির জন্য এই চুক্তি বা পণ্যগুলির সাথে সংযোগে দায়বদ্ধ থাকবে না।
          </p>
          <p>
            (১) ওয়েবসাইটের বিষয়বস্তু বা পণ্যগুলি ব্যবহার বা ব্যবহারে অক্ষমতা;
            (২) বিকল্প পণ্য বা সামগ্রী সংগ্রহের খরচ; (৩) যে কোনো পণ্য কেনা বা
            প্রাপ্ত বা ওয়েবসাইটের মাধ্যমে প্রবেশ করা লেনদেন; অথবা (৪) আপনি
            অভিযোগ করেন যে কোনো হারানো লাভ।
          </p>
          <h4>৫. ক্ষতিপূরণ</h4>
          <p>
            আপনি Shokhi Box এবং এর যেকোন ঠিকাদার, এজেন্ট, কর্মচারী, কর্মকর্তা,
            পরিচালক, শেয়ারহোল্ডার, সহযোগীদের মুক্তি দেবেন, ক্ষতিপূরণ দেবেন,
            রক্ষা করবেন এবং ধরে রাখবেন এবং যুক্তিসঙ্গত অ্যাটর্নিদের ফি এবং খরচ
            সহ সমস্ত দায়, দাবি, ক্ষতি, খরচ এবং খরচ থেকে বরাদ্দ করবেন, (1) এই
            চুক্তি বা এই চুক্তির অধীনে আপনার ওয়্যারেন্টি, প্রতিনিধিত্ব এবং
            বাধ্যবাধকতার লঙ্ঘনের সাথে সম্পর্কিত বা উদ্ভূত তৃতীয় পক্ষের; (2)
            ওয়েবসাইটের বিষয়বস্তু বা ওয়েবসাইটের বিষয়বস্তুর আপনার ব্যবহার; (3)
            পণ্য বা আপনার পণ্যের ব্যবহার (ট্রায়াল পণ্য সহ); (4) কোনো ব্যক্তি বা
            সত্তার কোনো বুদ্ধিবৃত্তিক সম্পত্তি বা অন্যান্য মালিকানা অধিকার; (5)
            এই চুক্তির কোনো বিধান আপনার লঙ্ঘন; অথবা (6) Shokhi Box-এ আপনি যে
            কোনো তথ্য বা ডেটা সরবরাহ করেছেন। যখন Shokhi Box কে মামলার হুমকি
            দেওয়া হয় বা তৃতীয় পক্ষের দ্বারা মামলা করা হয় তখন Shokhi Box
            ক্ষতিপূরণ দেওয়ার প্রতিশ্রুতি সম্পর্কে আপনার কাছ থেকে লিখিত আশ্বাস
            চাইতে পারে; এই ধরনের আশ্বাস প্রদানে আপনার ব্যর্থতাকে Shokhi Box এই
            চুক্তির বস্তুগত লঙ্ঘন বলে বিবেচনা করতে পারে। Shokhi Box এর খরচে
            Shokhi Box-এর পছন্দের পরামর্শ সহ, ওয়েবসাইটের যেকোনও বিষয়বস্তু বা
            পণ্যের আপনার ব্যবহার সম্পর্কিত তৃতীয়-পক্ষের দাবির প্রতিরক্ষায় অংশ
            নেওয়ার অধিকার থাকবে। Shokhi Box আপনার অনুরোধ এবং খরচে তৃতীয় পক্ষের
            দাবির প্রতিরক্ষায় যুক্তিসঙ্গতভাবে সহযোগিতা করবে। যেকোনো দাবির
            বিরুদ্ধে Shokhi Box কে রক্ষা করার একমাত্র দায়বদ্ধতা আপনার থাকবে,
            তবে আপনাকে অবশ্যই Shokhi Box এর সাথে সম্পর্কিত যেকোন নিষ্পত্তির
            বিষয়ে পূর্বে লিখিত সম্মতি পেতে হবে। এই বিধানের শর্তাবলী এই চুক্তির
            যেকোন সমাপ্তি বা বাতিল বা ওয়েবসাইট বা পণ্যগুলির আপনার ব্যবহার থেকে
            বেঁচে থাকবে।
          </p>
          <p>
            কিছু বিচার বিভাগ আনুষঙ্গিক বা ফলস্বরূপ ক্ষতির জন্য দায়বদ্ধতার
            সীমাবদ্ধতা বা বর্জনের অনুমতি দেয় না তাই উপরের কিছু সীমাবদ্ধতা আপনার
            জন্য প্রযোজ্য নাও হতে পারে।
          </p>
          <h4>গোপনীয়তা</h4>
          <p>
            Shokhi Box ব্যবহারকারীর গোপনীয়তা রক্ষায় এবং Shokhi Box-এর ডেটা
            ব্যবহারের নোটিশ প্রদানে দৃঢ়ভাবে বিশ্বাস করে। অনুগ্রহ করে Shokhi Box
            গোপনীয়তা নীতি দেখুন, এখানে রেফারেন্স দ্বারা অন্তর্ভুক্ত করা হয়েছে,
            যা ওয়েবসাইটে পোস্ট করা হয়েছে।
          </p>
          <h4>আবদ্ধ হওয়ার চুক্তি</h4>
          <p>
            এই ওয়েবসাইটটি ব্যবহার করে বা পণ্য অর্ডার করার মাধ্যমে, আপনি স্বীকার
            করেন যে আপনি এই চুক্তি এবং এই ওয়েবসাইটের সমস্ত শর্তাবলী পড়েছেন এবং
            শর্ত মেনে চলতে সম্মত হন।
          </p>
          <h4>ঘটনা:</h4>
          <p>
            Shokhi Box এখানে ডিফল্ট হিসাবে বিবেচিত হবে না বা ভূমিকম্প, বন্যা,
            আগুন, ঝড়, প্রাকৃতিক দুর্যোগ, ঈশ্বরের কাজ, যুদ্ধ, সন্ত্রাস, সশস্ত্র
            সংঘাত, ধর্মঘট, লকআউট বা বয়কট ইত্যাদি কারণে এখানে তার কাজ সম্পাদনে
            কোনও বন্ধ, বাধা বা বিলম্বের জন্য দায়ী করা হবে না।
          </p>
          <p>
            <strong>
              <u>অপারেশন বন্ধ:</u>
            </strong>
            <br />
            Shokhi Box যেকোন সময়, তার নিজস্ব বিবেচনার ভিত্তিতে এবং আপনাকে আগাম
            নোটিশ ছাড়াই, ওয়েবসাইটের কার্যক্রম এবং পণ্যের বিতরণ বন্ধ করে দিতে
            পারে।
          </p>
          <p>
            <strong>
              <u>সামগ্রিক চুক্তিনামা:</u>
            </strong>
            <br />
            এই চুক্তিটি আপনার এবং Shokhi Box-এর মধ্যে সম্পূর্ণ চুক্তিকে
            অন্তর্ভুক্ত করে এবং এখানে থাকা বিষয়ের সাথে সম্পর্কিত যেকোন পূর্বের
            চুক্তিগুলিকে বাতিল করে।
          </p>
          <p>
            <strong>
              <u>মওকুফের প্রভাব:</u>
            </strong>
            <br />
            এই চুক্তির কোনো অধিকার বা বিধান প্রয়োগ বা প্রয়োগ করতে Shokhi
            Box-এর ব্যর্থতা এই ধরনের অধিকার বা বিধানের প্রত্যাহার গঠন করবে না।
            এই চুক্তির কোন বিধান যদি উপযুক্ত বিচার বিভাগের আদালত দ্বারা অবৈধ বলে
            পাওয়া যায়, তবে পক্ষগুলি সম্মত হয় যে আদালতের পক্ষের উদ্দেশ্যগুলিকে
            কার্যকর করার চেষ্টা করা উচিত যে বিধানটিতে প্রতিফলিত হয়েছে এবং এই
            চুক্তির অন্যান্য বিধানগুলি রয়ে গেছে পূর্ণ শক্তি এবং প্রভাব।
          </p>
          <p>
            <strong>
              <u>পরিচালনা আইন ও এখতিয়ার:</u>
            </strong>
            <br />
            এই ওয়েবসাইটটির উৎপত্তি কর্ণাটকের বেঙ্গালুরু শহর থেকে। এই চুক্তিটি
            কর্ণাটক রাজ্যের আইন দ্বারা শাসিত হবে এর বিপরীতে আইন নীতির বিরোধ
            বিবেচনা না করে। আপনি এই চুক্তির বিধানগুলি কার্যকর করার জন্য, এই
            চুক্তির লঙ্ঘন বা ডিফল্টের জন্য ক্ষতি পুনরুদ্ধারের জন্য, বা অন্যথায়
            এই চুক্তির অধীনে বা কারণে উদ্ভূত, রাজ্যে অবস্থিত আদালত ব্যতীত
            অন্যথায় কোনো মামলা, প্রক্রিয়া বা দাবি শুরু বা বিচার করবেন না
            কর্ণাটকের। এই ওয়েবসাইটটি ব্যবহার করে বা পণ্য অর্ডার করার মাধ্যমে,
            আপনি এই চুক্তির অধীনে বা এর কারণে উদ্ভূত যে কোনও অ্যাকশন, মামলা,
            কার্যক্রম বা দাবির ক্ষেত্রে এই ধরনের আদালতের এখতিয়ার এবং স্থানের
            সাথে সম্মত হন। আপনি এতদ্বারা এই চুক্তি এবং যেকোন সম্পর্কিত নথি থেকে
            উদ্ভূত জুরি দ্বারা বিচারের যে কোনো অধিকার পরিত্যাগ করেন।
          </p>
          <p>
            <strong>
              <u>সীমাবদ্ধতার সংবিধি:</u>
            </strong>
            <br />
            আপনি সম্মত হন যে বিপরীতে যে কোনও আইন বা আইন যাই হোক না কেন,
            ওয়েবসাইট বা পণ্যগুলির ব্যবহার বা এই চুক্তির সাথে সম্পর্কিত যে কোনও
            দাবি বা কর্মের কারণ এই ধরনের দাবি বা পদক্ষেপের কারণের এক (1) বছরের
            মধ্যে দায়ের করতে হবে উত্থিত হয় বা চিরতরে বাধা দেওয়া হয়।
          </p>
          <p>
            <strong>
              <u>ক্লাস অ্যাকশন অধিকার মওকুফ</u>
            </strong>
            <br />
            এই চুক্তিতে প্রবেশ করার মাধ্যমে, আপনি এতদ্বারা অপরিবর্তনীয়ভাবে
            অন্যদের সাথে একটি ক্লাস অ্যাকশন বা অনুরূপ পদ্ধতিগত ডিভাইসের আকারে
            দাবিতে যোগদান করার অধিকারকে পরিত্যাগ করবেন৷ এই চুক্তির সাথে
            সম্পর্কিত, বা সংযোগ থেকে উদ্ভূত যেকোন দাবিগুলি অবশ্যই স্বতন্ত্রভাবে
            নিশ্চিত করা উচিত৷
          </p>
          <p>
            <strong>
              <u>সমাপ্তি:</u>
            </strong>
            <br />
            Shokhi Box ওয়েবসাইটটিতে আপনার অ্যাক্সেস বন্ধ করার অধিকার সংরক্ষণ
            করে যদি এটি যুক্তিসঙ্গতভাবে বিশ্বাস করে যে, তার একমাত্র বিবেচনার
            ভিত্তিতে, আপনি এই চুক্তির যেকোনো শর্তাবলী লঙ্ঘন করেছেন। সমাপ্তির
            পরে, আপনাকে ওয়েবসাইটটি ব্যবহার করার অনুমতি দেওয়া হবে না এবং Shokhi
            Box, তার নিজস্ব বিবেচনার ভিত্তিতে এবং আপনাকে অগ্রিম নোটিশ ছাড়াই,
            পণ্যগুলির জন্য কোনও বকেয়া অর্ডার বাতিল করতে পারে। যদি ওয়েবসাইটটিতে
            আপনার অ্যাক্সেস বন্ধ করা হয়, Shokhi Box ওয়েবসাইটের অননুমোদিত
            অ্যাক্সেস রোধ করার জন্য প্রয়োজনীয় বলে মনে করা যাই হোক না কেন
            অনুশীলন করার অধিকার সংরক্ষণ করে। এই চুক্তিটি অনির্দিষ্টকালের জন্য
            টিকে থাকবে যতক্ষণ না এবং যতক্ষণ না Shokhi Box তার নিজস্ব বিবেচনার
            ভিত্তিতে এবং আপনাকে অগ্রিম না করে, এটিকে বাতিল করতে বেছে নেয়।
          </p>
          <p>
            <strong>
              <u>অ্যাসাইনমেন্ট:</u>
            </strong>
            <br />
            আপনি এই চুক্তির অধীনে আপনার অধিকার এবং বাধ্যবাধকতা অন্য কাউকে অর্পণ
            করতে পারবেন না। Shokhi Box তার নিজস্ব বিবেচনার ভিত্তিতে এবং আপনাকে
            অগ্রিম বিজ্ঞপ্তি ছাড়াই এই চুক্তির অধীনে তার অধিকার এবং
            বাধ্যবাধকতাগুলি অর্পণ করতে পারে।
          </p>
          <p>
            <strong>
              এই ওয়েবসাইটটি ব্যবহার করে বা এই ওয়েবসাইট থেকে পণ্য অর্ডার করার
              মাধ্যমে আপনি এই চুক্তির সমস্ত শর্তাবলীর সাথে আবদ্ধ হতে সম্মত হন৷
            </strong>
          </p>
        </div>
        <div class="page-wrapper">
          <h1>TERMS &amp; CONDITIONS</h1>
          <h4>TERMS OF SERVICE AGREEMENT</h4>
          <p>
            <strong>
              PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING
              THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO
              BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            </strong>
          </p>
          <p>
            This Terms of Service Agreement (the “Agreement”) governs your use
            of this website, www.shokhibox.com (the “Website”), Shokhi Box
            (“Business Name”) offer of products for purchase on this Website, or
            your purchase of products available on this Website. This Agreement
            includes, and incorporates by this reference, the policies and
            guidelines referenced below. Shokhi Box reserves the right to change
            or revise the terms and conditions of this Agreement at any time by
            posting any changes or a revised Agreement on this Website. Shokhi
            Box will alert you that changes or revisions have been made by
            indicating on the top of this Agreement the date it was last
            revised. The changed or revised Agreement will be effective
            immediately after it is posted on this Website. Your use of the
            Website following the posting any such changes or of a revised
            Agreement will constitute your acceptance of any such changes or
            revisions. Shokhi Box encourages you to review this Agreement
            whenever you visit the Website to make sure that you understand the
            terms and conditions governing use of the Website. This Agreement
            does not alter in any way the terms or conditions of any other
            written agreement you may have with Shokhi Box for other products or
            services. If you do not agree to this Agreement (including any
            referenced policies or guidelines), please immediately terminate
            your use of the Website. If you would like to print this Agreement,
            please click the print button on your browser toolbar.
          </p>
          <h4>I. PRODUCTS</h4>
          <p>
            Terms of Offer. This Website offers for sale certain products (the
            “Products”). By placing an order for Products through this Website,
            you agree to the terms set forth in this Agreement.
          </p>
          <p>
            <strong>
              <u>Customer Solicitation:</u>
            </strong>
            <br />
            Unless you notify our third party call center reps, while they are
            calling you, of your desire to opt out from further direct company
            communications and solicitations, you are agreeing to continue to
            receive further emails and call solicitations Shokhi Box and its
            designated in house or third party call team(s).
          </p>
          <p>
            <strong>
              <u>Opt Out Procedure:</u>
            </strong>
            <br />
            We provide 2 easy ways to opt out of from future solicitations.
          </p>
          <ul>
            <li>
              You may use the opt out link found in any email solicitation that
              you may receive.
            </li>
            <li>
              You may also choose to opt out, via sending your email address to:
              support@shikhibox.com
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>
              <u>Proprietary Rights:</u>
            </strong>
            <br />
            Shokhi Box has proprietary rights and trade secrets in the Products.
            You may not copy, reproduce, resell or redistribute any Product
            manufactured and/or distributed by Shokhi Box. Shokhi Box also has
            rights to all trademarks and trade dress and specific layouts of
            this webpage, including calls to action, text placement, images and
            other information.
          </p>
          <p>
            <strong>
              <u>Sales Tax:</u>
            </strong>
            <br />
            If you purchase any Products, you will be responsible for paying any
            applicable sales tax.
          </p>
          <h4>II. WEBSITE</h4>
          <p>
            <strong>
              <u>Content, Intellectual Property &amp; Third Party Links:</u>
            </strong>
            <br />
            In addition to making Products available, this Website also offers
            information and marketing materials. This Website also offers
            information, both directly and through indirect links to third-party
            websites. Shokhi Box does not always create the information offered
            on this Website; instead the information is often gathered from
            other sources. To the extent that Shokhi Box does create the content
            on this Website, such content is protected by intellectual property
            laws of the India, Bangladesh, foreign nations, and international
            bodies. Unauthorized use of the material may violate copyright,
            trademark, and/or other laws. You acknowledge that your use of the
            content on this Website is for personal, noncommercial use. Any
            links to third-party websites are provided solely as a convenience
            to you. Shokhi Box does not endorse the contents on any such
            third-party websites. Shokhi Box is not responsible for the content
            of or any damage that may result from your access to or reliance on
            these third-party websites. If you link to third-party websites, you
            do so at your own risk.
          </p>
          <p>
            <strong>
              <u>Use of Website:</u>
            </strong>
            <br />
            Shokhi Box is not responsible for any damages resulting from use of
            this website by anyone. You will not use the Website for illegal
            purposes. You will (1) abide by all applicable local, state,
            national, and international laws and regulations in your use of the
            Website (including laws regarding intellectual property), (2) not
            interfere with or disrupt the use and enjoyment of the Website by
            other users, (3) not resell material on the Website, (4) not engage,
            directly or indirectly, in transmission of “spam”, chain letters,
            junk mail or any other type of unsolicited communication, and (5)
            not defame, harass, abuse, or disrupt other users of the Website
          </p>
          <p>
            <strong>
              <u>License:</u>
            </strong>
            <br />
            By using this Website, you are granted a limited, non-exclusive,
            non-transferable right to use the content and materials on the
            Website in connection with your normal, noncommercial, use of the
            Website. You may not copy, reproduce, transmit, distribute, or
            create derivative works of such content or information without
            express written authorization from Shokhi Box or the applicable
            third party (if third party content is at issue).
          </p>
          <p>
            <strong>
              <u>Posting:</u>
            </strong>
            <br />
            By posting, storing, or transmitting any content on the Website, you
            hereby grant Shokhi Box a perpetual, worldwide, non-exclusive,
            royalty-free, assignable, right and license to use, copy, display,
            perform, create derivative works from, distribute, have distributed,
            transmit and assign such content in any form, in all media now known
            or hereinafter created, anywhere in the world. Shokhi Box does not
            have the ability to control the nature of the user-generated content
            offered through the Website. You are solely responsible for your
            interactions with other users of the Website and any content you
            post. Shokhi Box is not liable for any damage or harm resulting from
            any posts by or interactions between users. Shokhi Box reserves the
            right, but has no obligation, to monitor interactions between and
            among users of the Website and to remove any content Shokhi Box
            deems objectionable
          </p>
          <h4>III. DISCLAIMER OF WARRANTIES</h4>
          <p>
            YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE
            WEBSITE AND PRODUCTS ARE OFFERED ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. Shokhi Box EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF THE PRODUCTS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR
            ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS.
            (“PRODUCTS” INCLUDE TRIAL PRODUCTS.)
          </p>
          <p>
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Shokhi Box MAKES
            NO WARRANTY:
          </p>
          <ul>
            <li>
              THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE,
              RELIABLE, COMPLETE, OR TIMELY.
            </li>
            <li>
              THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS
              ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
            </li>
            <li>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
              HEREIN.
            </li>
            <li>
              AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
              PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.
            </li>
            <li>
              REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.
            </li>
          </ul>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES,
            SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <h4>IV LIMITATION OF LIABILITY</h4>
          <p>
            Shokhi Box TAKES NO LIABILITY OR EXCLUSIVE REMEDY, IN LAW, IN
            EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND
            PRODUCTS AND/OR FOR ANY BREACH OF THIS AGREEMENT.
          </p>
          <p>
            Shokhi Box WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT
            OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM
            (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR PRODUCTS;
            (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY
            PRODUCTS PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH
            THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
          <h4>V. INDEMNIFICATION</h4>
          <p>
            You will release, indemnify, defend and hold harmless Shokhi Box,
            and any of its contractors, agents, employees, officers, directors,
            shareholders, affiliates and assigns from all liabilities, claims,
            damages, costs and expenses, including reasonable attorneys’ fees
            and expenses, of third parties relating to or arising out of (1)
            this Agreement or the breach of your warranties, representations and
            obligations under this Agreement; (2) the Website content or your
            use of the Website content; (3) the Products or your use of the
            Products (including Trial Products); (4) any intellectual property
            or other proprietary right of any person or entity; (5) your
            violation of any provision of this Agreement; or (6) any information
            or data you supplied to Shokhi Box. When Shokhi Box is threatened
            with suit or sued by a third party Shokhi Box may seek written
            assurances from you concerning your promise to indemnify Shokhi Box;
            your failure to provide such assurances may be considered by Shokhi
            Box to be a material breach of this Agreement. Shokhi Box will have
            the right to participate in any defense by you of a third-party
            claim related to your use of any of the Website content or Products,
            with counsel of shokhibox’s choice at its expense. Shokhi Box will
            reasonably cooperate in any defense by you of a third-party claim at
            your request and expense. You will have sole responsibility to
            defend Shokhi Box against any claim, but you must receive Shokhi Box
            prior written consent regarding any related settlement. The terms of
            this provision will survive any termination or cancellation of this
            Agreement or your use of the Website or Products.
          </p>
          <h4>VI. PRIVACY</h4>
          <p>
            Shokhi Box believes strongly in protecting user privacy and
            providing you with notice of shokhibox’s use of data. Please refer
            to Shokhi Box privacy policy, incorporated by reference herein, that
            is posted on the Website.
          </p>
          <h4>VII. AGREEMENT TO BE BOUND</h4>
          <p>
            By using this Website or ordering Products, you acknowledge that you
            have read and agree to be bound by this Agreement and all terms and
            conditions on this Website.
          </p>
          <h4>VIII. GENERAL</h4>
          <p>
            <strong>
              <u>Majeure:</u>
              <br />
              Shokhi Box will not be deemed in default hereunder or held
              responsible for any cessation, interruption or delay in the
              performance of its obligations hereunder due to earthquake, flood,
              fire, storm, natural disaster, act of God, war, terrorism, armed
              conflict, labor strike, lockout, or boycott.
            </strong>
          </p>
          <p>
            <strong>
              <u>Cessation of Operation:</u>
            </strong>
            <br />
            Shokhi Box may at any time, in its sole discretion and without
            advance notice to you, cease operation of the Website and
            distribution of the Products.
          </p>
          <p>
            <strong>
              <u>Entire Agreement:</u>
            </strong>
            <br />
            This Agreement comprises the entire agreement between you and Shokhi
            Box and supersedes any prior agreements pertaining to the subject
            matter contained herein.
          </p>
          <p>
            <strong>
              <u>Effect of Waiver:</u>
            </strong>
            <br />
            The failure of Shokhi Box to exercise or enforce any right or
            provision of this Agreement will not constitute a waiver of such
            right or provision. If any provision of this Agreement is found by a
            court of competent jurisdiction to be invalid, the parties
            nevertheless agree that the court should endeavor to give effect to
            the parties’ intentions as reflected in the provision, and the other
            provisions of this Agreement remain in full force and effect.
          </p>
          <p>
            <strong>
              <u>Governing Law &amp; Jurisdiction:</u>
            </strong>
            <br />
            This Website originates from the city of Bengaluru, Karnataka. This
            Agreement will be governed by the laws of the State of Karnataka
            without regard to its conflict of law principles to the contrary.
            You will not commence or prosecute any suit, proceeding or claim to
            enforce the provisions of this Agreement, to recover damages for
            breach of or default of this Agreement, or otherwise arising under
            or by reason of this Agreement, other than in courts located in
            State of Karnataka. By using this Website or ordering Products, you
            consent to the jurisdiction and venue of such courts in connection
            with any action, suit, proceeding or claim arising under or by
            reason of this Agreement. You hereby waive any right to trial by
            jury arising out of this Agreement and any related documents.
          </p>
          <p>
            <strong>
              <u>Statute of Limitation:</u>
            </strong>
            <br />
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to use of the
            Website or Products or this Agreement must be filed within one (1)
            year after such claim or cause of action arose or be forever barred.
          </p>
          <p>
            <strong>
              <u>Waiver of Class Action Rights</u>
            </strong>
            <br />
            BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY
            RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF
            A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT
            OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED
            INDIVIDUALLY.
          </p>
          <p>
            <strong>
              <u>Termination:</u>
            </strong>
            <br />
            Shokhi Box reserves the right to terminate your access to the
            Website if it reasonably believes, in its sole discretion, that you
            have breached any of the terms and conditions of this Agreement.
            Following termination, you will not be permitted to use the Website
            and Shokhi Box may, in its sole discretion and without advance
            notice to you, cancel any outstanding orders for Products. If your
            access to the Website is terminated, Shokhi Box reserves the right
            to exercise whatever means it deems necessary to prevent
            unauthorized access of the Website. This Agreement will survive
            indefinitely unless and until Shokhi Box chooses, in its sole
            discretion and without advance to you, to terminate it.
          </p>
          <p>
            <strong>
              <u>Assignment:</u>
            </strong>
            <br />
            You may not assign your rights and obligations under this Agreement
            to anyone. Shokhi Box may assign its rights and obligations under
            this Agreement in its sole discretion and without advance notice to
            you.
          </p>
          <p>
            <strong>
              BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU
              AGREETO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
              AGREEMENT.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}
