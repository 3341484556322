import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

export default function Category() {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [categoryInput, setCategory] = useState({
     slug:'',
     name:'',
     description:'',

     metaKeywords:'',
     metaTitle:'',
     metaDescription:'',
     errorList:[],
  });

 const handleInput = (e) =>{
    e.persist();
    setCategory({...categoryInput,[e.target.name]:e.target.value});
 }

 const submitCategory = (e) =>{
   e.preventDefault();

   axios.post('/api/add-category',categoryInput,{
    headers: { 'Allowed-Source': allowedSource }
})
   .then(response=>{
     const res = response.data;

    if(res.status ===200){
        
        document.getElementById('theForm').reset();
       swal("Success",res.message,"success");
     
 
    }
    else if(res.status ===400){
        setCategory({...categoryInput,errorList:res.message});
    }


   });


 }


  return (
    //main div
    <div className='container-fluid px-4'> 
     <h1>Add category</h1>

<form onSubmit={submitCategory} id='theForm'>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" >
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button>
  </li>
  <li className="nav-item">
    <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">SEO tags</button>
  </li>
  
</ul>

<div className="tab-content" id="myTabContent">

  <div className="tab-pane card-body border fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    

 

  <div className="form-group mb-3">
    <label >Slug</label>
    <input onChange={handleInput} value={categoryInput.slug} class="form-control"  type="text" id="slug" name='slug' />
  </div>

  <div className="form-group mb-3">
    <label>Name</label>
    <input onChange={handleInput} value={categoryInput.name} class="form-control"  type="text"  id="name" name='name'  />
  </div>

  <div className="form-group mb-3">
    <label>Description</label>
    <input onChange={handleInput} value={categoryInput.description} class="form-control"  type="text" id="description"name='description' />
  </div>
  

  </div>
  

  <div className="tab-pane card-body border fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
    
  <div className="form-group mb-3">
    <label>Meta-title</label>
    <input onChange={handleInput} value={categoryInput.metaTitle} class="form-control"  type="text" id="meta-title" name='metaTitle' />
  </div>

  <div className="form-group mb-3">
    <label>Meta keywords</label>
    <textarea onChange={handleInput} value={categoryInput.metaKeywords} class="form-control"  type="text"  id="meta-keywords"name='metaKeywords' />
  </div>

  <div className="form-group mb-3">
    <label>Meta description</label>
    <textarea onChange={handleInput} value={categoryInput.metaDescription} class="form-control"  type="text"  id="meta-description"name='metaDescription' />
  </div>





  </div>

</div>

<button type='submit' className='btn btn-primary'>Submit</button>

</form>

    </div> //main div
  )
}
