import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { TextInput } from "../../ui";

const initialValues = {
  otp: "",
};

const validationSchema = Yup.object({
  otp: Yup.number().required("Required!"),
});

const Otp = ({ setloggedIn, setIsVerify }) => {
  const [expiresAt, setExpiresAt] = useState(() => {
    const storedTime = new Date(localStorage.getItem("expires_at"));
    // Adjust for the 6-hour difference
    storedTime.setHours(storedTime.getHours() + 6);
    return storedTime.getTime();
  });
  const history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const onSubmit = (values, { resetForm }) => {
    const data = {
      ...values,
      redirect_url_exists:
        sessionStorage.getItem("redirect_url_exists") === "true" ? true : false,
    };
    resetForm();
    const authToken = localStorage.getItem("auth_token");
    axios
      .get("/sanctum/csrf-cookie", {
        headers: {
          "Allowed-Source": allowedSource,
          Authorization: "Bearer " + authToken,
        },
      })
      .then((response) => {
        axios
          .post("/api/verify-otp", data, {
            headers: { "Allowed-Source": allowedSource },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              localStorage.setItem("isVerified", res.data.isVerified);
              // swal("Processing", "Please Wait", "success");
              // swal("Success", res.data.message, "success");
              // history.push("/home");
              setloggedIn(true);
              setIsVerify(res.data.isVerified);
              if (res.data.redirect_url_exists) {
                sessionStorage.removeItem("redirect_url_exists");
                history.push("/checkout");
              } else {
                history.push("/");
              }
            } else {
            }
          });
      });
  };

  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    return Math.max(0, Math.floor((expiresAt - currentTime) / 1000));
  };

  const [seconds, setSeconds] = useState(calculateTimeRemaining());
  const [ResendOtpBtn, setResendOtpBtn] = useState(false);
  const intervalRef = useRef(null); // Ref to hold interval reference

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      clearInterval(intervalRef.current);
      setResendOtpBtn(true);
    }
  }, [seconds]);

  // useEffect(() => {
  //   intervalRef.current = setInterval(() => {
  //     setSeconds((prevSeconds) => prevSeconds - 1);
  //   }, 1000);

  //   return () => clearInterval(intervalRef.current); // Cleanup interval using ref
  // }, []);

  // useEffect(() => {
  //   if (seconds === 0) {
  //     clearInterval(intervalRef.current); // Stop the interval when seconds reach 0
  //     setResendOtpBtn(true);
  //   }
  // }, [seconds]);

  // Formatting the seconds into minutes and seconds

  const resendOtp = () => {
    const values = {};
    axios
      .get("/sanctum/csrf-cookie", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((response) => {
        axios
          .post("/api/resend-otp", values, {
            headers: { "Allowed-Source": allowedSource },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              localStorage.setItem("expires_at", res.data.expires_at);
              const newExpiresAt = new Date(res.data.expires_at);
              // Adjust for the 6-hour difference
              newExpiresAt.setHours(newExpiresAt.getHours() + 6);
              setExpiresAt(newExpiresAt.getTime());
              setSeconds(calculateTimeRemaining());
              setResendOtpBtn(false);
              window.location.reload();
            } else {
            }
          });
      });

    // setSeconds(ini); // Reset seconds to initial value
    // setResendOtpBtn(false);
    // clearInterval(intervalRef.current); // Clear the previous interval
    // countdownRunning.current = false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="Register container my-5 flex flex-col justify-center items-center">
      <h2 className="text-2xl font-bold text-black">OTP</h2>
      <div className="border rounded-xl shadow-xl px-5 py-4 w-fit">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="space-y-5">
            <TextInput name="otp" inputType="text" label="OTP" />
            <div>
              <div className="text-black">
                {minutes}:{remainingSeconds < 10 ? "0" : ""}
                {remainingSeconds}
              </div>

              {ResendOtpBtn ? (
                <button
                  onClick={resendOtp}
                  className="text-black text-sm underline"
                  type="button"
                >
                  Resend OTP
                </button>
              ) : (
                ""
              )}
            </div>
            <button
              className="border bg-blue-500 py-2 px-4 rounded-md"
              type="submit"
            >
              Submit
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Otp;
