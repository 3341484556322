import React, { useEffect, useState } from 'react'
 
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

export default function AddBlog() {
    let history = useHistory();
    
    const [blogInput, setBlogInput] = useState({
        heading:'',
        pic:'',
        blog:'',
        errorList:[],
     });
   
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;


 const handleInput = (e) =>{
    setBlogInput({...blogInput,[e.target.name]:e.target.value});
 }

 const submit = (e) =>{
    e.preventDefault();
   const data = {
    heading:blogInput.heading,
    pic:blogInput.pic,
    blog:blogInput.blog,
   }
    axios.post('/api/add-blog',data,{
      headers: { 'Allowed-Source': allowedSource }
  })
    .then(response=>{
      const res = response.data;
 
     if(res.status ===200){
         
         document.getElementById('theForm').reset();
        swal("Success",res.message,"success");
      
     }
     else{
        swal("Warning","Fill up all the fields","warning")
     }
 
 
    });
 
 
  }


 
  return (
    <div>
        <h4>Add Blog</h4>
        <form onSubmit={submit} id='theForm'>
   
        <div className="form-group mb-3">
    <label >Heading</label>
    <input onChange={handleInput} value={blogInput.heading} class="form-control"  type="text" id="heading" name='heading' />
  </div>

        <div className="form-group mb-3">
    <label >Pic</label>
    <input onChange={handleInput} value={blogInput.pic} class="form-control"  type="text" id="pic" name='pic' />
  </div>

  <div className="form-group mb-3">
    <label >Blog</label>
    <textarea wrap="hard"  onChange={handleInput} value={blogInput.blog}  class="form-control" id="blog" name="blog" rows="15" cols="10">
    </textarea>
  </div>

  

<button type='submit' className='btn btn-primary'>Add</button>

</form>
    </div>
  )
  

}
