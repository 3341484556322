import React, { useEffect, useState } from 'react'
 
import { useHistory } from "react-router-dom";
import axios from 'axios';
 
import { Link } from 'react-router-dom';
 

export default function ViewBlog() {


    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
   
    
    
    let history = useHistory();
    
    const [loading, setloading] = useState(true);

    const [blogs, setBlogs] = useState([]);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
     
     
        
        axios.get('/api/view-blogs',{
          headers: { 'Allowed-Source': allowedSource }
      })
      
      .then(res=>{
          
          if(res.data.status===200){
              
            setBlogs(res.data.blogs);
          }
          else{
              setErrors(res.data.messages);
              history.push('/');
          }
          setloading(false);
  
      })
      .catch(error=>{
          ////////console.log(error);
          history.push('/');
     });  
  
  
  
      }, [history]);

      if(loading){
        return(
            <h4>Loading...</h4>
        )
    }
    else{
  return (
    <div className='blog-container mx-auto'>
             <div className='blog-inner-container mx-auto d-flex flex-column justify-content-center align-items-center'>
                    { blogs.length>0 &&
                      blogs?.map((blog)=>{
                        return(
                            <div className='blog d-flex flex-column border p-5 w-100 rounded gap-5'>
                                  <h3>{blog.Heading}</h3>
                                  <div className='blog-img-container'>
                                     <img className='blog-img' src={`${blog.pic}`}/> 
                                  </div>
                                  <div className='blog-container'>
                                    <pre className='blog-blog text-wrap'> <p>{blog.blog}</p></pre>
                                 </div>

                            
                                 <Link to={`/admin/edit-blog/${blog.id}`} className='btn btn-warning'>Edit</Link>
                            </div>
                        )
                      })

                    }
             </div>
        
    </div>
  )
                }
}
