import CryptoJS from 'crypto-js';

const encryptionKey = "493";

export const encryptData = (data) => {
  const dataString = JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(dataString, encryptionKey).toString();
  return encryptedData;
};

// Decryption function
export const decryptData = (encryptedData) => {
  if (encryptedData === null) return null;
  
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    const decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);

    // Check if decryptedDataString is not empty before parsing
    if (decryptedDataString.trim() === "") {
      console.error('Decrypted data is empty.');
      return null;
    }

    return JSON.parse(decryptedDataString);
  } catch (error) {
    console.error('Error parsing decrypted data:', error);
    return null;
  }
};