import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import box from "../../images/Create Shokhi Box.png";
import cartlogo from "../../images/cart.png";
import close from "../../images/cross.png";
import deliveryVan from "../../images/delivery-van.png";
import logo from "../../images/logo.png";
import menu from "../../images/menu.png";
import searchIcon from "../../images/search.png";
import trashBin from "../../images/trash-bin.png";
import { trackButtonClick } from "../../utils/gtm";
import Categories from "./CatAndSubCats";

export default function NavBar(props) {
  useEffect(() => {
    const preloadImages = () => {
      const images = [
        logo,

        // Add more image paths as needed
      ];

      images.forEach((image) => {
        new Image().src = image;
      });
    };

    preloadImages();

    // Cleanup function (optional)
    return () => {
      // Perform any cleanup here, if needed
    };
  }, []);

  const [showSubCats, setShowSubCats] = useState(false);

  const [Show, setShow] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      props.setloggedIn(true);
    } else props.setloggedIn(false);

    const handleOutsideClick = (event) => {
      // Check if the clicked element is outside the nav
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        !event.target.closest(".sidebar-container") &&
        !event.target.closest(".sidebar-btn")
      ) {
        setShow(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [navRef.current]);

  const toggleSidebar = () => {
    setShow(!Show);
  };

  const deleteBox = (box_id) => {
    let temp_boxes = boxes;
    temp_boxes = temp_boxes.filter((the_box) => the_box.box_id !== box_id);
    setBoxes(temp_boxes);

    let temp_cart = cart;

    temp_cart = temp_cart.filter((item) => item.box_num !== box_id);

    setCart(temp_cart);
    setCartD(temp_cart);

    props.setCartLength(temp_cart.length);
    localStorage.setItem("cartLength", temp_cart.length);
    props.setCartLength(temp_cart.length);

    //////////////console.log("after delete box",temp_boxes)
  };

  const { cart, setCart, boxes, setBoxes } = useContext(MyCartContext);

  function totalPrice() {
    let total = 0;
    cart.forEach((element) => {
      total += element.price * element.quantity;
    });
    return total;
  }

  function totalItems() {
    let total = 0;
    cart.forEach((element) => {
      total += element.quantity;
    });
    return total;
  }

  //console.log("Navbar rendered")

  const handleChange = (item, id, value) => {
    let temp_cart = cart;

    temp_cart.forEach(async (item) => {
      if (item.id === id && item.box_num === 0) {
        let data = {
          id: item.id,
        };
        let product = {};

        // await axios.post('/api/get-product-by-id',data, {
        //       headers: { 'Allowed-Source': allowedSource }
        //   })cart
        //    .then(res=>{
        //               if(res.data.status===200){
        //                 product = res.data.product;

        //               }

        //           })

        //dfsd
        if (value == 1) {
          if (limitCrossed(item.id, item.stock)) {
            swal("No more available", "Insufficient Stock", "warning");
          } else {
            if (item.box_num === 0) {
              item.quantity += value;

              setCart([...temp_cart]);

              setCartD(temp_cart);
            }
          }
        } else {
          if (item.quantity != 1) {
            item.quantity += value;

            setCart([...temp_cart]);

            setCartD(temp_cart);
          }
        }
      }
    });
  };
  function limitCrossed(id, stock) {
    if (cart === null) return false;

    let total = 0;
    cart.forEach((element) => {
      if (element.id === id) {
        total += element.quantity;
      }
    });

    if (total > stock) return true;

    return false;
  }

  const deleteItem = (id) => {
    let temp_cart = cart;

    temp_cart = temp_cart.filter(
      (item) => item.id !== id || item.box_num !== 0
    );

    setCartD(temp_cart);
    setCart([...temp_cart]);

    localStorage.setItem("cartLength", temp_cart.length);
    props.setCartLength(temp_cart.length);
  };
  const [search, setSearch] = useState("");

  return (
    <div className="NavBar header-bg">
      <div className="d-flex justify-content-between text-black">
        <Link className="dec-none mx-2 text-black" to="/">
          <img className="logo" src={logo} alt="none" />
        </Link>

        <div className="d-flex align-items-center">
          <div className="blog-lg">
            <Link
              className="btn btn-info fw-bold dec-none mx-2 text-black "
              to="/blogs"
            >
              Tips
            </Link>
          </div>

          <Link to="/shokhibox" className="">
            <img className="" src={box}></img>
          </Link>
          <div className="d-none d-md-block">
            {!props.loggedIn && (
              <div>
                <Link className="btn  mx-2 dec-none  fw-bold login" to="/login">
                  Login
                </Link>
                <Link
                  className="btn  mx-2 dec-none  fw-bold register"
                  to="/register"
                >
                  Register
                </Link>
              </div>
            )}
            {props.loggedIn && (
              <button
                onClick={(e) => props.handleLogout(e)}
                type="button"
                className="m-1 border px-2 py-1 rounded-md bg-red-600 text-white hover:bg-red-500 transition-all duration-300"
              >
                Logout
              </button>
            )}

            {props.isAdmin && (
              <>
                <Link
                  className="btn  mx-2 dec-none text-black login"
                  to="/admin"
                >
                  Admin
                </Link>
              </>
            )}
          </div>
          <Link
            onClick={() => trackButtonClick("Cart View")}
            className="dec-none  mx-2 text-black "
            to="/cart"
            id="cart view"
          >
            <div className="d-flex">
              <img
                style={{ width: "30px" }}
                className="cart"
                src={cartlogo}
                alt="none"
              />

              <span className="cartAmount">{totalItems()}</span>
            </div>
          </Link>
        </div>
      </div>
      {props.showFloatingCart === true && (
        <button className=" rounded floating-cart border-2 border-black ">
          <div
            className="d-flex"
            onMouseEnter={() => {
              props.setShowCart(true);
              trackButtonClick("Cart View");
            }}
            onMouseLeave={() => props.setShowCart(false)}
            id="cart view"
          >
            <img className="cart" src={cartlogo} alt="none" />
          </div>
        </button>
      )}
      <div
        onMouseEnter={() => props.setShowCart(true)}
        onMouseLeave={() => props.setShowCart(false)}
        className={`flex flex-col homecart-container h-screen  ${
          props.showCart ? "" : " d-none "
        }`}
      >
        <div className="">
          <button
            onClick={() => props.setShowCart(!props.showCart)}
            className="sidebar-icon-container "
          >
            <img
              className="sidebar-icon"
              style={{ width: "40px" }}
              src={close}
              alt="Menu"
            ></img>
          </button>

          <div className="text-black ">
            <span className="d-block fs-5">Your Cart</span>
            <span className="d-block fs-5">{props.cartLength} Items</span>
          </div>
          <div className="checkout-cart-floating h-[40vh] lg:h-[58vh] text-black m-1 text-start">
            {cart.length > 0 &&
              cart.map((item) => {
                if (item.box_num === 0)
                  return (
                    <div className="border-top border-top-3">
                      <div className="checkout-cart-item  d-flex align-items-center justify-content-between">
                        <img
                          className=" "
                          style={{ width: "40px" }}
                          src={item.pic}
                          alt="loading"
                        />

                        <span className="ms-1 floating-item  item-title">
                          {item.name}
                        </span>

                        <div className="price fw-bold mx-1">
                          <div className="d-flex mx-auto">
                            <button
                              onClick={(e) => handleChange(item, item.id, -1)}
                              className="bg-info px-2 border rounded"
                            >
                              -
                            </button>
                            <button
                              onClick={(e) => handleChange(item, item.id, 1)}
                              className=" bg-warning px-2 mx-2 border rounded"
                            >
                              +
                            </button>
                          </div>
                          <div>
                            {item.quantity} x {item.price} Tk
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center"></div>
                      <div className="d-flex justify-content-center">
                        <button
                          onClick={(e) => deleteItem(item.id)}
                          className=" border-0"
                        >
                          <img
                            style={{ width: "30px" }}
                            src={trashBin}
                            alt="none"
                          />
                        </button>
                      </div>
                    </div>
                  );
              })}

            <div className="checkout-boxes text-black mb-2">
              {boxes.length > 0 &&
                boxes.map((box, index) => {
                  return (
                    <div className="border-top border-top-3  mt-1 rounded">
                      <h6>Shokhi Box {index + 1}</h6>
                      {box.items.map((item) => {
                        return (
                          <div className="checkout-cart-item  d-flex align-items-center justify-content-between">
                            <img
                              className=" "
                              style={{ width: "40px" }}
                              src={item.pic}
                              alt="loading"
                            />

                            <span className="mx-1 item-name">{item.name}</span>

                            <div className="price fw-bold mx-1">
                              {item.quantity} x {item.price} Tk
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center">
                        <button
                          onClick={(e) => deleteBox(box.box_id)}
                          style={{ borderRadius: "50%" }}
                          className="border-0"
                        >
                          <img
                            style={{ width: "30px" }}
                            src={trashBin}
                            alt="none"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <h5 className="text-white">Total {totalPrice()} Tk</h5>
        <div className="d-flex justify-content-around mb-2">
          <Link
            className="btn btn-primary"
            to={`/cart`}
            id="cart view"
            onClick={() => {
              props.setShowCart(false);
              trackButtonClick("Cart View");
            }}
          >
            Go To Cart
          </Link>
          <Link
            className="btn btn-warning"
            to={`/checkout`}
            onClick={() => props.setShowCart(false)}
          >
            Checkout
          </Link>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg d-none d-lg-block">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="bg-white navbar-collapse rounded-md"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" to="/">
                  Home
                </Link>
              </li>

              {Categories &&
                Categories.map((cat) => {
                  return (
                    <li className="nav-item dropdown text-black">
                      <a
                        className="nav-link"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {cat.name}
                      </a>
                      <ul className="dropdown-menu topz text-start">
                        <li className="full-width b-bottom mx-3">
                          {" "}
                          <Link
                            className="dec-none   my-1  full-width "
                            to={`/products/${cat.name}`}
                          >
                            All
                          </Link>
                        </li>
                        {cat.subcats &&
                          cat.subcats.map((subcat) => {
                            return (
                              <li className=" full-width b-bottom mx-3">
                                {" "}
                                <Link
                                  className=" dec-none my-1   full-width"
                                  to={`/products/${cat.name}/${subcat.name}`}
                                >
                                  {subcat.name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
              <Link
                onClick={() => setShow(false)}
                to="/TrackOrder"
                className="btn"
              >
                <div className="flex justify-center items-center w-max ml-2 border-2 px-2 rounded-md">
                  <img className="box-icon" src={deliveryVan}></img>
                  <p className="fw-bold text-black">Track order</p>
                </div>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
      <nav className="sidebar-btn d-flex d-block d-lg-none align-items-center justify-content-between">
        <button
          onClick={() => toggleSidebar()}
          className="sidebar-icon-container m-3"
        >
          <img className="sidebar-icon" src={menu} alt="Menu"></img>
        </button>

        <div>
          <div className="me-2 searchContainer flex justify-center items-center">
            <input
              type="text"
              placeholder="Sanitary Pads,Tampons,Mask,Chocolates"
              className="mx-auto  border-0 bg-transparent focus:ring-0 text-black"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Link className="" to={`/searchItem?target=${search}`}>
              <img
                style={{ width: "23px", marginRight: "4px" }}
                src={searchIcon}
                className="min-w-5"
              ></img>
            </Link>
          </div>
        </div>
      </nav>
      <div
        ref={navRef}
        className={`sidebar d-flex flex-column  ${Show ? "sidebar-show" : ""}`}
      >
        <div>
          <button
            onClick={() => toggleSidebar()}
            className="sidebar-icon-container m-1"
          >
            <img style={{ width: "40px" }} src={close} alt="Close"></img>
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center space-x-2">
          <Link
            onClick={() => setShow(false)}
            className="dec-none bg-white text-xl text-black rounded-md px-2 h-12 flex justify-center items-center"
            to="/blogs"
          >
            Tips
          </Link>
          <Link
            onClick={() => setShow(false)}
            to="/TrackOrder"
            className="dec-none bg-white flex justify-center items-center px-3 py-2 rounded-md h-12"
          >
            <img className="box-icon" src={deliveryVan}></img>
            <span className="fw-bold text-black">Track Order</span>
          </Link>
        </div>
        {Categories.length && (
          <nav className="  ms-3 sidebar-container navbar navbar-expand-lg navbar-light bg-light text-start">
            <ul className="navbar-nav">
              <Link
                onClick={() => setShow(false)}
                className="nav-link text-white "
                to={"/"}
              >
                Home
              </Link>
              {Categories?.map((cat) => {
                return (
                  <li className="nav-item dropdown ">
                    <a
                      className="nav-link text-white "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {cat.name}

                      <span className="ms-2 text-black">+</span>
                    </a>

                    <ul
                      className="dropdown-menu mx-4"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li onClick={() => setShow(false)}>
                        {" "}
                        <Link
                          className="dec-none  text-white dropdown-item"
                          to={`/products/${cat.name}`}
                        >
                          All
                        </Link>
                      </li>
                      {cat.subcats.map((subcat) => {
                        return (
                          <li onClick={() => setShow(false)}>
                            {" "}
                            <Link
                              className="dec-none  text-white dropdown-item"
                              to={`/products/${cat.name}/${subcat.name}`}
                            >
                              {subcat.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}

              {!props.loggedIn && (
                <div className="d-flex justify-content-between w-75">
                  <Link
                    className="btn btn-warning  mx-2 dec-none  fw-bold login"
                    to="/login"
                  >
                    Login
                  </Link>
                  <Link
                    className="btn btn-secondary  mx-2 dec-none  fw-bold register"
                    to="/register"
                  >
                    Register
                  </Link>
                </div>
              )}
              {props.loggedIn && (
                <button
                  onClick={(e) => props.handleLogout(e)}
                  type="button"
                  className="m-1 btn btn-danger"
                >
                  Logout
                </button>
              )}

              {props.isAdmin && (
                <>
                  <Link
                    className="btn  mx-2 dec-none text-black login"
                    to="/admin"
                  >
                    Admin
                  </Link>
                </>
              )}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
