import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import Disposable from "../../images/Disposable.png";
import WaterBag from "../../images/Hot Water Bag.png";
import Pads from "../../images/Pads.png";
import Panties from "../../images/Panties.png";
import Razor from "../../images/Razor.png";
import Tampons from "../../images/Tampons.png";
import TeaBag from "../../images/Tea Bag.png";
import Chocolate from "../../images/Treats.png";
import Vwash from "../../images/V Wash.png";
import WaxStrips from "../../images/Wax Strips.png";
import mask from "../../images/mask.png";
import trashBin from "../../images/trash-bin.png";

export default function ShokhiBox(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    num_of_box,
    setNumberOfBox,
    boxes,
    setBoxes,
    setCartLength,
    cart,
    setCart,
  } = useContext(MyCartContext);

  function available(subcategory) {
    if (props.shokhiBox.length === 0) return false;

    let found = 0;

    props.shokhiBox?.forEach((item) => {
      if (item.name.toLowerCase() === subcategory.toLowerCase()) {
        found = 1;
      }
    });

    if (found) return true;

    return false;
  }

  function Total_Price() {
    let total = 0;

    let box = props.shokhiBox;
    if (box.length == 0) {
      return 0;
    }

    box.forEach((element) => {
      total += element.item.quantity * element.item.price;
    });

    return total;
  }

  const deleteItem = (element) => {
    props.setPrice(props.totalPrice - element.item.price);
    let temp_box = props.shokhiBox.filter((each) => {
      if (each.item.id === element.item.id) return false;
      return true;
    });

    props.setShokhiBox(temp_box);
  };

  const addToCart = () => {
    let temp_cart = cart;
    let box = props.shokhiBox;

    if (box !== null) {
      if (box.length < 1) {
        swal("Shokhi box is empty", "Warning", "warning");
        return;
      }

      if (temp_cart === null) temp_cart = [];

      let items = [];
      box.forEach((element) => {
        //  if(box.length>9){
        //     element.item.price = element.item.price*90/100;
        //  }
        temp_cart.push(element.item);
        items.push(element.item);
      });
      let temp_boxes = boxes;
      let newBox = {
        box_id: boxes.length + 1,
        items: items,
      };
      console.log(newBox);
      temp_boxes.push(newBox);

      setCart(temp_cart);
      props.setCartLength(temp_cart.length);
      setBoxes(temp_boxes);

      setCartD(temp_cart);

      // Boxes.push(box);

      props.setShokhiBox([]);
      setNumberOfBox((prev) => prev + 1);
      props.setPrice(0);
      swal("Box added to cart", "success", "success");

      localStorage.setItem("cartLength", temp_cart.length);
      setCartLength(temp_cart.length);
    } else {
      swal("Shokhi box is empty", "Warning", "warning");
    }
  };

  const types = [
    { name: "Pads", pic: Pads },
    { name: "Tampons", pic: Tampons },
    { name: "V Wash", pic: Vwash },
    { name: "Razor", pic: Razor },
    { name: "Hair Removal", pic: WaxStrips },
    { name: "Disposable Bag", pic: Disposable },
    { name: "Hot Water Bag", pic: WaterBag },
    { name: "Tea", pic: TeaBag },
    { name: "Chocolates", pic: Chocolate },
    { name: "Panties", pic: Panties },
    { name: "Face-mask", pic: mask },
    { name: "Eye-mask", pic: mask },
    { name: "Nose-mask", pic: mask },
    { name: "Lip-mask", pic: mask },
  ];

  const changeQuantity = (e, product, action) => {
    let box = props.shokhiBox;
    console.log(product.id);

    box.forEach((element) => {
      if (element.item.id == product.id) {
        if (action == 1 && element.item.quantity < element.item.stock) {
          element.item.quantity += action;
        } else {
          if (element.item.quantity != 1) {
            element.item.quantity -= action;
          }
        }
      }
    });
    console.log(box);
    props.setShokhiBox([...box]);
  };

  return (
    <div className="Container m-1">
      <Helmet>
        <title>Shokhibox</title>
        <meta name="description" contents="Shokhibox" />

        <meta
          name="keywords"
          contents="Shokhibox,Women hygiene products,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
        />
      </Helmet>

      <div className="shokhiBox-container mx-auto text-black">
        <div className="shokhiBox">
          <h2 className="m-3">Create Your Own Shokhi Box</h2>

          {types.map((type) => {
            return (
              <>
                {available(type.name) ? (
                  <div className="Box">
                    {props.shokhiBox.map((element) => {
                      if (
                        element.name.toLowerCase() === type.name.toLowerCase()
                      ) {
                        return (
                          <div className="item-row d-flex align-items-center justify-content-between">
                            <div className="shokhi-pic-container">
                              <img
                                loading="lazy"
                                className="shokhi-pic"
                                src={element.item.pic}
                                alt="Nai"
                              />
                            </div>
                            <div className="item-details d-flex justify-content-between align-items-center">
                              <div className="d-flex justify-content-between  align-items-center">
                                <span className="m-1 item-title">
                                  {element.item.name}
                                </span>
                              </div>

                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column align-items-center">
                                  <div>
                                    <p className="m-1">
                                      {element.item.price} Tk x{" "}
                                      {element.item.quantity}{" "}
                                    </p>
                                  </div>
                                  <div className="d-flex mx-auto">
                                    <button
                                      onClick={(e) =>
                                        changeQuantity(e, element.item, 1)
                                      }
                                      className=" bg-warning px-2 mx-2 border rounded "
                                    >
                                      +
                                    </button>
                                    <button
                                      onClick={(e) =>
                                        changeQuantity(e, element.item, -1)
                                      }
                                      className="  bg-info px-2 mx-2 border rounded  "
                                    >
                                      -
                                    </button>
                                  </div>
                                  <button
                                    onClick={() => deleteItem(element)}
                                    style={{
                                      backgroundColor: "none",
                                      borderRadius: "50%",
                                    }}
                                    className="border border-0"
                                  >
                                    <img
                                      style={{
                                        width: "30px",
                                        borderRadius: "50%",
                                      }}
                                      src={trashBin}
                                      alt="none"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div className="item-row d-flex align-items-center">
                    <div className="item-name">
                      <img
                        loading="lazy"
                        className="shokhi-pic"
                        src={type.pic}
                        alt="Nai"
                      />
                    </div>
                    <div className="item-details">{type.name}</div>
                    <div className="">
                      <Link
                        className="add mx-2 dec-none btn btn-warning"
                        to={`/shokhibox/${type.name}`}
                      >
                        +
                      </Link>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>

        {
          //   Total_added()>9 ?
          //  ( <h5 className='mt-3'>Total : {props.totalPrice*90/100}<del className='mx-1'>{props.totalPrice}</del></h5>)
          //  :
          <h5 className="mt-3">Total : {Total_Price()} Tk</h5>
        }

        <button onClick={() => addToCart()} className="m-3 btn btn-primary">
          Add the box to Cart
        </button>
      </div>
    </div>
  );
}
