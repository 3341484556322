import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

export default function ViewSubCat() {
    let history = useHistory();
    const [loading, setloading] = useState(true);

    const [subCategories, setSubCategories] = useState([]);
    const [errors, setErrors] = useState([]);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {
     
        axios.get('/api/get-subcats',{
          headers: { 'Allowed-Source': allowedSource }
      })
      .then(res=>{
            
            if(res.data.status===200){
                setSubCategories(res.data.subcategories);
                
            }
            else{
                setErrors(res.data.messages);
            }
            setloading(false);
    
        })
        .catch(error=>{
            ////////console.log(error);
       });  
  
    
        }, [history]);


  if(loading){
    return(
         <div>
            Loading categories..
         </div>
    )
   } 

else{
  return (
    <div>
         <table class="table">
  <thead>
    <tr>
      <th scope="col">Serial</th>
      <th scope="col">Name</th>
    
    </tr>
  </thead>
  <tbody>
   

        {
           subCategories.map((subcategory,index)=>{

            return(
                <tr>
                <th scope="row">{index+1}</th>
                <td>{subcategory.name}</td>
                <td><Link to={`/admin/update-subcat/${subcategory.id}`} className='btn btn-warning'>Update</Link></td>
              </tr>
            )

           }) 
        }

  </tbody>
</table>
    </div>
  )
}
}
