import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import prev from "../../images/AL.png"
import next from "../../images/AR.png"

export default function Draggable(props) {

    const tabsbox = useRef(null);
	const [dragging, setDragging] = useState(false);
  
	const startDragging = () => {
	  setDragging(true);
	};
  
	const stopDragging = () => {
	  setDragging(false);
	};
  
	const drag = (e) => {
      if(1){
		return;
	  }

        ////console.log("Dragging")
	  if (dragging) {
      
		tabsbox.current.scrollLeft -= e.movementX;
	  }
	};
    const handleMouseUp = () => {
        stopDragging();
		setDragging(false);
      };
      
  
	useEffect(() => {
       ////console.log("useEffect ran")
	  document.addEventListener('mouseup', handleMouseUp);
    
	  return () => {
       
		document.removeEventListener('mouseup', handleMouseUp);
	  };
	}, [dragging]);


	const scroll = (direction) =>{

     if(direction===1){
		if(window.innerWidth>600)
	    	tabsbox.current.scrollLeft -=380;
	    else
		    tabsbox.current.scrollLeft -=190;

	 }
	 else{
		if(window.innerWidth>600)
		tabsbox.current.scrollLeft +=380;
		else
		tabsbox.current.scrollLeft +=190;
	 }

	}
	
	

  return (
    <div className="draggableContainer">
		<div className='d-flex align-items-center justify-content-center'>
		<button onClick={()=>scroll(1)} className='home-control-btn border border-0 bg-none '><img src={prev} className='home-control'/></button>
		
		  <ul
			className="tabs-box"
			ref={tabsbox}
			onMouseMove={drag}
			onMouseDown={startDragging}
			
                  
		  >
			
		{ 

          props.TrendingProducts.map((product,index)=>{
			return(
<li className="">
			<div className='tredningProduct1 '>
                                                            <Link draggable="false" to={`/product/${product.id}`} className="dec-none product-link">
                                                               <div  key={index} className="m-2 item-card" >
															   
															   {product.discount.discount!==0 &&
                                                                      <span className='og-price'>{product.discount.discount}% OFF</span>
                                                                    }
																	{product.size.name!=="Not Available" &&
                                                                    <span className='size myborder-pink2 px-2 rounded '>{product.size.name}</span>
																     }

                                                               <img draggable="false" src={product.pic} className="item-pic border border-1 border-black rounded-2" alt="..."/>
                                                               <div className="item-body">
                                                                 <span className="item-title">{product.name}</span>
																 
																
																
                                                                  <div className=''>
																	 
                                                                    <h6 className="fw-bold"><span className='item-price'>৳{Math.ceil(props.calculatePrice(product))}</span> { product.discount.discount!==0 &&
                                                                      <del className=''>{product.price}Tk</del>
                                                                     }</h6>
																	
																	{
                                                                product.stock ?
                                                                (
																	<button onClick={(e)=>props.addToCart(product,e)} className='rounded p-1 myborder-pink bg-white mb-2 '>Add To Cart</button>
                                                                )
                                                                :
                                                                (
                                                                            <button className='btn bg-dark text-white'>Request Stock</button>
                                                                )
                                                            }

                                                                    
                                                                 </div>
                                                               
                                                               
                                                             
                                                                
                                                                 
                                                                       
                                                               
                                                               </div>
                                                                 
                                                             </div>  
                                                             </Link>                  
                                         </div>
			</li>
			)
		})
			
			}
			 
		  </ul>
		  <button onClick={()=>scroll(2)} className='home-control-btn border border-0 bg-none '><img src={next} className='home-control'/></button>
		  </div>
		</div>
  )
}
