import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import prev from "../../images/before.png";
import next from "../../images/next.png";

export default function RelatedDraggable(props) {
  const tabsbox = useRef(null);
  const [dragging, setDragging] = useState(false);

  const startDragging = () => {
    setDragging(true);
  };

  const stopDragging = () => {
    setDragging(false);
  };

  const drag = (e) => {
    if (1) {
      return;
    }

    ////console.log("Dragging")
    if (dragging) {
      tabsbox.current.scrollLeft -= e.movementX;
    }
  };
  const handleMouseUp = () => {
    stopDragging();
    setDragging(false);
  };

  useEffect(() => {
    ////console.log("useEffect ran")
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const scroll = (direction) => {
    if (direction === 1) {
      if (window.innerWidth > 600) tabsbox.current.scrollLeft -= 380;
      else tabsbox.current.scrollLeft -= 190;
    } else {
      if (window.innerWidth > 600) tabsbox.current.scrollLeft += 380;
      else tabsbox.current.scrollLeft += 190;
    }
  };

  function alreadyAdded(id) {
    let flag = 0;

    cart.forEach((item) => {
      if (item.id === id && item.box_num === 0) {
        flag = 1;
      }
    });

    if (flag) return true;

    return false;
  }

  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }
  const { cart, setCart, boxes, setBoxes } = useContext(MyCartContext);

  const addToCart = async (product, e) => {
    e.preventDefault();
    e.persist();

    let total = 0;
    let found = 0;
    cart.forEach((element) => {
      if (product.id === element.id) {
        total = total + 1;
        if (product.stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      let product_id = product.id;

      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: product.name,
        id: product_id,
        size: product.size.name,
        color: "",
        pic: product.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: 0,
      };

      cart.push(productInput);

      setCartD(cart);
      setCart(cart);
      props.setCartLength(cart.length);

      localStorage.setItem("cartLength", cart.length);
    }
  };

  return (
    <div className="draggableContainer">
      <div className="d-flex align-items-center justify-content-center">
        <button
          onClick={() => scroll(1)}
          className="home-control-btn border border-0 bg-none "
        >
          <img src={prev} className="home-control" />
        </button>

        <ul
          className="tabs-box"
          ref={tabsbox}
          onMouseMove={drag}
          onMouseDown={startDragging}
        >
          {props.TrendingProducts.map((product, index) => {
            return (
              <li className="">
                <div className="tredningProduct1 ">
                  <Link
                    draggable="false"
                    to={`/product/${product.id}`}
                    className="dec-none product-link"
                  >
                    <div key={index} className="m-2 item-card">
                      {product.discount.discount !== 0 && (
                        <span className="og-price">
                          {product.discount.discount}% OFF
                        </span>
                      )}

                      <img
                        draggable="false"
                        src={product.pic}
                        className="item-pic border border-1 border-black rounded-2"
                        alt="..."
                      />
                      <div className="item-body">
                        <span className="item-title">{product.name}</span>

                        <div className="">
                          <h6 className="fw-bold">
                            <span className="item-price">
                              ৳{Math.ceil(props.calculatePrice(product))}
                            </span>
                            {product.discount.discount !== 0 && (
                              <del className="">{product.price}Tk</del>
                            )}{" "}
                          </h6>

                          <button
                            onClick={(e) => addToCart(product, e)}
                            className="rounded p-1 myborder-pink bg-white mb-2 "
                          >
                            Add To Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
        <button
          onClick={() => scroll(2)}
          className="home-control-btn border border-0 bg-none "
        >
          <img src={next} className="home-control" />
        </button>
      </div>
    </div>
  );
}
