import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import { TextInput } from "../../ui";

export default function Register(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();

  const initialValues = {
    name: "",
    email_or_phone: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required!"),
    email_or_phone: Yup.string().required("Required!"),
    password: Yup.string().required("Required!"),
    confirmPassword: Yup.string().required("Required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    // resetForm();

    const data = {
      ...values,
      redirect_url_exists:
        sessionStorage.getItem("redirect_url_exists") === "true" ? true : false,
    };

    axios
      .get("/sanctum/csrf-cookie", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((response) => {
        axios
          .post("/api/register", data, {
            headers: { "Allowed-Source": allowedSource },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              swal("Processing", "Please Wait", "success");
              localStorage.setItem("auth_token", res.data.token);
              localStorage.setItem("auth_name", res.data.username);
              localStorage.setItem("isVerified", res.data.isVerified);
              localStorage.setItem("expires_at", res.data.expires_at);
              props.setIsVerify(res.data.isVerified);

              swal("Success", res.data.message, "success");
              history.push("/otp");
            } else {
              setregisterInput({
                ...registerInput,
                error_list: res.data.message,
              });
            }
          });
      });
  };

  const [registerInput, setregisterInput] = useState({
    name: "",
    emailOrPhone: "",
    password: "",
    confirmPassword: "",
    error_list: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="Register container my-5 flex flex-col justify-center items-center">
        <h2 className="text-2xl font-bold text-black">Register</h2>
        <div className="border rounded-xl shadow-xl px-5 py-4 w-fit">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="space-y-5">
              <TextInput name="name" inputType="text" label="Name" />
              <TextInput
                name="email_or_phone"
                inputType="text"
                label="Email or Mobile"
              />
              <TextInput
                name="password"
                inputType="password"
                label="Password"
              />
              <TextInput
                name="confirmPassword"
                inputType="password"
                label="Confirm Password"
              />
              <button
                className="border bg-blue-500 py-2 px-4 rounded-md"
                type="submit"
              >
                Submit
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
