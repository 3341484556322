import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Route, Switch } from "react-router-dom";
import ShokhiBox from "./ShokhiBox";
import ShokhiItems from "./ShokhiItems";

export default function Box(props) {
  let history = useHistory();

  const [shokhiBox, setShokhiBox] = useState([]);
  const [totalPrice, setPrice] = useState(0);

  useEffect(() => {
    let total = 0;

    shokhiBox.forEach((element) => {
      total += element.item.price;
    });
    //if(shokhi_box.length>=10)
    //setPrice(total*90/100)
    //else
    setPrice(total);
  }, [history]);

  return (
    <div>
      <Switch>
        <Route exact path="/shokhibox">
          <ShokhiBox
            shokhiBox={shokhiBox}
            setShokhiBox={setShokhiBox}
            setCartLength={props.setCartLength}
            setPrice={setPrice}
            totalPrice={totalPrice}
          />
        </Route>

        <Route exact path="/shokhibox/:subcategory">
          <ShokhiItems
            shokhiBox={shokhiBox}
            setShokhiBox={setShokhiBox}
            setPrice={setPrice}
            totalPrice={totalPrice}
          />
        </Route>
      </Switch>
    </div>
  );
}
