import React, { useEffect, useState } from "react";

import { Carousel } from "flowbite-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import b3 from "../../images/cod.webp";
import b2 from "../../images/discount.webp";
import b1 from "../../images/welcome.webp";
export default function Slider() {
  let history = useHistory();

  useEffect(() => {
    const preloadImages = () => {
      const images = [
        b1,
        b2,
        b3,
        // Add more image paths as needed
      ];

      images.forEach((image) => {
        new Image().src = image;
      });
    };

    preloadImages();

    // Cleanup function (optional)
    return () => {
      // Perform any cleanup here, if needed
    };
  }, []);

  const [picIndex, setPicIndex] = useState(0);

  const pics = [
    {
      url: b1,
      link: "/trending/All Products",
    },
    {
      url: b2,
      link: "/products/Bundles",
    },
    {
      url: b3,
      link: "#",
    },
  ];

  const nextPic = () => {
    if (picIndex + 1 > pics.length - 1) setPicIndex(0);
    else setPicIndex((prev) => prev + 1);
  };

  const prevPic = () => {
    if (picIndex - 1 < 0) setPicIndex(pics.length - 1);
    else setPicIndex((prev) => prev - 1);
  };

  const bannerStyle = {
    transform: `translate(${picIndex * -100}%)`,
  };

  const goTo = (link) => {
    history.push(link);
  };

  return (
    <div className="h-40 sm:h-64 xl:h-80 2xl:h-96 container">
      <Carousel>
        {pics.map((pic, index) => {
          return (
            <div key={pic.url}>
              <img
                className="w-full h-full"
                src={pic.url}
                onClick={() => goTo(pic.link)}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
