import React, { useState } from "react";

import axios from "axios";
import swal from "sweetalert";

export default function AddAPromo() {
  const [input, setInput] = useState({
    promoCode: "",
    amount: "",
    type: "",
  });

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
    const data = {
      promoCode: input.promoCode,
      amount: input.amount,
      type: input.type,
    };

    axios
      .post("/api/setPromo", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((response) => {
        const res = response.data;

        if (res.status === 200) {
          document.getElementById("theForm").reset();
          swal("Success", res.message, "success");
        } else {
          swal("Warning", "Fill up all the fields", "warning");
        }
      });
  };

  return (
    <div>
      <h4>Add Sub-Category</h4>
      <form onSubmit={submit} id="theForm">
        <div classNameName="form-group mb-3">
          <label>Promo Code</label>
          <input
            style={{ width: "300px" }}
            onChange={handleInput}
            value={input.promoCode}
            className="form-control mx-auto"
            type="text"
            id="promoCode"
            name="promoCode"
          />
        </div>

        <div className="flex justify-center items-center space-x-5">
          <div className="form-group mb-3 flex justify-center items-center space-x-1">
            <input
              onChange={handleInput}
              value={"percent"}
              type="radio"
              id="percent"
              name="type"
            />
            <label htmlFor="percent">Percent</label>
          </div>
          <div className="form-group mb-3 flex justify-center items-center space-x-1">
            <input
              onChange={handleInput}
              value={"taka"}
              type="radio"
              id="taka"
              name="type"
            />
            <label htmlFor="taka">Taka</label>
          </div>
        </div>

        {input.type && (
          <div>
            <label htmlFor="amount">
              {" "}
              {input.type === "percent" ? "Percent" : "Taka"}{" "}
            </label>
            <input
              style={{ width: "300px" }}
              onChange={handleInput}
              value={input.amount}
              className="form-control mx-auto"
              type="text"
              id="amount"
              name="amount"
            />
          </div>
        )}

        <button type="submit" className="btn btn-primary mt-3">
          Add
        </button>
      </form>
    </div>
  );
}
