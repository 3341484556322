import axios from "axios";
import html2pdf from "html2pdf.js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CompanyInfo from "../admin/CompanyInfo.js";

export default function OnDelivery() {
  const [ShowInvoice, setShowInvoice] = useState(false);
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  let history = useHistory();

  const [loading, setloading] = useState(true);

  const [orders, setOrders] = useState([]);

  const [empty, setEmpty] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    axios
      .get("/api/onDelivery", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then(async (res) => {
        if (res.data.status === 200) {
          setOrders(res.data.orders);

          if (!res.data.orders.length) {
            ////////console.log("empty")
            setEmpty(true);
          }
        } else {
          setErrors(res.data.messages);
          history.push("/admin/dashboard");
        }
        setloading(false);
      })
      .catch((error) => {
        ////////console.log(error);
        history.push("/admin/dashboard");
      });
  }, [history]);

  const deliver = (id) => {
    const data = {
      id: id,
      del_status: "Delivered",
    };
    axios
      .post("/api/set-status", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          window.location.reload();
        }
      });
  };

  const cancel = (id) => {
    const data = {
      id: id,
    };
    axios
      .post("/api/cancel-order", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          window.location.reload();
        }
      });
  };
  const [search, setSearch] = useState("");
  const Search = (e) => {
    let temp_search = "";
    temp_search = e.target.value;

    setSearch(temp_search);
  };

  const Download = (divId) => {
    const element = document.getElementById(divId);

    if (element) {
      html2pdf(element);
    } else {
      console.error(`Element with id ${divId} not found.`);
    }
  };

  const calculateDeliveryCharge = (order) => {
    const totalDeliveryCharge = order.orderdetails.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
    return order.total - totalDeliveryCharge;
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString("en-US");
  };

  if (loading) {
    return <div>Loading Orders..</div>;
  } else if (empty) {
    return <div>No order is pending</div>;
  } else {
    return (
      <div>
        <input
          type="text"
          placeholder="Search Order By Phone Number"
          className="searchBar w-25 mx-auto m-3 border rounded"
          name="search"
          value={search}
          onChange={(e) => Search(e)}
        />
        {orders
          .filter((order) => {
            let temp_search = search;
            let temp_name = order.phn;
            if (temp_search === "") return true;
            else if (temp_name.includes(temp_search)) {
              return true;
            } else return false;
          })
          .map((order, index) => (
            <div className="">
              {ShowInvoice && (
                <div id={order.id} className="invoiceWidth mx-auto fw-bold">
                  <div>
                    <CompanyInfo />

                    <div
                      className="mx-auto card invoiceWidth"
                      style={{ borderRadius: "0px 0px 5px 5px" }}
                    >
                      <div className="my-4">
                        <h1 className="card-title">Invoice</h1>
                      </div>
                      <div className="card-body  text-start">
                        <div className="mb-3 row">
                          <div className="col-sm-6 text-start">
                            <strong>Invoice ID:</strong> {order.invoiceId}
                          </div>
                          <div className="col-sm-6 text-sm-start">
                            <strong>Date:</strong>{" "}
                            {formatDate(order.created_at)}
                            <br />
                            <strong>Time:</strong>{" "}
                            {formatTime(order.created_at)}
                          </div>
                        </div>

                        <div className="mb-3   text-start ">
                          <div className="  text-start my-p-0">
                            <strong>Customer Name:</strong> {order.name}
                          </div>
                          <div className=" text-sm-start">
                            <strong>Phone:</strong> {order.phn}
                          </div>
                        </div>

                        <div className="mb-3  text-start">
                          <div className="col-sm-12 text-start">
                            <strong>Address:</strong> {order.address}
                          </div>
                        </div>

                        <table className="table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.orderdetails.map((item, index) => (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price} Tk</td>
                                <td>{item.quantity * item.price} Tk</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="row">
                          <div className=" "></div>
                          <div className=" text-start">
                            <h5>
                              {`Delivery Charge: ${calculateDeliveryCharge(
                                order
                              )}`}{" "}
                              Tk
                            </h5>
                            <h5>Total: {order.total} Tk</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div>
                {!ShowInvoice && (
                  <button
                    onClick={(e) => setShowInvoice(true)}
                    className="btn m-1 bg-primary text-white"
                  >
                    View Invoice
                  </button>
                )}
                {ShowInvoice && (
                  <button
                    onClick={(e) => setShowInvoice(false)}
                    className="btn m-1 bg-primary text-white"
                  >
                    Hide Invoice
                  </button>
                )}
                <button
                  onClick={(e) => deliver(order.id)}
                  className="btn m-1 bg-success text-white"
                >
                  Complete Order
                </button>

                <button
                  onClick={(e) => Download(order.id)}
                  className="btn m-1 bg-warning text-white"
                >
                  Download
                </button>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
