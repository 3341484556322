import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import sad from "../../images/sad.png";

export default function ProductWithSubCat(props) {
  const { setCartLength } = useContext(MyCartContext);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const { category, subcategory } = useParams();

  let history = useHistory();

  const [loading, setloading] = useState(true);

  const [products, setProducts] = useState([]);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const data = {
      cat: category,
      subcat: subcategory,
    };
    axios
      .post("/api/get-subcategory-products", data, {
        headers: { "Allowed-Source": allowedSource },
      })

      .then((res) => {
        if (res.data.status === 200) {
          setProducts(res.data.products);
        } else {
          setErrors(res.data.messages);
          history.push("/");
        }
        setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
        history.push("/");
      });
  }, [history, subcategory]);

  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  const [search, setSearch] = useState("");

  const Search = (e) => {
    let temp_search = "";
    temp_search = e.target.value;

    setSearch(temp_search);
  };

  function alreadyAdded(id) {
    let flag = 0;

    cart.forEach((item) => {
      if (item.id === id && item.box_num === 0) {
        flag = 1;
      }
    });

    if (flag) return true;

    return false;
  }

  const addToCart = (product, e) => {
    e.preventDefault();
    e.persist();

    let total = 0;
    let found = 0;
    cart.forEach((element) => {
      if (product.id === element.id) {
        total = total + 1;
        if (product.stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      let product_id = product.id;

      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: product.name,
        id: product_id,
        size: product.size.name,
        color: "",
        pic: product.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: 0,
      };

      cart.push(productInput);

      setCartD(cart);
      setCart(cart);
      props.setCartLength(cart.length);

      localStorage.setItem("cartLength", cart.length);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sortBy, setSortBy] = useState(-1);

  const sortClicked = (e, val) => {
    setSortBy(val);

    let sortedItems = [...products];

    if (val == 1) {
      sortedItems.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (val == 0) {
      sortedItems.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    } else if (val == 2) {
      sortedItems.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (val == 3) {
      sortedItems.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }

    setProducts(sortedItems);
  };
  const { cart, setCart } = useContext(MyCartContext);
  if (loading) {
    return <div>{/* <img src={loader} alt="Your GIF" /> */}</div>;
  } else if (products.length === 0) {
    return (
      <div className="text-black d-flex flex-column">
        <h4 className="fw-bold mt-5">No Items Available</h4>
        <img src={sad} className="item-pic mx-auto" />
      </div>
    );
  } else {
    return (
      <div className="outercontainer d-flex flex-column">
        <Helmet>
          <title>Products</title>
          <meta name="description" contents="Women hygiene products" />

          <meta
            name="keywords"
            contents="Women hygiene products,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
          />
        </Helmet>

        <input
          type="text"
          placeholder="Search item"
          className="searchBar w-25 mx-auto m-3 border rounded"
          name="search"
          value={search}
          onChange={(e) => Search(e)}
        />
        <div className="d-flex mx-4">
          <label className="text-black p-2 fw-bold">Sort By Price</label>
          <select
            className="p-2 border text-black w-40 cursor-pointer"
            name="cars"
            id="cars"
            value={sortBy}
            onChange={(e) => sortClicked(e, e.target.value)}
          >
            <option className="p-2" value={-1}>
              None
            </option>
            <option className="p-2" value={0}>
              High to Low
            </option>
            <option className="p-2" value={1}>
              Low to High
            </option>
            <option className="p-2" value={2}>
              Alphabetic(A-Z)
            </option>
            <option className="p-2" value={3}>
              Alphabetic(Z-A)
            </option>
          </select>
        </div>
        <div className="Container d-flex flex-wrap justify-content-center align-items-center">
          {products
            .filter((item) => {
              let temp_search = search.toLowerCase();
              let temp_name = item.name.toLowerCase();
              if (temp_search === "") return true;
              else if (temp_name.includes(temp_search)) {
                return true;
              } else return false;
            })
            .map((product, index) => {
              return (
                <div className="tredningProduct1 border m-1">
                  <Link to={`/product/${product.id}`} className="dec-none">
                    <div key={index} className="m-2 item-card p-2">
                      {product.discount_id !== 1 && (
                        <span className="og-price">-{product.price}Tk</span>
                      )}

                      <img
                        loading="lazy"
                        src={product.pic}
                        className="card-img-top item-pic"
                        alt="..."
                      />
                      <div className="item-body ">
                        <h5 className="item-title">{product.name}</h5>
                        <h4></h4>

                        <div className="d-flex justify-content-between">
                          <span className="">
                            <span className="item-price">
                              ৳{Math.ceil(calculatePrice(product))}
                            </span>
                          </span>
                          {product.size.name !== "Not Available" && (
                            <span className=" border px-2 rounded ">
                              {product.size.name}
                            </span>
                          )}
                        </div>
                        <button
                          onClick={(e) => addToCart(product, e)}
                          className="myborder-pink bg-white mb-2"
                        >
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
