import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function Dashboard() {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  ////////console.log(allowedSource);
  let history = useHistory();

  const [loading, setloading] = useState(true);

  const [orders, setOrders] = useState([]);
  const [empty, setEmpty] = useState(false);

  const [errors, setErrors] = useState([]);

  const [Pending, setPending] = useState(0);
  const [Delivered, setDelivered] = useState(0);

  useEffect(() => {
    axios
      .get("/api/allOrders", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOrders(res.data.orders);

          if (!res.data.orders.length) {
            ////////console.log("empty")
            setEmpty(true);
          } else {
            let orders = res.data.orders;

            let delivered = 0.0,
              pending = 0.0;

            for (let i = 0; i < orders.length; i++) {
              if (orders[i].status === "Pending")
                pending = pending + parseFloat(orders[i].total);
              else delivered = delivered + parseFloat(orders[i].total);
            }
            setDelivered(delivered);
            setPending(pending);
          }
        } else {
          setErrors(res.data.messages);
          history.push("/admin/dashboard");
        }
        setloading(false);
      })
      .catch((error) => {
        ////////console.log(error);
        history.push("/admin/dashboard");
      });
  }, [history]);

  if (loading) {
    return <div>Loading Orders..</div>;
  } else if (empty) {
    return <div>No order yet</div>;
  } else {
    return (
      <div>
        <div className="Finance d-flex flex-wrap justify-content-around ">
          <div className="box1 d-flex flex-column justify-content-center">
            <Link className="dec-none" to={"/admin/pending"}>
              <h3 className=" text-black">Pending</h3>
              <h4 className=" text-black">{Pending} Tk</h4>
            </Link>
          </div>
          <div className="box2 d-flex flex-column justify-content-center">
            <Link className="dec-none" to={"/admin/delivered"}>
              <h3 className=" text-black">Sold</h3>
              <h4 className=" text-black">{Delivered} Tk</h4>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
