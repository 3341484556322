import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import MasterLayout from './layouts/admin/MasterLayout';
import axios from 'axios';
import swal from 'sweetalert';
import {useHistory} from 'react-router-dom';

export default function AdminPrivateRoute(props) {
    const history = useHistory();

    const [Authenticated, setAuthenticated] = useState(false);
    const [Loading, setLoading] = useState(true);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

    useEffect(() => {
      axios.get("/api/checkingAuthenticated",{
        headers: { 'Allowed-Source': allowedSource }
    })
      .then(res=>{
          
           if(res.status===200){
            setAuthenticated(true);
           }

           setLoading(false);
      })
      .catch(error => {
        console.error(error);  
        setAuthenticated(false);
        setLoading(false);
      });
    
      return ()=>{
        setAuthenticated(false);
       }

    }, []);

  axios.interceptors.response.use(undefined,function axiosRetryInterceptor(err){
    if(err.response.status === 401){
        swal("Unauthorized",err.response.data.message,'warning');
        history.push("/");
    }
    return Promise.reject(err);
  }

  );

  axios.interceptors.response.use(function(response){
    return response;
  },function (error){
   if(error.response.status === 403){//access denied
       swal("Forbidden",error.response.data.message,"warning");
       setLoading(false);
       setAuthenticated(false);
   }
    
  }

  );



    
    if(Loading){
        return <h1>Loading..</h1>
    }
    else{

  return (
    
   <Route 
   path={props.path}
   name = {props.name}
   render={(props,location)=>
       Authenticated ?
      (<MasterLayout {...props}/> ) :
      (<Redirect to={ {pathname:"/login",state:{from:location}}} />)
   }
   
   /> 
  )

}



}
