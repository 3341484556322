import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Draggable from "../frontend/Draggable";

export default function Trending(props) {
  let TrendingProducts = props.TrendingProducts;

  const slides = () => {
    if (window.innerWidth > 940) return 4;
    return 2;
  };

  function currentSlides() {
    let x = calculateTotalSlides();

    let arr = [];
    for (let i = 0; i < x; i++) {
      arr.push(i);
    }
    return arr;
  }
  function calculateTotalSlides() {
    let x = TrendingProducts.length / slides();
    x = Math.floor(x);
    if (TrendingProducts.length % slides() === 1) x++;
    if (x === 0) return 1;

    return x;
  }

  const [slide_no, setSlide_no] = useState(currentSlides());
  const [itemPerSlide, setItemPerSlide] = useState(slides());
  const [totalSlides, setTotalSlides] = useState(calculateTotalSlides());

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const updateDimension = () => {
    setSlide_no(currentSlides());
    setItemPerSlide(slides());
    setTotalSlides(calculateTotalSlides());

    setScreenSize(getCurrentDimension());
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  });

  ////////console.log(slide_no)

  return (
    <>
      <section className="trending homesec mt-4 z-0">
        <div className="d-flex justify-content-between home-product-title">
          <span className="text-black fs-5 ms-2">
            {props.title}
            {props.emoji}
          </span>
          <Link
            className="dec-none btn border text-black"
            to={`trending/${props.title}`}
          >
            View All
          </Link>
        </div>

        <Draggable
          addToCart={props.addToCart}
          serial={props.serial}
          TrendingProducts={TrendingProducts}
          calculatePrice={props.calculatePrice}
        />
      </section>
    </>
  );
}
