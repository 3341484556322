import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export default function Login(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();

  const [loginInput, setloginInput] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setloginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
      email_or_phone: loginInput.email,
      password: loginInput.password,
      redirect_url_exists:
        sessionStorage.getItem("redirect_url_exists") === "true" ? true : false,
    };

    axios
      .post("/api/login", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_email", res.data.email);
          localStorage.setItem("auth_name", res.data.username);
          localStorage.setItem("isVerified", "true");

          props.setloggedIn(true);

          swal("Success", res.data.message, "success");
          if (res.data.auth) {
            props.setAdmin(true);
            localStorage.setItem("auth", true);
            history.push("/admin");
          } else {
            localStorage.setItem("auth", false);
            if (res.data.redirect_url_exists) {
              sessionStorage.removeItem("redirect_url_exists");
              history.push("/checkout");
            } else {
              history.push("/");
            }
          }
        } else if (res.data.status === 201) {
          swal("Success", res.data.message, "success");
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else {
          setloginInput({ ...loginInput, error_list: res.data.message });
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Login container my-5">
      <h1 className="text-2xl font-bold text-black mb-5">Login</h1>
      <form className="card" onSubmit={loginSubmit}>
        <div className=" mb-3 w-75 m-auto">
          <label className="form-label">Email address</label>
          <input
            type="text"
            className="bg-white border rounded-md outline-0 ring-0 focus:ring-1 focus:ring-blue-400 text-black py-1 px-2"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            name="email"
            onChange={handleInput}
            value={loginInput.email}
          />
          <h6>{loginInput.error_list.email}</h6>
        </div>
        <div className=" mb-3 w-75 m-auto flex flex-col justify-center items-center">
          <label className="form-label">Password</label>
          <input
            type="password"
            className="bg-white border rounded-md outline-0 ring-0 focus:ring-1 focus:ring-blue-400 text-black py-1 px-2"
            id="exampleInputPassword1"
            name="password"
            onChange={handleInput}
            value={loginInput.password}
          />
          <h6>{loginInput.error_list.password}</h6>
        </div>

        <div className=" mb-3 w-75 m-auto form-check"></div>
        <button
          type="submit"
          className="border bg-blue-500 py-2 px-4 rounded-md text-white"
        >
          Login
        </button>
      </form>
    </div>
  );
}
