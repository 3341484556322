import AddProduct from "../components/admin/AddProduct";
import Category from "../components/admin/Category";
import Dashboard from "../components/admin/Dashboard";

import Delivered from "../components/admin/Delivered";
import Pending from "../components/admin/Pending";
import Profile from "../components/admin/Profile";
import UpdateCat from "../components/admin/UpdateCat";

import UpdateProduct from "../components/admin/UpdateProduct";
import UpdateSubCat from "../components/admin/UpdateSubCat";
import ViewCategory from "../components/admin/ViewCategory";
import ViewProducts from "../components/admin/ViewProducts";
import ViewSubCat from "../components/admin/ViewSubCat";
import AddSubCat from "../components/admin/AddSubCat";
import AddDiscount from "../components/admin/AddDiscount";
import AddBlog from "../components/admin/AddBlog";
import ViewBlog from "../components/admin/ViewBlog";
import EditBlog from "../components/admin/EditBlog";
import ProductPic from "../components/admin/ProductPic";
import AddPic from "../components/admin/AddPic";
import AddAPromo from "../components/admin/AddAPromo";
import PromoCodes from "../components/admin/PromoCodes";
import OnDelivery from "../components/admin/OnDelivery";
 
 
 

 

const routes = [
 {path:'/admin' ,exact:true,name:"Admin",component:Dashboard},
 {path:'/admin/dashboard' ,exact:true,name:"Dashboard",component:Dashboard},
 {path:'/admin/profile' ,exact:true,name:"Profile",component:Profile},
 {path:'/admin/add-category' ,exact:true,name:"Category",component:Category},
 {path:'/admin/view-category' ,exact:true,name:"ViewCategory",component:ViewCategory},
 {path:'/admin/add-product' ,exact:true,name:"AddProduct",component:AddProduct},
 {path:'/admin/pending' ,exact:true,name:"Pending",component:Pending},
 {path:'/admin/delivered' ,exact:true,name:"Delivered",component:Delivered},
 {path:'/admin/view-products' ,exact:true,name:"ViewProducts",component:ViewProducts},

 {path:'/admin/update/:id' ,exact:true,name:"UpdateProduct",component:UpdateProduct},

 {path:'/admin/update-cat/:id' ,exact:true,name:"UpdateCat",component:UpdateCat},

 {path:'/admin/view-subcats' ,exact:true,name:"UpdateCat",component:ViewSubCat},
 {path:'/admin/update-subcat/:id' ,exact:true,name:"UpdateCat",component:UpdateSubCat},

 {path:'/admin/edit-blog/:id' ,exact:true,name:"UpdateBlog",component:EditBlog},

 {path:'/admin/add-subcat' ,exact:true,name:"UpdateCat",component:AddSubCat},
 {path:'/admin/add-discount' ,exact:true,name:"Discount",component:AddDiscount},
 {path:'/admin/add-blog' ,exact:true,name:"Blog",component:AddBlog},
 {path:'/admin/view-blog' ,exact:true,name:"Blog",component:ViewBlog},
 {path:'/admin/productsPic' ,exact:true,name:"Blog",component:ProductPic},
 {path:'/admin/addPic/:id' ,exact:true,name:"Blog",component:AddPic},
 {path:'/admin/addPromo' ,exact:true,name:"Blog",component:AddAPromo},
 {path:'/admin/allPromo' ,exact:true,name:"Blog",component:PromoCodes},
 {path:'/admin/onDelivery' ,exact:true,name:"Blog",component:OnDelivery}
]


export default routes;