import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";

import { useState } from "react";
import close from "../../images/close.png";
import menu from "../../images/menu.png";
import routes from "../../routes/routes";

export default function MasterLayout() {
  const [Show, setShow] = useState(false);

  const toggleSidebar = () => {
    setShow(!Show);
  };

  return (
    <div className="master admin-bg text-white">
      <nav className="admin-sidebar-btn d-flex ">
        <button onClick={() => toggleSidebar()} className="m-3 admin-btn">
          <img className="admin-sidebar-open" src={menu} alt="Close"></img>
        </button>
      </nav>

      <div
        className={`admin-sidebar d-flex flex-column  ${
          Show ? "sidebar-show" : ""
        }`}
      >
        <button onClick={() => toggleSidebar()} className="m-3 admin-btn ">
          <img className="admin-sidebar-close " src={close} alt="Close"></img>
        </button>
        <Link to="/admin/dashboard" className="dec-none">
          Dashboard
        </Link>
        <Link to="/admin/profile" className="dec-none">
          Profile
        </Link>

        <h5>Category</h5>
        <Link to="/admin/add-category" className="dec-none">
          Add Category
        </Link>
        <Link to="/admin/view-category" className="dec-none">
          View Category
        </Link>

        <h5>Discount</h5>
        <Link to="/admin/add-discount" className="dec-none">
          Add Discount
        </Link>

        <h5>Sub-Category</h5>
        <Link to="/admin/view-subcats" className="dec-none">
          View Sub-Category
        </Link>
        <Link to="/admin/add-subcat" className="dec-none">
          Add Sub-Category
        </Link>

        <h5>Product</h5>
        <Link to="/admin/add-product" className="dec-none">
          Add Product
        </Link>
        <Link to="/admin/view-products" className="dec-none">
          View Products
        </Link>
        <Link to="/admin/productsPic" className="dec-none">
          Add Pic
        </Link>

        <h5>Delivery</h5>
        <Link to="/admin/pending" className="dec-none">
          Pending
        </Link>
        <Link to="/admin/onDelivery" className="dec-none">
          On Delivery
        </Link>
        <Link to="/admin/delivered" className="dec-none">
          Delivered
        </Link>

        <h5>Blog</h5>
        <Link to="/admin/add-blog" className="dec-none">
          Add Blog
        </Link>
        <Link to="/admin/view-blog" className="dec-none">
          View/Edit Blog
        </Link>

        <h5>Promo Code</h5>
        <Link to="/admin/addPromo" className="dec-none">
          Add Promo
        </Link>
        <Link to="/admin/allPromo" className="dec-none">
          All Promo Codes
        </Link>
      </div>

      <div className="content ">
        <main className="container-fluid">
          <Switch>
            {routes.map((route, index) => {
              return (
                route.component && (
                  <Route
                    key={index}
                    path={route.path}
                    name={route.name}
                    exact={route.exact}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </main>
      </div>
    </div>
  );
}
