import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

export default function UpdateSubCat() {
    let history = useHistory();
    const { id } = useParams();
    const [name,setName] = useState("");
    const [loading, setloading] = useState(true);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {
        const data ={
            id:id
        }
     
        axios.post('/api/get-subcat-by-id',data,{
          headers: { 'Allowed-Source': allowedSource }
      })
        .then(res=>{
            
            if(res.data.status===200){
                setName(res.data.subcategory.name);
                ////////console.log("GG");
            }
            else{
                swal("Warning","Something went wrong","warning")
            }
            setloading(false);
    
        })
        .catch(error=>{
            
       });  
  
    
        }, [history]);


 const handleInput = (e) =>{
       setName(e.target.value);
 }

 const submit = (e) =>{
    e.preventDefault();
    
    const data ={
        name:name,
        id:id,
    }
 
    axios.post('/api/update-subcat',data,{
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(response=>{
   
 
     if(response.data.status ===200){
         document.getElementById('theForm').reset();
        swal("Success",response.data.message,"success");
      
     }
     else{
        swal("Warning","Fill up the field","warning")
        
     }
 
 
    });
 
 
  }


  if(loading){
    return(
      <div>Loading...</div>
    )
  }
  else{
  return (
    <div>
        <h4>Update Sub-Category</h4>
        <form onSubmit={submit} id='theForm'>
   
 

  <div className="form-group mb-3">
    <label >Name</label>
    <input onChange={handleInput} value={name} class="form-control"  type="text" id="name" name='name' />
  </div>

  

<button type='submit' className='btn btn-primary'>Update</button>

</form>
    </div>
  )
  }

}
