const Categories = [
  {
    name: "Feminine Hygiene",
    subcats: [
      {
        name: "Sanitary Pads",
      },
      {
        name: "Panties",
        subsubcats: [
          {
            name: "panty 1",
          },
          {
            name: "panty 1",
          },
        ],
      },
      {
        name: "Tampons",
      },
      {
        name: "V Wash",
      },

      {
        name: "Disposable Bag",
      },

      {
        name: "Hair Removal",
      },
    ],
  },
  {
    name: "Bundles",
    subcats: [
      {
        name: "Feminine Care Bundles",
      },
      {
        name: "Chocolate Bundles",
      },
      {
        name: "Mask Bundles",
      },
    ],
  },
  {
    name: "Cramp Relief",
    subcats: [
      {
        name: "Electric Massager",
      },
      {
        name: "Hot Water Bag",
      },
    ],
  },
  {
    name: "Masks",
    subcats: [
      {
        name: "Eye Mask",
      },
      {
        name: "Nose Mask",
      },
      {
        name: "Lip Mask",
      },
      {
        name: "Face Mask",
      },
    ],
  },

  {
    name: "Treats",
    subcats: [
      {
        name: "Chocolates",
      },
      {
        name: "Tea",
      },
    ],
  },

  {
    name: "Beauty Care",
    subcats: [
      {
        name: "Intimate Brightening Solution",
      },
      {
        name: "Body Mist",
      },
      {
        name: "Moisturizer",
      },
      {
        name: "Facewash",
      },
      {
        name: "Body Lotion",
      },
    ],
  },
];

export default Categories;
