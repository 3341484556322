import { Field } from "formik";

export const TextInput = ({ label, inputType, name }) => {
  return (
    <div className="w-fit">
      <Field name={name}>
        {({ field, meta }) => {
          return (
            <div className="flex flex-col items-start">
              <label htmlFor={name} className="text-black">
                {label}
              </label>
              <input
                type={inputType}
                id={name}
                className="bg-white border rounded-md outline-0 ring-0 focus:ring-1 focus:ring-blue-400 text-black py-1 px-2"
                {...field}
              />
              {meta.touched && meta.error ? (
                <div className="text-red-500"> {meta.error} </div>
              ) : null}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
