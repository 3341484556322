import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

export default function EditBlog() {

    let history = useHistory();
    const { id } = useParams();
    const [Blog,setBlog] = useState("");
    const [loading, setloading] = useState(true);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

    const [blogInput, setBlogInput] = useState({
        heading:'',
        pic:'',
        blog:'',
        errorList:[],
     });


    useEffect(() => {
        const data ={
            id:id
        }
     
        axios.post('/api/get-blog-by-id',data,{
          headers: { 'Allowed-Source': allowedSource }
      })
        .then(res=>{
            
            if(res.data.status===200){
                setBlogInput({...blogInput,
                heading:res.data.blog.Heading,
                pic:res.data.blog.pic,
                blog:res.data.blog.blog,

                });
                ////////console.log("GG");
            }
            else{
                swal("Warning","Something went wrong","warning")
            }
            setloading(false);
    
        })
        .catch(error=>{
            
       });  
  
    
        }, [history]); 


        const handleInput = (e) =>{
            setBlogInput({...blogInput,[e.target.name]:e.target.value});
         }

         const submit = (e) =>{
            e.preventDefault();
            
            const data ={
                blogInput:blogInput,
                id:id,
            }
         
            axios.post('/api/update-blog',data,{
              headers: { 'Allowed-Source': allowedSource }
          }).then(response=>{
              const res = response.data;
         
             if(res.status ===200){
                 
                 document.getElementById('theForm').reset();
                swal("Success",res.message,"success");
              
             }
             else if(res.status ===400){
                swal("Warning","Fill up all the fields","warning")
                 
             }
         
         
            });
         
         
          }

  return (
    <div>
    <h4>Add Blog</h4>
    <form onSubmit={submit} id='theForm'>

    <div className="form-group mb-3">
<label >Heading</label>
<input onChange={handleInput} value={blogInput.heading} class="form-control"  type="text" id="heading" name='heading' />
</div>

    <div className="form-group mb-3">
<label >Pic</label>
<input onChange={handleInput} value={blogInput.pic} class="form-control"  type="text" id="pic" name='pic' />
</div>

<div className="form-group mb-3">
<label >Blog</label>
<textarea wrap="hard"  onChange={handleInput} value={blogInput.blog}  class="form-control" id="blog" name="blog" rows="15" cols="10">
</textarea>
</div>



<button type='submit' className='btn btn-primary'>Update</button>

</form>
</div>

  )
}
