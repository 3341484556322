import React from "react";

export default function (props) {
  return (
    <div>
      <label>Add To Home</label>
      <select
        class="form-select mb-3"
        value={props.productInput.home}
        name="home"
        aria-label="Default select example"
        onChange={props.handleInput}
      >
        {props.Sections.map((section, index) => {
          return (
            <option key={index} value={section.name}>
              {section.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
