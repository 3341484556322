import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import downArrow from "../../images/downArrow.png";

import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import RelatedDraggable from "./RelatedDraggable";

export default function Product(props) {
  const { cart, setCart } = useContext(MyCartContext);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const { id } = useParams();

  useEffect(() => {
    setloading(true);
    window.scrollTo(0, 0);
  }, [id]);

  let history = useHistory();
  const [loading, setloading] = useState(true);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});

  const [selectedPic, setPic] = useState([]);
  const [pics, setPics] = useState([]);

  const [currentPics, setCurrentPics] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);

  const [errors, setErrors] = useState([]);

  const [selectedColor, setColor] = useState(1);
  const [selectedSize, setSize] = useState(0);

  const [focusedPic, setFocusedPic] = useState("");

  const [distinctSizes, setDistinctSizes] = useState([]);
  const [distinctColors, setDistinctColors] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const [sizes, setAllSizes] = useState([]);
  const [colors, setAllColors] = useState([]);

  const [discount, setDiscount] = useState(0);

  const handleCurrentPics = (product) => {
    let temp = [];
    pics.forEach((pic) => {
      if (pic.product_id === product.id) {
        temp.push(pic.pic);
      }
    });
    temp.push(product.pic);
    setCurrentPics([...temp]);
  };
  ////////console.log(currentPics)
  useEffect(() => {
    const data = {
      id: id,
    };
    axios
      .post("/api/get-product", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAllColors(res.data.colors);
          setAllSizes(res.data.sizes);
          setColor(res.data.product.color_id);
          setSize(res.data.product.size_id);
          setProducts(res.data.products);
          setProduct(res.data.product);
          setPic(res.data.product.pic);
          setPics(res.data.pics);
          setRelatedProducts(res.data.relatedProducts);

          let temp2 = [];
          res.data.pics.forEach((pic) => {
            if (pic.product_id == res.data.product.id) {
              temp2.push(pic.pic);
            }
          });
          temp2.push(res.data.product.pic);

          setFocusedPic(temp2[0]);
          setCurrentPics(temp2);

          let availableSizes = [];

          res.data.products.forEach((item, index) => {
            if (index === 0) setDiscount(item.discount.discount);

            availableSizes.push(item.size_id);

            if (item.id === res.data.product.id) {
              setSelectedProduct(item);
            }
          });

          let temp = [...new Set(availableSizes)];
          setDistinctSizes(temp);

          let availableColors = [];
          res.data.products.forEach((item) => {
            if (res.data.product.size_id === item.size_id) {
              availableColors.push(item.color_id);
            }
          });
          let temp_distinctColors = [...new Set(availableColors)];
          setDistinctColors(temp_distinctColors);
        } else {
          setErrors(res.data.messages);
          history.push("/");
        }
        setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
        history.push("/");
      });
  }, [id]);

  const sizeClicked = (distinctSize) => {
    setSize(distinctSize);
    let temp_availableColors = [];
    let temp_distinctColors = [];

    //set available colors according to the size to be set
    products.forEach((item) => {
      if (distinctSize === item.size_id) {
        temp_availableColors.push(item.color_id);
      }
    });
    temp_distinctColors = [...new Set(temp_availableColors)];
    setDistinctColors(temp_distinctColors);

    //default color is set to be the first one
    setColor(temp_distinctColors[0]);

    //set pic according to the color to be set
    products.forEach((product) => {
      if (product.color_id === temp_distinctColors[0]) {
        setPic(product.pic);
      }
      if (
        temp_distinctColors[0] === product.color_id &&
        distinctSize === product.size_id
      ) {
        setSelectedProduct(product);

        handleCurrentPics(product);
        setFocusedPic(product.pic);
      }
    });
  };

  const colorClicked = (distinctColor) => {
    setColor(distinctColor);

    products.forEach((product) => {
      if (product.color_id === distinctColor) {
        setPic(product.pic);
      }
      if (
        distinctColor === product.color_id &&
        selectedSize === product.size_id
      ) {
        setSelectedProduct(product);
      }
    });
  };

  function alreadyAdded(id) {
    let flag = 0;

    cart?.forEach((item) => {
      if (item.id === id && item.box_num === 0) {
        flag = 1;
      }
    });

    if (flag) return true;

    return false;
  }

  function colorAvailable(colors) {
    let flag = true;
    colors.forEach((color) => {
      if (color === 1) flag = false;
    });

    return flag;
  }
  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  const addToCart = (id, stock, e) => {
    e.preventDefault();

    let cartData = cart;

    let temp_cart = [];
    if (cartData !== null) temp_cart = cartData;

    let total = 0;
    let found = 0;
    temp_cart.forEach((element) => {
      if (element.id === id) {
        total = total + 1;
        if (stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: selectedProduct.name,
        id: selectedProduct.id,
        size: selectedProduct.size.name,
        color: "",
        pic: selectedProduct.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(selectedProduct)),
        box_num: 0,
      };

      temp_cart.push(productInput);
      setCart(temp_cart);
      setCartD(temp_cart);

      props.setCartLength(temp_cart.length);
      setCart(temp_cart);
    }
  };

  const [expanded, setExpanded] = useState(true);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  function sizeAvailable(distinctSizes) {
    for (let i = 0; i < distinctSizes.length; i++) {
      if (distinctSizes[i] === 1) return false;
    }
    return true;
  }

  if (loading) {
    return <div></div>;
  } else {
    return (
      <div className="d-flex flex-column">
        <div className="productPage m-2 d-flex flex-column flex-column flex-md-row justify-content-around align-items-start ">
          <Helmet>
            <title>Products</title>
            <meta name="description" contents="Women hygiene products" />

            <meta
              name="keywords"
              contents="Women hygiene products,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
            />
          </Helmet>

          <div className="mx-auto d-flex flex-column flex-lg-row  align-items-start gap-5">
            <div className="pic-outer d-flex  mx-auto ">
              <div className="pic-inner d-flex  flex-sm-column justify-content-center align-items-center">
                <img src={focusedPic} className="focused-pic zoom" />
                <div className="all-pic-container border border-2 d-flex flex-wrap justify-content-center align-items-center">
                  {currentPics.map((pic) => {
                    return (
                      <button
                        className="border border-0"
                        onClick={() => setFocusedPic(pic)}
                      >
                        <img className="small-product-pic zoom" src={pic} />
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="picAndBox  border border-2 rounded p-3">
                <div className="">
                  <div className="text-black d-flex flex-column justify-content-start  mt-4">
                    <h5 className="card-title fs-3">{product.name}</h5>
                    <h5 className="card-title my-3">
                      Price: {Math.ceil(calculatePrice(selectedProduct))} Tk
                      {selectedProduct.discount.id > 1 && (
                        <del className="fs-5 mx-1 text-danger">
                          {" "}
                          {selectedProduct.price}
                        </del>
                      )}
                    </h5>

                    {selectedProduct.discount.id > 1 && (
                      <div>
                        <h5 className="">
                          {" "}
                          {selectedProduct.discount.discount}% OFF
                        </h5>
                      </div>
                    )}

                    {sizeAvailable(distinctSizes) && (
                      <div className="sizes container mt-4">
                        <h4>Sizes</h4>
                        <div className="option-container d-flex flex-wrap justify-content-center">
                          {distinctSizes.map((distinctSize, index) => {
                            let name = "";
                            sizes.forEach((size) => {
                              if (size.id === distinctSize) {
                                name = size.name;
                              }
                            });

                            return (
                              <button
                                key={index}
                                className={`m-2 btn border-black ${
                                  distinctSize === selectedSize
                                    ? "bg-black text-white"
                                    : ""
                                }`}
                                onClick={(e) => sizeClicked(distinctSize)}
                              >
                                {name}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div>
                      {product.stock ? (
                        <button
                          onClick={(e) =>
                            addToCart(product.id, product.stock, e)
                          }
                          className="border rounded atc-btn"
                        >
                          Add to cart
                        </button>
                      ) : (
                        <button className="btn bg-dark text-white">
                          Request Stock
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="about d-flex flex-column justify-content-center align-items-center border-0 text-black">
                <button className="btn btn-info mt-3 des-btn">
                  Description{" "}
                  <img
                    className={`downArrow ${expanded ? "" : "rotated"}`}
                    src={downArrow}
                  ></img>
                </button>
                <div
                  className={` m-3 text-start card-text description-container ${
                    expanded ? "expanded" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>

                <p className="m-3 text-start card-text">
                  Grab this product now !
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="related-Products text-black">
          <h3>Related Products</h3>
          <RelatedDraggable
            setCartLength={props.setCartLength}
            addToCart={addToCart}
            serial={2}
            TrendingProducts={relatedProducts}
            calculatePrice={calculatePrice}
          />
        </div>
      </div>
    );
  }
}
