import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';



export default function ViewProducts() {
    let history = useHistory();
    
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setloading] = useState(true);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {
         
        axios.get('api/get-all-products',{
            headers: { 'Allowed-Source': allowedSource }
        })
        .then(res=>{
            if(res.data.status===200){
                setProducts(res.data.products);
            }
            else{
                ////////console.log(res.data.message);
            }
           setloading(false);

        })
        .catch(error=>{

            setErrors(error);
        });
    
      
    }, [history]);

    const DeleteProduct = (id)=>{
       const data = {
         id:id
       };
        axios.post('api/delete-product',data,{
            headers: { 'Allowed-Source': allowedSource }
        })
        .then(res=>{
            if(res.data.status===200){
                swal("Deleted","Deletion Confirmed",'success');
            }
            else{
                swal("Errir",res.data.message,'success');
            }
           // window.location.reload();

        });
        
    }

    const [search,setSearch] = useState("");

   const Search = (e) =>{
    let temp_search=""; 
    temp_search= e.target.value;

      setSearch(temp_search);
    

   };
    
    
    if(loading){
        return(
             <div>
                Loading Products..
             </div>
        )
       }   
    else{
  return (

    <div className='d-flex flex-column'>
        <input type="text" placeholder='Search item' className="searchBar w-25 mx-auto m-3 border rounded" name='search' value={search} onChange={(e)=>Search(e)}/>
    
          <div className='Container d-flex  flex-wrap justify-content-center'>
              
                  
                  
                

                       {
                        products.filter(item =>{
                            let temp_search = search.toLowerCase();
                            let temp_name = item.name.toLowerCase();
                            if(temp_search==="")
                              return true;
                            else if(temp_name.includes(temp_search)){
                              return true;
                            }
                            else
                              return false;
                            
                          }
                          ).map((product)=>{
                             
                            return(
                                <div className='eachProduct d-flex flex-column align-items-center m-2'>
                                        <img className="adminProduct" src={product.pic} alt=''></img>
                                        <span className='m-1'>Product ID : {product.id}</span>
                                        <span className='m-1'>Name : {product.name}</span>
                                        <span className='m-1'>Size : {product.size.name}</span>
                                        <span className='m-1'>Color : {product.color.name}</span>
                                        <span className='m-1'>Price : {product.price} Tk</span>
                                        <span className='m-1'>Discount : {product.discount.discount}%</span>
                                        <span className='m-1'>Stock : {product.stock}</span>
                                        <Link to={`/admin/update/${product.id}`} className='btn btn-warning m-1'>Update</Link>
                                        <button onClick={()=>DeleteProduct(product.id)} className='btn btn-danger m-1'>Delete</button>
                                </div>
                            )
                        })
                            
                        }    
                      </div>  
                      </div>
         
  )
    }
}
