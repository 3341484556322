import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import axios from "axios";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import correct from "../../images/correct.png";
import delivery from "../../images/delivery.png";
import returnpic from "../../images/return.png";
import Slider from "../frontend/Slider";
import DraggableLinks from "./DraggableLinks";
import Trending from "./Trending";

export default function Home(props) {
  const { cart, setCart, boxes, setBoxes } = useContext(MyCartContext);

  const [render, setRender] = useState(true);
  // ReactPixel.pageView();

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const [TrendingProducts, setTrendingProducts] = useState([]);
  const [hotproducts, setHotproducts] = useState([]);
  const [TrendingBundles, setTrendingBundles] = useState([]);
  const [TrendingHygiene, setTrendingHygiene] = useState([]);
  const [TrendingMasks, setTrendingMasks] = useState([]);
  const [TrendingUnder, setTrendingUnder] = useState([]);
  const [TrendingTreats, setTrendingTreats] = useState([]);

  const [errors, setErrors] = useState([]);
  const [loading, setloading] = useState(true);
  const [productLoading, setProductLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let data = {};

    axios
      .post("/api/get-home-items", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setProductLoading(false);
          setTrendingProducts(res.data.products);
          setTrendingBundles(res.data.bundles);
          setTrendingTreats(res.data.all);
          setHotproducts(res.data.hot);
        } else {
          setErrors(res.data.messages);
        }
        setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
      });
  }, []);

  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  // function calculatePrice(product){
  //   let price = product.price-(product.price*product.discount.discount)/100;
  //   return price;
  // }

  function alreadyAdded(id) {
    let flag = 0;

    cart.forEach((item) => {
      if (item.id === id && item.box_num === 0) {
        flag = 1;
      }
    });

    if (flag) return true;

    return false;
  }

  const addToCart = async (product, e) => {
    e.preventDefault();
    e.persist();

    let total = 0;
    let found = 0;
    cart.forEach((element) => {
      if (product.id === element.id) {
        total = total + 1;
        if (product.stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      let product_id = product.id;

      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: product.name,
        id: product_id,
        size: product.size.name,
        color: "",
        pic: product.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: 0,
      };

      cart.push(productInput);

      setCartD(cart);
      setCart(cart);
      props.setCartLength(cart.length);

      localStorage.setItem("cartLength", cart.length);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-wrapper my-2">
      <Helmet>
        <title>Home</title>
        <meta name="description" contents="Women hygiene products" />

        <meta
          name="keywords"
          contents="Trending Products,Women hygiene products,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
        />
      </Helmet>

      <Slider />
      <DraggableLinks />

      <hr />

      <hr />

      <div className="d-none d-lg-block">
        <input
          type="text"
          placeholder="Search item"
          className="searchBar w-25 mx-auto m-3 border rounded text-black"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Link className="btn btn-primary" to={`/searchItem?target=${search}`}>
          Search
        </Link>
      </div>

      {productLoading ? (
        <div>{/* <img src={loader} alt="Your GIF" /> */}</div>
      ) : (
        <>
          {hotproducts.length > 0 && (
            <Trending
              emoji={"🔥"}
              title={"Hot Deals"}
              addToCart={addToCart}
              calculatePrice={calculatePrice}
              TrendingProducts={hotproducts}
              serial={1}
            />
          )}

          {TrendingProducts.length > 0 && (
            <Trending
              emoji={"📈"}
              title={"Flash Sales"}
              addToCart={addToCart}
              calculatePrice={calculatePrice}
              TrendingProducts={TrendingProducts}
              serial={2}
            />
          )}
          {TrendingBundles.length > 0 && (
            <Trending
              emoji={"📦"}
              title={"Bundles"}
              addToCart={addToCart}
              calculatePrice={calculatePrice}
              TrendingProducts={TrendingBundles}
              serial={3}
            />
          )}
          {TrendingTreats.length > 0 && (
            <Trending
              emoji={""}
              title={"All Products"}
              addToCart={addToCart}
              calculatePrice={calculatePrice}
              TrendingProducts={TrendingTreats}
              serial={3}
            />
          )}
        </>
      )}

      <section className=" details-container-home d-flex flex-column justify-content-around  mx-auto align-items-center  flex-md-row gap-5 gap-md-0  my-5 text-black">
        <div className=" d-flex">
          <img
            loading="lazy"
            src={correct}
            alt="Loading"
            className="home-icon-detail p-0"
          />
          <div className="home-info d-flex flex-column">
            <h5>100% Original</h5>
            <p className="f-small">All Products Sourced Directly</p>
          </div>
        </div>
        <div className=" d-flex">
          <img
            loading="lazy"
            src={delivery}
            alt="Loading"
            className="home-icon-detail p-0"
          />
          <div className="home-info d-flex flex-column">
            <h5 className="">Free Shipping</h5>
            <p className="f-small">On Orders Above 1000Tk</p>
          </div>
        </div>
        <div className=" d-flex">
          <img
            loading="lazy"
            src={returnpic}
            alt="Loading "
            className="home-icon-detail p-0"
          />
          <div className=" home-info d-flex flex-column">
            <h5>Easy Returns</h5>
            <p className="f-small">Hassle-Free Pick-ups & Returns</p>
          </div>
        </div>
      </section>
    </div>
  );
}
