 
import React,{useEffect} from 'react'

export default function ShippingReturnPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
       }, [])

  return (
    <div>
      <div  className='text-black' style={{ marginTop: "8rem" }}>
            <div class="m-5">
            <h1>Shipping & Return Policy</h1>
                <div class="page-wrapper" >
                    <h1 >শিপিং নীতি</h1>
                    <h4 >বিতরণ প্রক্রিয়া কিভাবে কাজ করে?</h4>
                    <ul >
                        <li >আমাদের সিস্টেম আপনার অর্ডার প্রক্রিয়া করার পর, আপনার পণ্যগুলি নিখুঁত অবস্থায় আছে তা নিশ্চিত করার জন্য পুঙ্খানুপুঙ্খভাবে পরিদর্শন করা হয়।</li>
                        <li >চূড়ান্ত গুণমান যাচাইয়ের পরে, সেগুলি প্যাক করা হয় এবং আমাদের বিশ্বস্ত ডেলিভারি পার্টনারের কাছে হস্তান্তর করা হয়। এটি সাধারণত আপনার অর্ডার স্থাপনের 48 ঘন্টার মধ্যে করা হয়।</li>
                        <li >আমাদের ডেলিভারি পার্টনাররা আপনার কাছে যত তাড়াতাড়ি সম্ভব প্যাকেজ নিয়ে আসবে। যদিও আমরা 2-3 দিনের মধ্যে আপনার পণ্য সরবরাহ করার জন্য যথাসাধ্য চেষ্টা করি, তবে কিছু দূরবর্তী অবস্থানে এটি আরও বেশি সময় নিতে পারে। যদি, আমাদের ডেলিভারি অংশীদাররা আপনার প্রদত্ত ঠিকানায় বা উপযুক্ত সময়ে পৌঁছাতে অক্ষম হয়, তারা সমস্যা সমাধানের জন্য আপনার সাথে যোগাযোগ করবে।</li>
                    </ul>
                    <h4 >কিভাবে আইটেম প্যাকেজ করা হয়?</h4>
                    <p >আমরা আমাদের পণ্যগুলিকে কার্ডবোর্ডের বাক্সে প্যাকেজ করি এবং চালানও এটির সাথে মোড়ানো থাকে। প্রতিটি পৃথক পণ্য বাবল র‍্যাপে প্যাকেজ করা হয় যেন ভঙ্গুর আইটেমগুলি অতিরিক্ত বাবল র‍্যাপ দিয়ে নিরাপদে সুরক্ষিত থাকে৷</p>
                    <h4 >ডেলিভারি চার্জ কি?</h4>
                    <p >ঢাকার মধ্যে 1500 টাকার নিচে অর্ডারের জন্য ডেলিভারি ফি 60 টাকা এবং 1500 টাকার বেশি অর্ডারের জন্য ডেলিভারি ফি হবে 0।</p>
                    <p >ঢাকার বাইরে 1500 টাকার নিচে অর্ডারের জন্য ডেলিভারি ফি 100 টাকা এবং 1500 টাকার বেশি অর্ডারের জন্য ডেলিভারি ফি হবে 0।</p>
                    <p >সময়ের সাথে সাথে ডেলিভারি চার্জ পরিবর্তন করার অধিকার Shokhi Box সংরক্ষণ করে।</p>
                    <p>1500 টাকার বেশি অর্ডারের জন্য কোনো ডেলিভারি প্রযোজ্য নয়।</p>
                </div>
                <div class="page-wrapper" >
                    <h1 >SHIPPING POLICY</h1>
                    <h4 >How does the delivery process work?</h4>
                    <ul >
                        <li >Once our system processes your order, your products are inspected thoroughly to ensure they are in a perfect condition.</li>
                        <li >After they pass through the final round of quality check, they are packed and handed over to our trusted delivery partner. This is usually done within 48 hours of your order being placed</li>
                        <li >Our delivery partners then bring the package to you at the earliest possibility. Though we try our best to get your products delivered within 2-3 days, but in some remote locations it may take longer. In case, our delivery partners are unable to reach your provided address or at a suitable time, they will contact you to resolve the issue.</li>
                    </ul>
                    <h4 >How are items packaged?</h4>
                    <p >We package our products in cardboard boxes with your invoice wrapped with along with it. Each individual product is packaged in bubble wrap while fragile items like bottles are safely secured with additional bubble wrap.</p>
                    <h4 >What are the delivery charges?</h4>
                    <p >The delivery fee for orders below BDT 1500 within Dhaka will be BDT 60 and the delivery fee will be 0 for orders above BDT 1500.</p>
                    <p >The delivery fee for orders below BDT 1500 outside Dhaka will be BDT 100 and the delivery fee will be 0 for orders above BDT 1500.</p>
                    <p >Shokhi Box reserves the right to modify the delivery charges over time.</p>
                    <p>No delivery applicable for orders above BDT 1500.</p>
                    <p >&nbsp;</p>
                    <div class="page-wrapper">
                        <p>Shokhi Box-এ আমরা আমাদের গ্রাহকদের কাছে নতুন এবং সিল করা পণ্য সরবরাহ করতে পেরে গর্বিত। ফ্যাশনের অন্যান্য বিভাগের পণ্য যেমন ফেরতযোগ্য, সৌন্দর্য পণ্যগুলি তেমন ফেরতযোগ্য নয় স্বাস্থ্যবিধি সমস্যার কারণে।</p>
                        <p>আমাদের চালানগুলি আমাদের গুদাম ছেড়ে যাওয়ার আগে কঠোর মানের পরীক্ষা প্রক্রিয়ার মধ্য দিয়ে যায়। তবে, শিপমেন্টের সময় পণ্যটি ক্ষতিগ্রস্থ হওয়ার সামান্য সম্ভাবনা রয়েছে।</p>
                        <p>আমরা আপনাকে সীল খোলা / টেম্পারড / ত্রুটিপূর্ণ/ ক্ষতিগ্রস্থ পার্সেল গ্রহণ না করার জন্য অনুরোধ করছি, কারণ এই ধরনের ক্ষেত্রে রিটার্ন গ্রহণ করা হবে না। যাইহোক, আপনি যদি ক্ষতিগ্রস্থ/ত্রুটিপূর্ণ অবস্থায় কোনো আইটেম পেয়ে থাকেন বা আপনাকে একটি ভুল পণ্য পাঠানো হয়ে থাকে, তাহলে Shokhibox তার গ্রাহকদের একটি 'সহজ রিটার্ন পলিসি'* অফার করে, যেখানে আপনি ডেলিভারির ৭২ ঘন্টার মধ্যে একটি পণ্যের রিটার্ন/বিনিময়ের অনুরোধ করতে পারেন। আমরা আংশিক রিটার্নও গ্রহণ করি যেখানে আপনি আপনার অর্ডারে এক বা সমস্ত পণ্য ফেরতের জন্য অনুরোধ করতে পারেন।</p>
                        <ul>
                            <li>ধাপ 1: অর্ডার পাওয়ার ৭২ ঘন্টার মধ্যে ইমেলের (support@shokhibox.com) মাধ্যমে আমাদের সাথে যোগাযোগ করুন।</li>
                            <li>ধাপ 2: আপনার অর্ডার আইডির বিশদ বিবরণ, পণ্যের ছবি এবং ফেরতের কারণ আমাদের রেফারেন্সের জন্য চালান সহ একটি ইমেলে দিন।</li>
                            <li>ধাপ 3: রিটার্ন এর অনুরোধ অনুমোদিত হলে আমরা ২-৪ দিনের মধ্যে পণ্যগুলি ফেরত নেব। আমরা রিফান্ড বা প্রতিস্থাপন প্রক্রিয়া শুরু করব শুধুমাত্র যদি আমাদের দ্বারা প্রাপ্ত পণ্য/গুলি অব্যবহৃত হয় এবং তাদের মূল প্যাকেজিংয়ে সিল, লেবেল এবং বারকোড অক্ষত থাকে।</li>
                        </ul>
                        <p><b>ফেরতের ক্ষেত্রে, শিপিং চার্জ গ্রাহক বহন করবেন এবং পণ্যের মূল্য ফেরত পাবেন। অনুমোদনের পরে ৩-৫ কার্যদিবসের মধ্যে ফেরত সম্পাদন করা হবে।</b></p>
                        <p>এই বিষয়ে আরও কোন প্রশ্নের জন্য আপনি অনুগ্রহ করে আমাদের সাথে যোগাযোগ করতে পারেন (01788477966) অথবা আমাদের ইমেল করতে পারেন (support@shokhibox.com)</p>
                        <p>*শর্ত প্রযোজ্য</p>
                    </div>
                    <div class="page-wrapper">
                        <h1>RETURN POLICY</h1>
                        <p>We at Shokhibox take pride in delivering new and sealed products to our customers. Unlike other categories of fashion, beauty products are nonreturnable due to hygiene issues.</p>
                        <p>Our shipments go through rigorous quality check processes before they leave our warehouse, however, there is a slight possibility that the product could get damaged during shipment.</p>
                        <p>We urge you not to accept seal open/ Tampered/Leaked/Damaged shipment box, as returns won't be accepted in such cases. However, if you have received an item in a damaged/defective condition or have been sent a wrong product, Shokhibox offers its customers an<span>&nbsp;</span><strong>‘Easy return policy’*</strong>, wherein you can raise a return/exchange request of a product within<span>&nbsp;</span><strong>48 hours</strong><span>&nbsp;</span>of its delivery. We also accept partial returns wherein you can raise a return request for one or all products in you order.</p>
                        <ul>
                            <li>Step 1: Contact our Customer Support team via email (support@shokhibox.com) within 48 hours of receiving the order.</li>
                            <li>Step 2: Provide us with your order ID details and your reason for return over an email along with the image of the product and the invoice for our reference.</li>
                            <li>Step 3: We will pick up the products within 2-4 days once the request is approved. We will initiate the refund or replacement process only if the product/s received by us are unused and in their original packaging with their seals, labels, and barcodes intact.</li>
                        </ul>
                        <p><b>In case of refund, shipping charges will be borne by customer and product cost would be refunded. Refunds will be processed within 3-5 business working days upon approval.</b></p>
                        <p>For any further queries on this you may please contact us at (01788477966) or email us at (support@shokhibox.com)</p>
                        <p>*Conditions Apply</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
