import React, { useEffect, useState } from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export default function PromoCodes() {
  let history = useHistory();

  const [loading, setloading] = useState(true);

  const [promos, setPromos] = useState([]);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  useEffect(() => {
    axios
      .get("/api/get-all-promo", {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setPromos(res.data.promos);
          ////////console.log("GG");
        } else {
          swal("Warning", "Something went wrong", "warning");
        }
        setloading(false);
      })
      .catch((error) => {});
  }, [history]);

  const deletePromo = (e, id) => {
    e.preventDefault();

    const data = {
      id: id,
    };

    axios
      .post("/api/delete-promo", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((response) => {
        if (response.data.status === 200) {
          window.location.reload();
          swal("Success", response.data.message, "success");
        } else {
          swal("Warning", "Fill up the field", "warning");
        }
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div
        className="mx-auto d-flex flex-column "
        style={{ minWidth: "300px", maxWidth: "700px" }}
      >
        <div className="d-flex align-items-center border mt-3 bg-white text-black fw-bold">
          <div className="w-75">Promo Code</div>
          <div className="w-25">Percentage</div>
          <div className="w-25">Delete</div>
        </div>

        {promos.length > 0 &&
          promos.map((promo) => {
            return (
              <div className="d-flex align-items-center border mt-3 bg-white text-black fw-bold">
                <div className="w-75">{promo.promoCode}</div>
                <div className="w-25">{promo.amount}</div>
                <div className="w-25">
                  <button
                    onClick={(e) => deletePromo(e, promo.id)}
                    className="btn btn-warning"
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        {promos.length === 0 && (
          <div className="mt-3">Promo Codes Not Found</div>
        )}
      </div>
    );
  }
}
