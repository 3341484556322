import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

export default function UpdateSubCat() {
    let history = useHistory();
    const { id } = useParams();
    const [name,setName] = useState("");
   
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;


 const handleInput = (e) =>{
       setName(e.target.value);
 }

 const submit = (e) =>{
    e.preventDefault();
   const data = {
    name:name
   }
    axios.post('/api/add-subcat',data,{
      headers: { 'Allowed-Source': allowedSource }
  })
    .then(response=>{
      const res = response.data;
 
     if(res.status ===200){
         
         document.getElementById('theForm').reset();
        swal("Success",res.message,"success");
      
     }
     else{
        swal("Warning","Fill up all the fields","warning")
     }
 
 
    });
 
 
  }


 
  return (
    <div>
        <h4>Add Sub-Category</h4>
        <form onSubmit={submit} id='theForm'>
   
 

  <div className="form-group mb-3">
    <label >Name</label>
    <input onChange={handleInput} value={name} class="form-control"  type="text" id="name" name='name' />
  </div>

  

<button type='submit' className='btn btn-primary'>Add</button>

</form>
    </div>
  )
  

}
