import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import { setCartD } from "../../hooks/useCart";
import emptyCart from "../../images/empty-cart.png";
import trashBin from "../../images/trash-bin.png";

export default function Cart(props) {
  const { cart, boxes, setCart, setBoxes, loading } = useContext(MyCartContext);

  const [render, setRender] = useState(true);

  const [total, setTotal] = useState(totalPrice());

  let history = useHistory();

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  function totalPrice() {
    let temp_total = 0;
    if (cart !== null) {
      cart.forEach((element) => {
        temp_total += element.price * element.quantity;
      });
    }

    return temp_total;
  }

  useEffect(() => {
    let x = totalPrice();
    setTotal(x);
  }, [cart, boxes, render]);

  // ////////////console.log(boxes)
  function limitCrossed(id, stock) {
    if (cart === null) return false;

    let total = 0;
    cart.forEach((element) => {
      if (element.id === id) {
        total += element.quantity;
      }
    });

    if (total > stock) return true;

    return false;
  }

  function boxZero() {
    let found = 0;

    cart.forEach((element) => {
      if (element.box_num == 0) found = 1;
    });

    return found;
  }
  const handleChange = (item, id, value) => {
    let temp_cart = cart;

    temp_cart.forEach(async (item) => {
      if (item.id === id && item.box_num === 0) {
        let data = {
          id: item.id,
        };
        let product = {};

        // await axios.post('/api/get-product-by-id',data, {
        //       headers: { 'Allowed-Source': allowedSource }
        //   })
        //    .then(res=>{
        //               if(res.data.status===200){
        //                 product = res.data.product;

        //               }

        //           })

        //dfsd
        if (value === 1) {
          if (limitCrossed(item.id, item.stock)) {
            swal("No more available", "Insufficient Stock", "warning");
          } else {
            if (item.box_num === 0) {
              item.quantity += value;

              setCart(temp_cart);
              props.setRenderNav((prev) => !prev);

              setCartD(temp_cart);
            }
          }
        } else {
          if (item.quantity !== 1) {
            item.quantity += value;

            setCart(temp_cart);
            props.setRenderNav((prev) => !prev);

            setCartD(temp_cart);
          }
        }
        setRender((prev) => !prev);
      }
    });
  };

  const deleteItem = (id) => {
    let temp_cart = cart;

    temp_cart = temp_cart.filter(
      (item) => item.id !== id || item.box_num !== 0
    );

    setCartD(temp_cart);
    setCart([...temp_cart]);

    localStorage.setItem("cartLength", temp_cart.length);
    props.setCartLength(temp_cart.length);
  };

  const deleteBox = (box_id) => {
    let temp_boxes = boxes;
    temp_boxes = temp_boxes.filter((the_box) => the_box.box_id !== box_id);
    setBoxes(temp_boxes);

    let temp_cart = cart;

    temp_cart = temp_cart.filter((item) => item.box_num !== box_id);

    setCart(temp_cart);
    setCartD(temp_cart);

    props.setCartLength(temp_cart.length);
    localStorage.setItem("cartLength", temp_cart.length);
    props.setCartLength(temp_cart.length);

    //////////////console.log("after delete box",temp_boxes)
  };

  //////////console.log(cart,"cart in Cart>")
  //////////console.log(boxes,"Boxes in cart")

  useEffect(() => {
    props.setShowCart(false);
    props.setShowFloatingCart(false);
    window.scrollTo(0, 0);

    return () => {
      props.setShowFloatingCart(true);
    };
  }, []);

  if (loading) {
    return <div className="text-black">{/* Loading.. */}</div>;
  } else if (cart.length === 0) {
    return (
      <div className="text-black d-flex flex-column">
        <Link
          className=" w-100 mt-3  mx-2 dec-none text-white login"
          to="/TrackOrder"
        >
          <span className="btn btn-primary">Track Your Order</span>
        </Link>

        <h4 className="fw-bold mt-5">Your Cart Is Empty</h4>
        <img src={emptyCart} className="item-pic mx-auto" />
      </div>
    );
  } else {
    return (
      <>
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          <Link
            className=" w-100 mt-3  mx-2 dec-none text-white login mb-3"
            to="/TrackOrder"
          >
            <span className="btn btn-primary">Track Your Order</span>
          </Link>

          {boxZero() === 1 && (
            <>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Pic</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Size</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((item, index) => {
                      if (item.box_num === 0)
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                style={{ height: "40px" }}
                                src={item.pic}
                                className=""
                                alt="..."
                              />
                            </td>
                            <td className="item-title">{item.name}</td>
                            <td>{item.price}</td>
                            <td>
                              {!(
                                item.size === "" ||
                                item.size === "Not Available"
                              )
                                ? item.size
                                : "-"}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column align-items-center">
                                  <div className="d-flex">
                                    <button
                                      onClick={(e) =>
                                        handleChange(item, item.id, -1)
                                      }
                                      className="bg-warning px-2 mx-2 border rounded"
                                    >
                                      -
                                    </button>
                                    <span>{item.quantity}</span>
                                    <button
                                      onClick={(e) =>
                                        handleChange(item, item.id, 1)
                                      }
                                      className="bg-info px-2 mx-2 border rounded"
                                    >
                                      +
                                    </button>
                                  </div>
                                  <button
                                    onClick={(e) => deleteItem(item.id)}
                                    style={{ borderRadius: "50%" }}
                                    className="border-0"
                                  >
                                    <img
                                      style={{ width: "30px" }}
                                      src={trashBin}
                                      alt="none"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <div className="boxContainer">
          {boxes.length > 0 &&
            boxes.map((box, index) => {
              return (
                <>
                  <div className="border-2 eachBox">
                    <h4 className="text-black">Shokhi Box {index + 1}</h4>
                    <div className="d-flex flex-wrap justify-content-between text-black">
                      {box.items.map((the_item, index) => {
                        return (
                          <>
                            <div className="cart-item-row d-flex align-items-center justify-content-between text-black">
                              <div className="shokhi-pic-container">
                                <img
                                  className="shokhi-pic"
                                  src={the_item.pic}
                                  alt="Nai"
                                />
                              </div>
                              <div className="item-details d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-column align-items-start box-name">
                                  <span className="m-1 text-start">
                                    {the_item.name}
                                  </span>
                                  {the_item.size !== "Not Available" && (
                                    <span>{the_item.size}</span>
                                  )}
                                </div>
                                <span className="m-1 box-item-q">
                                  <span className="fw-bold"></span>{" "}
                                  {the_item.price} Tk x {the_item.quantity}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => deleteBox(box.box_id)}
                        style={{ borderRadius: "50%" }}
                        className="border-0"
                      >
                        <img
                          style={{ width: "30px" }}
                          src={trashBin}
                          alt="none"
                        />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div>
          <div className="w-75 mx-auto my-5 text-center text-black">
            <h6 className="bold fs-3">
              <span className="color-blue fs-3">Total </span>: {total} Tk
            </h6>
          </div>

          <Link to="/checkout" className="m-3 btn bg-info">
            Checkout
          </Link>
        </div>
      </>
    );
  }
}
