import React, { useEffect, useState } from "react";

import axios from "axios";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import blogImage from "../../images/demoblog.avif";

export default function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  let history = useHistory();

  const [loading, setloading] = useState(true);

  const [blogs, setBlogs] = useState([]);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("/api/get-blogs", {
        headers: { "Allowed-Source": allowedSource },
      })

      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          setBlogs(res.data.blogs);
        } else {
          setErrors(res.data.messages);
          history.push("/");
        }
        setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
        history.push("/");
      });
  }, [history]);

  if (loading) {
    return <h4></h4>;
  } else {
    return (
      <div className="text-left mx-auto text-black my-10 px-10">
        <Helmet>
          <title>Blogs</title>
          <meta name="description" contents="Women hygiene blogs" />

          <meta
            name="keywords"
            contents="Blogs,Women hygiene,Tampons,Pads,Blogs,Masks,Cramp Relief,Undergarments,Underwears"
          />
        </Helmet>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
          {Array(8)
            .fill()
            .map((_, index) => (
              <div key={index} className="rounded-md overflow-hidden shadow-md">
                <div>
                  <img src={blogImage} alt="" />
                </div>
                <div className="mx-3 space-y-4 my-3">
                  <h5 className="text-xl font-bold tracking-wider">Title</h5>
                  <p
                    className="overflow-hidden"
                    style={{
                      WebkitLineClamp: 3,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Quod vel voluptatibus recusandae error odio ex sunt
                    molestias laudantium, consectetur neque ipsam eveniet,
                    eligendi totam ab minus pariatur excepturi reprehenderit,
                    itaque maiores! Libero ad illo temporibus et maxime
                    obcaecati dicta corporis, cupiditate facere iure error nulla
                    pariatur unde exercitationem repellat atque?
                  </p>
                  <div className="w-full text-right">
                    <Link
                      to={"/blogs/9"}
                      className="dec-none border px-3 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-500 transition-all duration-300 w-fit "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
