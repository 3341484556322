import axios from "axios";
import html2pdf from "html2pdf.js";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import checked from "../../images/checked.png";
import warning from "../../images/warning.png";
import CompanyInfo from "../admin/CompanyInfo";

export default function VerifyPayment(props) {
  const [ShowInvoice] = useState(false);
  const [order, setOrder] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [loading, setLoading] = useState(true);

  const [isSuccessful, setStatus] = useState(false);

  const [errorDetail, setErrorDetail] = useState("");

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const code = queryParams.get("code");
  const message = queryParams.get("message");
  const Download = () => {
    if (order && order.id) {
      const element = document.getElementById(order.id);
      console.log(element);
      if (element) {
        html2pdf().from(element).save();
      } else {
        console.error(`Element with id ${order.id} not found.`);
      }
    }
    // const element = document.getElementById(divId);

    // if (element) {
    //   html2pdf(element);
    // } else {
    //   console.error(`Element with id ${divId} not found.`);
    // }
  };

  const [details, setDetails] = useState({
    invoice: "",
  });

  useEffect(() => {
    ////console.log(code," ",message);
    if (code === "200") {
      setStatus(true);

      setDetails({ ...details, ["invoice"]: message });

      const data = {
        invoice: message,
      };

      axios

        .post("/api/trackOrder", data, {
          headers: { "Allowed-Source": allowedSource },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setOrder(res.data.order);
            console.log(res.data.order);
          }
        });
    } else {
      setErrorDetail(message);
    }

    setLoading(false);
  }, [code, message]);

  const calculateDeliveryCharge = (order) => {
    const totalDeliveryCharge =
      order.orderdetails &&
      order.orderdetails.reduce(
        (total, item) => total + item.quantity * parseInt(item.product_price),
        0
      );
    return order.total - totalDeliveryCharge;
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString("en-US");
  };

  if (loading) {
    return <div></div>;
  } else if (isSuccessful) {
    return (
      <div className="text-black mt-5">
        <div className="w-full flex justify-center items-center">
          <img className="" src={checked} />
        </div>
        <div className="space-y-2">
          <h1 className="text-3xl">Successful</h1>
          <h4 className="text-xl">
            {`Your invoice number is `}
            <span className="fs-3 fw-bold">{details.invoice}</span>
          </h4>
          <h4 className="text-xl">{`Use the invoice number to track your order`}</h4>
        </div>
        {order && (
          <>
            <div
              id={order.id}
              className="mx-auto font-bold min-w-[320px] max-w-[800px]"
            >
              <div>
                <CompanyInfo />

                <div
                  className="mx-auto card"
                  style={{ borderRadius: "0px 0px 5px 5px" }}
                >
                  <div className="my-4">
                    <h1 className="card-title">Invoice</h1>
                  </div>
                  <div className="card-body  text-start">
                    <div className="mb-3 row">
                      <div className="col-sm-6 text-start">
                        <strong>Invoice ID:</strong> {order.invoiceId}
                      </div>
                      <div className="col-sm-6 text-sm-start">
                        <strong>Date:</strong> {formatDate(order.created_at)}
                        <br />
                        <strong>Time:</strong> {formatTime(order.created_at)}
                      </div>
                    </div>

                    <div className="mb-3   text-start ">
                      <div className="  text-start my-p-0">
                        <strong>Customer Name:</strong> {order.name}
                      </div>
                      <div className=" text-sm-start">
                        <strong>Phone:</strong> {order.phn}
                      </div>
                    </div>

                    <div className="mb-3  text-start">
                      <div className="col-sm-12 text-start">
                        <strong>Address:</strong> {order.address}
                      </div>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.orderdetails &&
                          order.orderdetails.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price} Tk</td>
                                <td>
                                  {item.quantity * parseInt(item.product_price)}{" "}
                                  Tk
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <div className="row">
                      <div className=" "></div>
                      <div className=" text-start">
                        <h5>
                          {`Delivery Charge: ${calculateDeliveryCharge(order)}`}{" "}
                          Tk
                        </h5>
                        <h5>Total: {order.total} Tk</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="flex flex-col justify-center items-center">
              <span>Download your Invoice/Receipt</span>
              <button
                onClick={(e) => Download()}
                className="btn m-1 bg-warning text-white"
              >
                Download
              </button>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="text-black">
        <div className="text-black mt-5">
          <img
            className="text-center"
            style={{ width: "200px" }}
            src={warning}
          ></img>
          <h1>Failure</h1>
          <h4 className="fw-bold">{errorDetail}</h4>
        </div>
      </div>
    );
  }
}
