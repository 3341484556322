import React from "react";
import logo from "../../images/Logo_1.webp";

const CompanyInfo = () => {
  return (
    <div className="">
      <div
        className="p-1 px-3 mt-5 company-info d-flex align-items-center justify-content-between bg-dark"
        style={{ borderRadius: "0px 0px 5px 5px" }}
      >
        <div className="company-logo me-3">
          <img
            src={logo}
            alt="Company Logo"
            className="border img-fluid rounded-circle border-light"
          />
        </div>
        <div className="company-details text-light">
          <h2 className="mb-3 fw-bold">Shokhibox</h2>
          <p className="mb-1">Corporate Address: Dhaka, Bangladesh</p>
          <p>Talk to us: +8801788-477966</p>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
