import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { MyCartContext } from "../../Contexts/CartContext";
import useGetCart, { setCartD } from "../../hooks/useCart";

export default function Search(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const target = params.get("target");

  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setSearch(target);
    const data = {
      search: target,
    };
    axios
      .post("/api/search", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setProducts(res.data.products);
        } else {
        }
        setloading(false);
      })
      .catch((error) => {});
  }, [target]);

  const { cart, setCart } = useGetCart();
  const { localCart, setLocalCart } = useContext(MyCartContext);

  function alreadyAdded(id) {
    let flag = 0;

    cart.forEach((item) => {
      if (item.id === id && item.box_num === 0) {
        flag = 1;
      }
    });

    if (flag) return true;

    return false;
  }

  const addToCart = async (product, e) => {
    e.preventDefault();
    e.persist();

    let total = 0;
    let found = 0;
    cart.forEach((element) => {
      if (product.id === element.id) {
        total = total + 1;
        if (product.stock >= element.quantity + 1) {
          element.quantity += 1;
          found = 1;
          setCartD(cart);
          setCart([...cart]);
        } else {
          swal("Warning", "No more available", "warning");
        }
      }
    });

    if (!found) {
      let product_id = product.id;

      //    let boxData = JSON.parse(localStorage.getItem(""));

      const productInput = {
        name: product.name,
        id: product_id,
        size: product.size.name,
        color: "",
        pic: product.pic,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: 0,
      };

      cart.push(productInput);

      setCartD(cart);
      setCart([...cart]);
      props.setCartLength(cart.length);

      localStorage.setItem("cartLength", cart.length);
    }
  };
  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  const [sortBy, setSortBy] = useState(-1);
  const sortClicked = (e, val) => {
    setSortBy(val);

    let sortedItems = [...products];

    if (val == 1) {
      sortedItems.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (val == 0) {
      sortedItems.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }

    setProducts(sortedItems);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sortBy]);

  if (loading) {
    return <div>{/* <img src={loader} alt="Your GIF" /> */}</div>;
  }
  return (
    <div className=" d-flex flex-column text-black">
      <div>
        <input
          type="text"
          placeholder="Search item"
          className="searchBar w-25 mx-auto m-3 border rounded"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Link className="btn btn-primary" to={`/searchItem?target=${search}`}>
          Search
        </Link>
      </div>
      <div className="d-flex mx-4">
        <label className="text-black p-2 fw-bold">Sort By Price</label>
        <select
          className="p-2 border"
          name="cars"
          id="cars"
          value={sortBy}
          onChange={(e) => sortClicked(e, e.target.value)}
        >
          <option className="p-2" value={-1}>
            None
          </option>
          <option className="p-2" value={1}>
            Low to High
          </option>
          <option className="p-2" value={0}>
            High to Low
          </option>
        </select>
      </div>

      <div className="Container d-flex flex-wrap justify-content-center align-items-center ">
        {products.map((product, index) => {
          return (
            <div className="tredningProduct1 border m-1">
              <Link to={`/product/${product.id}`} className="dec-none">
                <div key={index} className="m-2 item-card p-2">
                  {product.discount_id !== 1 && (
                    <span className="og-price">-{product.price}Tk</span>
                  )}

                  <img
                    loading="lazy"
                    src={product.pic}
                    className="item-pic"
                    alt="..."
                  />
                  <div className="item-body ">
                    <h5 className="item-title">{product.name}</h5>
                    <h4></h4>

                    <h6 className="">
                      <span className="item-price">
                        ৳{Math.ceil(calculatePrice(product))}
                      </span>
                    </h6>
                    <button
                      onClick={(e) => addToCart(product, e)}
                      className=" myborder-pink bg-white mb-2"
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
