import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useGetCart from "../../hooks/useCart";

import sad from "../../images/sad.png";
export default function ShokhiItems(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  let history = useHistory();
  const { subcategory } = useParams();

  const { cart, setCart, boxes } = useGetCart();
  const [products, setProducts] = useState([]);

  const [errors, setErrors] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    let box = props.shokhiBox;

    if (box !== null) {
      let found = 0;
      box.forEach((element) => {
        if (element.name.toLowerCase() === subcategory.toLowerCase()) found = 1;
      });
      if (found) history.push("/shokhibox");
    }

    const data = {
      subcat: subcategory,
    };

    axios
      .post("/api/get-shokhi-items", data, {
        headers: { "Allowed-Source": allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setProducts(res.data.products);
        } else {
          setErrors(res.data.messages);
          // history.push('/');
        }
        setloading(false);
      })
      .catch((error) => {
        //////////console.log(error);
        // history.push('/');
      });
  }, [history, subcategory]);

  function calculatePrice(product) {
    let price =
      product.price - (product.price * product.discount.discount) / 100;
    return price;
  }

  const addToBox = (product) => {
    let current_box = 1;

    const productInput = {
      name: product.subcategory.name,
      item: {
        name: product.name,
        id: product.id,
        size: product.size.name,
        color: product.color.name,
        pic: product.pic,
        stock: product.stock,
        quantity: 1,
        price: Math.ceil(calculatePrice(product)),
        box_num: boxes.length + 1,
      },
    };

    props.setPrice(props.totalPrice + productInput.item.price);

    let box = props.shokhiBox;

    if (box === null) {
      box = [];
    }

    box.push(productInput);

    props.setShokhiBox(box);
    //////////console.log(box);
    history.push("/shokhibox");
  };

  const [search, setSearch] = useState("");

  const Search = (e) => {
    let temp_search = "";
    temp_search = e.target.value;

    setSearch(temp_search);
  };

  function limitCrossed(id, stock) {
    if (cart == null) return false;

    let total = 0;
    cart.forEach((element) => {
      if (element.id === id) {
        total += element.quantity;
      }
    });

    if (total >= stock) return true;

    return false;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <div>{/* <img src={loader} alt="Your GIF" /> */}</div>;
  } else if (products.length < 1) {
    return (
      <div className="text-black d-flex flex-column">
        <h4 className="fw-bold mt-5">No Items Available</h4>
        <img src={sad} className="item-pic mx-auto" />
      </div>
    );
  } else {
    return (
      <div className="Outer-Container d-flex flex-column">
        <input
          type="text"
          placeholder="Search item"
          className="searchBar w-25 mx-auto m-3 border rounded"
          name="search"
          value={search}
          onChange={(e) => Search(e)}
        />

        <div className="Container d-flex flex-wrap justify-content-center align-items-center">
          {products
            .filter((item) => {
              let temp_search = search.toLowerCase();
              let temp_name = item.name.toLowerCase();
              if (temp_search === "") return true;
              else if (temp_name.includes(temp_search)) {
                return true;
              } else return false;
            })
            .map((product, index) => {
              return (
                <div key={index} className="m-2 item-card">
                  <img
                    loading="lazy"
                    src={product.pic}
                    className=" item-pic "
                    alt="..."
                  />
                  <div className="item-body">
                    <h5 className="item-title">{product.name}</h5>
                    <h4></h4>

                    {!limitCrossed(product.id, product.stock) &&
                    product.stock ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <span className="">
                            {Math.ceil(calculatePrice(product))} Tk
                          </span>
                          {product.size.name !== "Not Available" && (
                            <span className=" border px-2 rounded ">
                              {product.size.name}
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => addToBox(product)}
                          className="btn"
                        >
                          Add to box
                        </button>
                      </>
                    ) : (
                      <button className="btn bg-danger text-white">
                        Not availablet
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
